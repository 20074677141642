import React from "react";

const Navigation = () => {
  return (
    <>
      <ul className="md:flex-row  flex gap-16  ml-10 text-xs xl:text-base text-gray-700 font-bold">
        {/* <li className={``}>
          <a className={`border-b-2 border-blue-600 pb-5 px-1`}>Schedule</a>
        </li> */}

        {/* <li className={``}>
          <a>List</a>
        </li> */}
      </ul>
    </>
  );
};

export default Navigation;
