import Slice1 from "../images/images/Slice1.svg";
import body_register from "../images/my_images/body_register.png";
import { AppContext, toast, API, Loader, useUtils } from "../utils";
import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const SetupAccount = () => {
  const utils = useUtils();
  const [type, setType] = useState("Entreprise");
  const [accounts, setAccounts] = useState(1);
  const [moderators, setModerators] = useState(1);
  const [status, setStatus] = useState("loading");

  const [state, setState] = React.useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setStatus("loading");
      let a = await API.account.all();
      if (a.data?.data.length) {
        a = a.data.data[0];
        setType(a.type);
        setAccounts(a.nb_accounts || 1);
        setModerators(a.nb_employees);
        setStatus("");
      }
    })();
  }, []);

  let userData = state.userData?.data || {};
  if (!userData) return null;

  const submit = async (e) => {
    try {
      setStatus("saving");
      let a = await API.account.all();
      await API.account.put(a.data.data[0].id, {
        type,
        nb_employees: moderators,
        nb_accounts: accounts,
      });
      await API.user.me();
      navigate("/setup/pages");
    } catch (e) {
      console.error(e);
      setStatus(null);
      toast.error("Une erreur est survenue");
    }
  };

  if (status == "loading") return <Loader />;

  const cnt = () => {
    if (status == "loading") return <Loader />;

    return (
      <div>
        <div className="logo_flex flex">
          <img src={Slice1} alt="logo_flex" />
          <h1>diggow</h1>
        </div>

        <div className="connect_block">
          <h2>
            Encore une étape <br />
            avant de créer votre compte !
          </h2>
          <p>
            Ces informations nous aiderons à bien personnaliser votre espace.
          </p>
        </div>
        <div className="input_login input_style">
          {/* first row */}

          <div className="choice_btn align">
            <span>Vous êtes ?</span>

            <div className="btns_style align">
              <button
                onClick={() => setType("Entreprise")}
                className={
                  type === "Entreprise"
                    ? ` btn_url`
                    : ` hover:btn_url_cover btn_url_cover`
                }
              >
                Entreprise
              </button>

              <button
                onClick={() => setType("Agence")}
                className={
                  type === "Agence"
                    ? ` btn_url`
                    : ` hover:btn_url_cover btn_url_cover`
                }
              >
                Agence
              </button>
            </div>
          </div>
        </div>
        {/* second row */}

        <div className="style_input_number align">
          <span>
            Combien de compte <br />
            vous aller gérer ?
          </span>
          <div className="align input_number">
            <button
              // disabled
              onClick={() => setAccounts(accounts + 1)}
            >
              +
            </button>
            <div>
              <InputText
                value={accounts.toLocaleString("en-US", {
                  //this is the function that formats the numbers
                  minimumIntegerDigits: 2, //change this to your minimum length
                  useGrouping: false,
                })}
                onChange={(e) => setAccounts(e.target.value)}
                className="btn_url_cover center_el"
                readOnly
              />
            </div>
            <button
              disabled={accounts <= 1}
              onClick={() => setAccounts(accounts - 1)}
              //disabled={moderators <= 1}
            >
              _
            </button>
          </div>
        </div>

        {/* third row */}

        <div className="style_input_number align">
          <span
          //className=" text-asap-white mt-1 mx-auto text-left   text-sm  font-thin font-Montserrat"
          >
            Nombre de personnes qui <br />
            vont gérer ces comptes ?<br />
          </span>
          <div className="align input_number">
            <button onClick={() => setModerators(moderators + 1)}>+</button>
            <div>
              <InputText
                value={moderators.toLocaleString("en-US", {
                  //this is the function that formats the numbers
                  minimumIntegerDigits: 2, //change this to your minimum length
                  useGrouping: false,
                })}
                onChange={(e) => setModerators(e.target.value)}
                className="btn_url_cover center_el"
                readOnly
              />
            </div>
            <button
              disabled={moderators <= 1}
              onClick={() => setModerators(moderators - 1)}
              //disabled={moderators <= 1}
            >
              _
            </button>
          </div>
        </div>

        <Button
          onClick={submit}
          type="submit"
          iconPos="right"
          loading={status == "saving"}
          //   label="Confirmer")}
          //onClick={() => utils.goTo("/setup/pages")}
          className="btn_url specific_style_btn"
        >
          Confirmer
        </Button>
      </div>
    );
  };

  ////**********structure pricipale */
  return (
    <div>
      <div className="block_register body_gray align">
        <div className="body_login full_width body_blue">
          <img
            src={body_register}
            alt="body_register"
            className="center_el"
          ></img>
        </div>
        {/* <div class="Vector"></div> */}

        <div className="form_login form_style full_width">
          <section>{cnt()}</section>
        </div>
      </div>
    </div>
  );
};

export default SetupAccount;
