import React, { useState, useEffect } from "react";

import toast from "react-hot-toast";
import { AppProvider, AppContext } from "./Context";
import API from "./Api";

import fbIcon from "../images/images/fbIcon.png";
import instaIcon from "../images/images/instaIcon.png";
import linkedinIcon from "../images/images/linkedinIcon.png";
import twitterIcon from "../images/images/twitterIcon.png";
import fbIconSM from "../images/images/sm/fb.svg";
import instaIconSM from "../images/images/sm/insta.svg";
import linkedinIconSM from "../images/images/sm/ln.svg";
import twitterIconSM from "../images/images/sm/twitter-icon.svg";
import youtubeIconSM from "../images/images/sm/youtube.svg";
import { useNavigate } from "react-router-dom";
// import randomColor from "randomcolor";
import Loader from "../cmp/Loader";

const SocialIcon = (props) => {
  let t = props.type;
  let c = props.className;
  let i = fbIcon;
  //console.log("typess", props.type);
  if (t == "instagram") i = instaIcon;
  else if (t == "linkedin") i = linkedinIcon;
  else if (t == "twitter") i = twitterIconSM;
  else if (t == "youtube") i = youtubeIconSM;
  if (props.small) {
    i = fbIconSM;
    if (t == "instagram") i = instaIconSM;
    else if (t == "linkedin") i = linkedinIconSM;
    else if (t == "twitter") i = twitterIcon;
    else if (t == "youtube") i = youtubeIconSM;
  }
  return (
    <img
      src={i}
      className={c || "md:w-12 mr-1 md:mr-12 md:mt-2 md:h-12 w-8 h-8"}
      alt=""
    />
  );
};

const useUtils = (props) => {
  const navigate = useNavigate();
  const [state, setState] = React.useContext(AppContext);

  return {
    navigate,
    state,
    setState,
    goTo: (url) => {
      navigate(url.replace(":pageId", state?.currentPage?.page_id));
    },
  };
};

// function* colorGeneratorFn() {
//   let i = 0;
//   let c;
//   while (true) {
//     if (i % 3 == 0) c = randomColor({ hue: "blue", luminosity: "bright" });
//     else c = randomColor({ hue: "green", luminosity: "bright" });
//     yield c;
//     i++;
//   }
// }
let colorsList = [
  "#6955e6",
  "#596ae9",
  "#f1c658",
  "#6955e6",
  "#596ae9",
  "#f1c658",
  "#6955e6",
  "#596ae9",
  "#f1c658",
];

// let colorGenerator = colorGeneratorFn();
let colorIdx = 0;
let getNextColor = () => {
  return colorsList[colorIdx++ % colorsList.length];
  //return randomColor({hue: '#45a3db'});
  //return colorGenerator.next().value;
};

export {
  SocialIcon,
  toast,
  API,
  AppProvider,
  AppContext,
  useUtils,
  getNextColor,
  Loader,
};
