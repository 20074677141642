import { API } from "../utils";
import React, { userRef, useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import user from "../images/images/user.jpg";
import { useRef } from "react";
import axios from "axios";

import dayjs from "dayjs";

const Profile = (props) => {
  console.log("Profile props ==>", props.userData);
  const [imageUrl, setImageUrl] = useState(props.defaultImage || user); 

  const onImageChange = (event) => {
    const file = event.target.files[0];
    
    setImageUrl(URL.createObjectURL(file)); 
  };
  const [formData, setFormData] = useState({
    company: props.userData.company ?? "",
    email: props.userData.email ?? "",
    name: props.userData.name ?? "",
    phone: props.userData.phone ?? "",
    picture: props.userData.picture ?? "",
    password: "",
    confirmpassword: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    
    if (formData.password !== formData.confirmpassword) {
      console.error("Passwords don't match!");
      
      return;
    }
    try {
      const response = await axios.put(`<span class="math-inline">\{API\}/users/</span>{props.userData.id}`, formData);
      console.log("User update response:", response.data);
      
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <div className="profileinfos">
      <div className="userinfos">
        <p>
          <h className="infoslabel">Name : </h> {props.userData.name}
        </p>
        <br />
        <p>
          <h className="infoslabel">Email : </h> {props.userData.email}
        </p>
        <br />
        <p>
          <h className="infoslabel"> Registration Date : </h>
          {dayjs(props.userData.created_at).format("ddd MMM DD YYYY")}
        </p>
      </div>

      <form onSubmit={handlesubmit}>
        <div className="profilepicture">
          <img
            alt="preview image"
            src={imageUrl}
            className="protectedimg"
            name="picture"
          />
          <br />
          <input
            type="file"
            onChange={onImageChange}
            className="filetype"
            name="picture"
            id="image_folder"
            value={formData.picture}
          />
          <label
            htmlFor="image_folder"
            className="pi pi-plus-circle"
            name="picture"
          >
            {" "}
            Upload Picture
          </label>
        </div>

        <div className="updateinfo">
          <label name="name" className="labelnoun">
            Nom
          </label>
          <tr />
          <InputText
            type="text"
            className="username"
            value={formData.name}
            name="name"
            onChange={handleInputChange}
          />
          <br />
          <label name="company" className="labelnoun">
            Société
          </label>
          <tr />
          <InputText
            type="text"
            className="username"
            name="company"
            value={formData.company}
            onChange={handleInputChange}
          />
          <br />
          <label name="phone" className="labelnoun">
            Téléphone
          </label>
          <tr />
          <InputText
            type="text"
            className="username"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
          />
          <br />
          <label name="email" className="labelnoun">
            Email
          </label>
          <tr />
          <InputText
            type="text"
            className="username"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />

          <br />
          <label name="password" className="labelnoun">
            Mot de passe
          </label>
          <tr />
          <input
            type="password"
            className="username"
            name="password"
            onChange={handleInputChange}
          />
          <br />
          <label name="password" className="labelnoun">
            Confirmer mot de passe
          </label>
          <tr />
          <input
            type="password"
            className="username"
            name="comfirme_password"
            onChange={handleInputChange}
          />
          <br />
          <Button label="Editer" className="useredit" type="submit" />
          <br />
        </div>
      </form>
    </div>
  );
};

export default Profile;


