import React, { useState, useEffect } from "react";
import millify from "millify";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import infoIcon from "../../../images/Info.png";
import xIcon from "../../../images/x-circle.png"

const AllStatsOverview = (props) => {
  let kpis = props.reportingData;
  let kpisData = [];
  let kpisTotal = kpis.total;
  const [kpisS, setKpisS] = useState();

  useEffect(() => {
    if (kpis.total) {
      const excludedKeys = ["page_fans_country", "page_fans_online", "page_fans_city", "page_fans_locale", "page_fans_gender_age", "page_fan_adds_by_paid_non_paid_unique", "sector_posts_count", "posts_engagement", "posts_type"];
      const kpisData = [];
      for (const [key, value] of Object.entries(kpis.metrics)) {
        if (value.name && !excludedKeys.includes(key)) {
          kpisData.push({
            keys: key,
            name: value.name,
            selected: false,
            description: value.description,
          });
        }
      }
      setKpisS(kpisData);
    }
  }, []);

  console.log("kpisS", kpisS);

  const select = (name, selected) => {
    let cp = [...kpisS];
    let idx = cp.findIndex((el) => el.name == name);
    cp[idx].selected = selected;
    setKpisS(cp);
  };

  return (
    <>
      <Header />
      <div className="content-dashbor " style={{background : "#f7f8fe"}}>
        <h1 className=" font-bold  text-center text-gray-800 lg:text-2xl pt-8 mt-4 ">
          {"Toutes les stats"}
        </h1>
        <p className="font-Montserrat font-semibold text-center text-gray-800 lg:text-xl  mt-4 ">
          {"Tous les indicateurs de Performance"}
        </p>

        {/* card */}

        <div className="flex flex-wrap justify-center mx-auto mt-4">
          <div className=" flex flex-wrap justify-around gap-4 mt-4 mb-20">
            {kpisS?.map((detail, id) => (
              <div
                
                key={id}
                className={`w-64 lg:h-46 ${
                  !detail.selected ? "bg-white" : "bg-indigo-600"
                }  rounded-lg p-4 shadow-md m-2  dark:bg-diggow-dark-300`}
              >
                <div>
                  <div className="flex  justify-between">
                    <p className={`${!detail.selected ? " text-gray-500" :"text-white font-semibold" } dark:text-diggow-primary font-Montserrat text-md`}>
                      {detail.name}
                    </p>

                    <span
                    onClick={() => select(detail.name, !detail.selected)}>
                        
                      <img className="cursor-pointer" src={ detail.selected ? xIcon : infoIcon} alt="" />
                    </span>
                  </div>

                  {!detail.selected && (
                    <div>
                      <h1 className="text-asap-blue dark:text-diggow-primary font-Montserrat mt-1 font-extrabold text-xl font-sans">
                        {typeof kpisTotal[detail.keys] == "number" ? (
                          (kpisTotal[detail.keys]).toLocaleString()
                        ) : (
                          <span className="text-xs font-bold text-indigo-600">
                            Not Available for the moment
                          </span>
                        )}
                      </h1>

                      {/* <h1 className="mt-8  font-semibold text-indigo-900">
                        +6 %
                      </h1> */}
                    </div>
                  )}

                  {detail.selected && <div className="text-xs mt-4 text-white">{detail.description}</div>}
                  {/* <p className="text-asap-blue dark:text-asap-text font-Montserrat text-xs mt-4 line-clamp-4 ">
                    <span className="font-Helvetica font-bold text-asap-blue-600">
                      Description :
                    </span>{" "}
                    {detail.description}
                  </p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllStatsOverview;
