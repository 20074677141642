import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { AppContext, SocialIcon, useUtils, getNextColor } from "../../utils";
// import { ReactComponent as I } from "../../images/i.svg";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import html2canvas from "html2canvas";

const AreaCmp = (props) => {
  //console.log("props.config?.title", props);
  const { t, i18n } = useTranslation();
  let options = {
    credits: false,

    chart: {
      type: "area",
    },
    title: {
      align: "left",
      text: "",
    },
    // subtitle: {
    //   align: "left",
    //   text: props.config?.description || "",
    //   style: { "font-weight": "50px", color: "#a", "margin-bottom": "20px" },
    // },
    xAxis: {
      gridLineWidth: 1,
      allowDecimals: false,
      tickInterval: 1,
    },
    // yAxis: {
    //     title: {
    //         text: 'Nuclear weapon states'
    //     },
    //     labels: {
    //         formatter: function () {
    //             return this.value / 1000 + 'k';
    //         }
    //     },
    //     gridLineColor:"#596ae9"
    // },
    plotOptions: {
      series: {
        color: "#596ae9",
      },
      area: {
        // pointStart: 1940,
        fillOpacity: 0.1,
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series: [
      {
        name: t("Performance_report.user_activity"),
        data: [],
      },
    ],
  };

  options.series[0].data = [];
  props.cmpData.data.map((entry, index) => {
    options.series[0].data.push({
      name: entry.name,
      y: entry[props.cmpData.dataKey],
    });
  });

  if (props.config.var == "sync") {
    return null;
  }

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");
    parent.classList.add("capture_style");

    html2canvas(parent, {
      onclone: (document) => {},
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        parent.classList.remove("capture_style");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  return (
    <div
      data-value={props.data.metrics[props.config.metrics[0]].name}
      className="style_title_chart highchart_block1 mb-5"
    >
      <div className="flex items-center">
        <div className="content_style_title">
          <h1>{t("Performance_report.user_activity")}</h1>
          <p>{t("Performance_report.user_activity_desc")}</p>
        </div>
        <button
          data-html2canvas-ignore
          onClick={printChart}
          className="ml-auto mr-1.5 w-5 h-5 print_capture"
        ></button>
      </div>
      <div className="highchart_block flex">
        <div className="highchart_block1 full_width">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </div>
  );
};

export default AreaCmp;
