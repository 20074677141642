import React from "react";
import { useTranslation } from "react-i18next";
import millify from "millify";
import { useState } from "react";
import Highcharts from "highcharts";
import { Dropdown } from "primereact/dropdown";
import HighchartsReact from "highcharts-react-official";
import { AppContext, API } from "../../utils";
import html2canvas from "html2canvas";

import DropdownImg from "../../images/dropdown1.png";
import { ReactComponent as UpIcon } from "../../images/upIcon.svg";
import { ReactComponent as DownIcon } from "../../images/downIcon.svg";

const SpLine = (props) => {
  //console.log('SpLine ==', props);
  const [state, setState] = React.useContext(AppContext);

  function convert_format_date(date_to_convert) {
    let date = new Date(date_to_convert);

    // Get year, month, and day part from the date
    let year = date.toLocaleString("default", { year: "numeric" });
    let month = date.toLocaleString("default", { month: "2-digit" });
    let day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    let formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }
  const { t, i18n } = useTranslation();

  function day_or_month(date_one, date_two) {
    let date1 = new Date(date_one);
    let date2 = new Date(date_two);

    let diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let type = " ";
    if (diffDays <= 31) {
      type = "day";
    } else {
      type = "month";
    }

    return type;
  }

  let date_form = convert_format_date(state.reportingPeriod[0].toDateString());
  let date_to = convert_format_date(state.reportingPeriod[1].toDateString());

  const [type_option, setType_option] = useState(
    day_or_month(date_form, date_to)
  );
  const [type_option1, setType_option1] = useState(
    day_or_month(date_form, date_to)
  );
  const [valueDropdownOption, setValueDropdownOption] = useState(
    day_or_month(date_form, date_to)
  );
  const [valueDropdownOption1, setValueDropdownOption1] = useState(
    day_or_month(date_form, date_to)
  );

  //console.log("SpLine -->", props);

  const socialType = props.data.socialType;

  let options = {
    credits: false,

    chart: {
      type: "spline",
      zoomType: "xy",
      style: {
        fontFamily: "Inter",
      },
    },
    title: {
      text: t("Performance_report.likes_evolution"),
      align: "left",
      margin: 50,
    },
    subtitle: {
      text: t("Performance_report.likes_evolution_desc"),
      align: "left",
      margin: 50,
    },
    xAxis: {
      categories: [],
      accessibility: {
        description: "Months of the year",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value + "";
        },
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
          enabled: false,
        },
      },
    },
    series: [{}],
  };

  let options1 = {
    credits: false,

    chart: {
      type: "spline",
      zoomType: "xy",
      style: {
        fontFamily: "Inter",
      },
    },
    title: {
      text: t("Performance_report.audience_growth"),
      align: "left",
      margin: 50,
    },
    subtitle: {
      text: t("Performance_report.audience_growth_desc"),
      align: "left",
      margin: 50,
    },
    xAxis: {
      categories: [],
      accessibility: {
        description: "Months of the year",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value;
        },
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
      spline: {
        lineWidth: 4,
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
          enabled: false,
        },
      },
    },
    series: [{}],
  };

  const previous_total = props.data.total.previous_total;
  const fans = props.data.total.page_fans
    ? props.data.total.page_fans.toLocaleString()
    : 0;
  const fans_perdus = props.data.total.page_fan_removes.toLocaleString() ?? 0;
  const fans_ajoutes = props.data.total.page_fan_adds.toLocaleString() ?? 0;
  const fans_total =
    props.data.total.page_fan_adds_total.Total.toLocaleString() ?? 0;
  const fans_paid =
    props.data.total.page_fan_adds_total.Paid.toLocaleString() ?? 0;
  const fans_organic =
    props.data.total.page_fan_adds_total.Organic.toLocaleString() ?? 0;

  const variation_page_fans =
    props.data.total.previous_total.variation_page_fans ?? 0;
  const previous_end_date = props.data.date_info.previous_end_day ?? 0;
  const previous_start_date = props.data.date_info.previous_start_day ?? 0;

  const variation_page_fans_adds = previous_total.previous_page_fan_adds
    ? ((props.data.total.page_fan_adds -
        previous_total.previous_page_fan_adds) /
        previous_total.previous_page_fan_adds) *
        100 ?? 0
    : 0;
  const variation_page_fans_adds_paid = previous_total.previous_fan_adds_paid
    ? ((props.data.total.page_fan_adds_total.Paid -
        previous_total.previous_fan_adds_paid) /
        previous_total.previous_fan_adds_paid) *
        100 ?? 0
    : 0;
  const variation_fan_adds_organic = previous_total.previous_fan_adds_organic
    ? ((props.data.total.page_fan_adds_total.Organic -
        previous_total.previous_fan_adds_organic) /
        previous_total.previous_fan_adds_organic) *
        100 ?? 0
    : 0;
  const variation_page_fan_removes = previous_total.previous_page_fan_removes
    ? ((props.data.total.page_fan_removes -
        previous_total.previous_page_fan_removes) /
        previous_total.previous_page_fan_removes) *
        100 ?? 0
    : 0;

  //--------------------------------------------------------------------
  // Initialiser un objet vide pour stocker les résultats finaux
  const propsDay1 = Object.entries(props.data.by_day);
  let results = {};
  propsDay1.forEach((item) => {
    let date = item[0];
    let propsDay1 = item[1];
    let fans = propsDay1.page_fans || 0;
    results[date] = {
      fans: fans,
    };
  });

  // Convertir l'objet results en tableau d'objets [{ date: "2023-07-01", fans: 165618 }, ...]
  let resultsArray = Object.entries(results).map(([date, data]) => ({
    date,
    fans: data.fans,
  }));

  // Trier le tableau d'objets par date (du plus ancien au plus récent)
  resultsArray.sort((a, b) => a.date.localeCompare(b.date));

  // Extraire les dates et les fans dans deux tableaux séparés
  let dates = resultsArray.map((item) => item.date);
  let fansCount = resultsArray.map((item) => item.fans);

  //--------------------------------------------------------------------

  //-- day data
  const day_array = [];
  const day_array_fans = [];
  const day_array_fan_adds = [];
  const day_array_fan_removes = [];
  const propsDay = Object.entries(props.data.by_day);
  for (const [key, value] of Object.entries(propsDay)) {
    day_array.push(value[0]);
    const jsonString = value[1].page_fan_adds_by_paid_non_paid_unique;
    const parsedData = jsonString ? JSON.parse(jsonString) : null;
    const totalValue = parsedData?.total || 0;
    /*if (value[1].page_fans)*/ day_array_fans.push(value[1].page_fans ?? 0);
    /*if (value[1].page_fan_adds)*/ /*day_array_fan_adds.push(
      totalValue
    );*/
    day_array_fan_adds.push(value[1].page_fan_adds);
    /*if (value[1].page_fan_removes)*/ day_array_fan_removes.push(
      -Math.abs(value[1].page_fan_removes ?? 0)
    );
  }

  //---- INSTAGRAM
  const day_instagram_array = [];
  const day_array_fan_adds_instagram = [];
  const propsDayInstagram = props.data.instagram.by_day
    ? Object.entries(props.data.instagram.by_day)
    : [];
  for (const [key, value] of Object.entries(propsDayInstagram)) {
    day_instagram_array.push(value[0]);
    day_array_fan_adds_instagram.push(value[1].page_fans_adds ?? 0);
  }

  const day_fans = {
    name: t("Performance_report.like_mention"),
    data: fansCount,
  };
  const day_fan_adds = {
    name: t("Performance_report.page_like_mention"),
    data:
      socialType == "facebook"
        ? day_array_fan_adds
        : day_array_fan_adds_instagram,
  };

  //console.log('day_array ', day_array)
  //console.log('day_instagram_array', day_instagram_array)

  const day_fan_removes = {
    name: t("Performance_report.unlike_mention"),
    data: day_array_fan_removes,
  };

  function day_data_option() {
    options.series.push(day_fan_adds);
    if (socialType == "facebook") options.series.push(day_fan_removes);
    if (socialType == "facebook") {
      //console.log("props.cmpData.data", day_array);
      sort_array(day_array).map((entry, index) => {
        options.xAxis.categories.push(entry);
      });
    } else if (socialType == "instagram" || socialType == "linkedin") {
      //console.log("props.cmpData.data", day_array);
      day_instagram_array.map((entry, index) => {
        options.xAxis.categories.push(entry);
      });
    }
  }

  function day_data_option1() {
    options1.series.push(day_fans);
    sort_array(day_array).map((entry, index) => {
      options1.xAxis.categories.push(entry);
    });
  }

  //-- month data
  const myData = props.cmpData.data;

  let page_fan_adds_instagram_month = [];
  let page_fan_adds_instagram_array = [];
  let myData_instagram = props.data.instagram.by_month
    ? Object.entries(props.data.instagram.by_month)
    : [];
  for (const [key, value] of Object.entries(myData_instagram)) {
    page_fan_adds_instagram_month.push(value[0] ?? 0);
    page_fan_adds_instagram_array.push(+value[1].page_fans_adds ?? 0);
  }

  const page_fans_array = myData.map((item) => item.page_fans);
  const page_fans = {
    name: "page fans",
    data: page_fans_array,
  };

  const page_fan_adds_array = myData.map((item) => item.page_fan_adds);
  const page_fan_adds = {
    name: "page fans adds",
    data:
      socialType == "facebook"
        ? page_fan_adds_array
        : page_fan_adds_instagram_array,
    color: "#6955e6",
  };

  const page_fan_removes_array = myData.map((item) => item.page_fan_removes);
  const array_remove_fan = [];
  for (const tt in page_fan_removes_array) {
    array_remove_fan.push(-Math.abs(page_fan_removes_array[tt]));
  }
  const page_fan_removes = {
    name: "page fans removes",
    data: array_remove_fan,
    color: "#f1c658",
  };

  const status = [
    { name: t("Options.month"), code: "month" },
    { name: t("Options.day"), code: "day" },
  ];

  function convertToMonth(dateString) {
    const date = new Date(dateString);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = date.getMonth();
    return monthNames[monthIndex];
  }

  function month_data_option() {
    options.series.push(page_fan_adds);
    if (socialType == "facebook") options.series.push(page_fan_removes);

    if (socialType == "facebook") {
      props.cmpData.data.map((entry, index) => {
        options.xAxis.categories.push(entry.name);
      });
    } else if (socialType == "instagram" || socialType == "linkedin") {
      page_fan_adds_instagram_month.map((entry, index) => {
        const dateString = entry;
        const month = convertToMonth(dateString);
        options.xAxis.categories.push(month);
      });
    }
  }

  // function month_data_option1() {
  //   options1.series.push(page_fans);
  //   props.cmpData.data.map((entry, index) => {
  //     options1.xAxis.categories.push(entry.name);
  //   });
  // }

  function formatDate(input) {
    var date = new Date(input);
    return (
      date.toLocaleString("default", { month: "short" }) + " " + date.getDate()
    );
  }

  const month_array = [];
  const month_array_fans = [];
  const propsMonth = Object.entries(props.data.page_fans_month);

  for (const [key, value] of Object.entries(propsMonth)) {
    month_array.push(value[0]);
    month_array_fans.push(+value[1].page_fans ?? 0);
  }

  const page_fans_month = {
    name: "page fans",
    data: month_array_fans,
  };

  function month_data_option1() {
    options1.series.push(page_fans_month);
    month_array.map((entry, index) => {
      options1.xAxis.categories.push(formatDate(entry));
    });
  }

  //-- option
  type_option === "month" ? month_data_option() : day_data_option();
  const onOptionChange = (e) => {
    setValueDropdownOption(e.value);
    if (e.value === "month") setType_option("month");
    if (e.value === "day") setType_option("day");
  };

  //-- option1
  type_option1 === "month" ? month_data_option1() : day_data_option1();
  const onOptionChange1 = (e) => {
    setValueDropdownOption1(e.value);
    if (e.value === "month") setType_option1("month");
    if (e.value === "day") setType_option1("day");
  };

  function sort_array(dates) {
    dates.sort(function (a, b) {
      let dateA = new Date(a);
      let dateB = new Date(b);
      return dateA - dateB;
    });
    return dates;
  }

  function remove_empty_value(arr) {
    var index;
    arr.some(function (obj, i) {
      return Object.keys(obj).length === 0 && obj.constructor === Object
        ? ((index = i), true)
        : false;
    });
    arr.shift(index);
    return index;
  }
  remove_empty_value(options.series);
  remove_empty_value(options1.series);

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");
    parent.classList.add("capture_style");

    html2canvas(parent, {
      onclone: (document) => {},
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        parent.classList.remove("capture_style");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  return (
    <div>
      <div className="highchart_block">
        <div data-value="Croissance de l’audience" className="highchart_block1">
          <div data-html2canvas-ignore className="flex items-center">
            <div className="flex items-center ml-auto border rounded-md capitalize">
              <img src={DropdownImg} className="pl-2" alt="DropdownImg" />
              <Dropdown
                value={valueDropdownOption}
                options={status}
                onChange={onOptionChange}
                defaultValue={setType_option}
                optionLabel="name"
                optionValue="code"
                placeholder="Choisir"
                className="font-medium"
              />
            </div>

            <button
              onClick={printChart}
              className="mx-5 w-5 h-5 print_capture"
            ></button>
          </div>

          <HighchartsReact highcharts={Highcharts} options={options} />

          <div className="capture_style"></div>
        </div>
      </div>
      <div className="highchart_block">
        <div data-value="Croissance de l’audience" className="highchart_block1">
          <div data-html2canvas-ignore className="flex items-center">
            <div className="flex items-center ml-auto border rounded-md capitalize">
              <img src={DropdownImg} className="pl-2" alt="DropdownImg" />
              <Dropdown
                value={valueDropdownOption1}
                options={status}
                onChange={onOptionChange1}
                defaultValue={setType_option1}
                optionLabel="name"
                optionValue="code"
                placeholder="Choisir"
                className="font-medium"
              />
            </div>

            <button
              onClick={printChart}
              className="mx-5 w-5 h-5 print_capture"
            ></button>
          </div>

          <HighchartsReact highcharts={Highcharts} options={options1} />

          <div className="info_chart_fans flex mt-15">
            <div className="w-1/2">
              <ul className="flex border-[#dee0e1] border-b font-bold">
                <li className="flex text-[#344563] text-[14px] font-['inter']">
                  <div className="w-3 h-3 rounded-full bg-[#5596e6] mr-1.5 circle_title"></div>
                  {t("Performance_report.Total_fans")}
                </li>
                <li className="ml-auto flex">{fans}</li>
                <li className="ml-auto flex">
                  {variation_page_fans > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                </li>
                <li className="ml-auto flex">
                  {variation_page_fans.toFixed(2) || 0}%
                </li>
              </ul>
              <ul className="flex border-[#dee0e1] border-b font-bold">
                <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                  <div className="w-3 h-3 rounded-full bg-[#6955e6] mr-1.5"></div>
                  {t("Performance_report.new_likes")}
                </li>
                <li className="ml-auto flex items-center">{fans_ajoutes}</li>
                <li className="ml-auto flex items-center">
                  {variation_page_fans_adds > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                </li>
                <li className="ml-auto flex items-center">
                  {variation_page_fans_adds.toFixed(2) || 0}%
                </li>
              </ul>

              {socialType == "facebook" ? (
                <div>
                  <ul className="flex border-[#dee0e1] border-b">
                    <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                      <div className="w-3 h-3 rounded-full bg-[#22c4ac] mr-1.5 circle_title"></div>
                      {t("Performance_report.paid_likes")}
                    </li>
                    <li className="ml-auto flex items-center">{fans_paid}</li>
                    <li className="ml-auto flex items-center">
                      {variation_page_fans_adds_paid > 0 ? (
                        <div
                          style={{
                            width: 15,
                            marginLeft: 15,
                            marginRight: 5,
                            fill: "green",
                          }}
                        >
                          <UpIcon />
                        </div>
                      ) : (
                        <div
                          style={{
                            width: 15,
                            marginLeft: 15,
                            marginRight: 5,
                            fill: "red",
                          }}
                        >
                          <DownIcon />
                        </div>
                      )}
                    </li>
                    <li className="ml-auto flex items-center">
                      {variation_page_fans_adds_paid.toFixed(2) || 0}%
                    </li>
                  </ul>
                  <ul className="flex border-[#dee0e1] border-b">
                    <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                      <div className="w-3 h-3 rounded-full bg-[#f6d991] mr-1.5 circle_title"></div>
                      {t("Performance_report.organic_likes")}
                    </li>
                    <li className="ml-auto flex items-center">
                      {fans_organic}
                    </li>
                    <li className="ml-auto flex items-center">
                      {variation_fan_adds_organic > 0 ? (
                        <div
                          style={{
                            width: 15,
                            marginLeft: 15,
                            marginRight: 5,
                            fill: "green",
                          }}
                        >
                          <UpIcon />
                        </div>
                      ) : (
                        <div
                          style={{
                            width: 15,
                            marginLeft: 15,
                            marginRight: 5,
                            fill: "red",
                          }}
                        >
                          <DownIcon />
                        </div>
                      )}
                    </li>
                    <li className="ml-auto flex items-center">
                      {variation_fan_adds_organic.toFixed(2) || 0}%
                    </li>
                  </ul>
                  <ul className="flex border-[#dee0e1] border-b font-bold">
                    <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                      <div className="w-3 h-3 rounded-full bg-[#f1c658] mr-1.5 circle_title"></div>
                      {t("Performance_report.unlikes")}
                    </li>
                    <li className="ml-auto flex items-center">{fans_perdus}</li>
                    <li className="ml-auto flex items-center">
                      {variation_page_fan_removes > 0 ? (
                        <div
                          style={{
                            width: 15,
                            marginLeft: 15,
                            marginRight: 5,
                            fill: "green",
                          }}
                        >
                          <UpIcon />
                        </div>
                      ) : (
                        <div
                          style={{
                            width: 15,
                            marginLeft: 15,
                            marginRight: 5,
                            fill: "red",
                          }}
                        >
                          <DownIcon />
                        </div>
                      )}
                    </li>
                    <li className="ml-auto flex items-center">
                      {variation_page_fan_removes.toFixed(2) || 0}%
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
            <div className="w-1/2">
              <div className="audience_total bg-rectangle rounded-[8px] ml-10 h-full">
                <div className="flex items-center">
                  <div className="w-1/5">
                    <h2 className="text-[#344563] text-[16px] font-['inter']">
                      {t("Performance_report.audience_metrics")}
                    </h2>
                  </div>
                  <div className="w-4/5">
                    <span className="text-[#152935] text-[24px] font-['inter'] font-extrabold">
                      {fans} Fans
                    </span>
                  </div>
                </div>
                {socialType == "facebook" ? (
                  <div className="flex items-center mt-9">
                    <div className="w-1/5">
                      <span className="text-[#344563] text-[14px] font-['inter']  font-bold">
                        {variation_page_fans
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                        %
                      </span>
                    </div>
                    <div className="w-4/5">
                      <span className="text-[#344563] text-[14px] font-['inter']">
                        {t("Options.compared")}
                        <span className="font-bold">
                          {" "}
                          {previous_end_date} - {previous_start_date}
                        </span>
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SpLine;
