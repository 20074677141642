import React, { useState, useEffect } from 'react';
import { API, AppContext } from "../utils";
import { InputSwitch } from 'primereact/inputswitch';
import { TabView, TabPanel } from 'primereact/tabview';

import paysCurrency from '../../src/countries';

const Abonnements = (props) => {
    const [state, setState] = React.useContext(AppContext);
    const [activeIndexes, setActiveIndexes] = useState([]);

    const [packages, setPackages] = useState([]);

    console.log('props', props);

    const loadPackages = async () => {
        try {
          const res = await API.payment.get();
          console.log('==>', res.data?.data);
          setPackages(res.data?.data);
          setActiveIndexes(new Array(res.data?.data.length).fill(0));
        } catch (e) {
          console.log(e);
        }
    };

    useEffect(() => {
      loadPackages();
    }, []);

    const handleSwitchChange = (index) => {
      setActiveIndexes(prevIndexes => {
        const newIndexes = [...prevIndexes];
        newIndexes[index] = (newIndexes[index] === 0) ? 1 : 0;
        return newIndexes;
      });
    };

    let onChoose = (planCommitmentId) => {
      let url = process.env.REACT_APP_API_URL + '/api/package-offers/subscription/payment/' + planCommitmentId;
      let token = state.token;
      
      let windowName = 'w_' + Date.now() + Math.floor(Math.random() * 100000).toString();
      windowName = '_self';
      var form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", url);
  
      form.setAttribute("target", windowName);
  
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "_token_");
      hiddenField.setAttribute("value", token);
      form.appendChild(hiddenField);
      document.body.appendChild(form);
  
      // window.open('', windowName);
  
      form.submit();
    };

    console.log('packages', packages);
    
    // Fonction pour récupérer la devise en fonction du code pays
    function getCurrencyByCountryName(countryName) {
      const country = paysCurrency.find(country => country.name === countryName);
      return country ? country.currency : null;
    }
    
    const paysName = props?.userData?.pays;
    const currency = getCurrencyByCountryName(paysName);

    console.log('currency', currency);
    console.log('props', paysName);

    return (
      <div className="block_abonnement">

        <div className="grid grid-cols-4 gap-4">
          {packages.map((pkg, index) => (
            <div key={index} className='box_abonnement bg-white p-5'>
              <TabView activeIndex={activeIndexes[index]} onTabChange={(e) => setActiveIndexes(prevIndexes => {
                const newIndexes = [...prevIndexes];
                newIndexes[index] = e.index;
                return newIndexes;
              })}>
                <TabPanel>
                  <span className='text-[18px] text-[#7F56D9] mb-5 font-semibold block'>{pkg.plan.name}</span>

                    {currency === 'TND' && (
                      <span className='text-[36px] font-semibold block'>
                        {pkg.pricings[0]?.price_tnd}{currency}
                        </span>
                    )}
                    {currency === '€' && (
                      <span className='text-[36px] font-semibold block'>
                        {pkg.pricings[0]?.price_euro}{currency}
                        </span>
                    )}
                    {currency === '$' && (
                      <span className='text-[36px] font-semibold block'>
                        {pkg.pricings[0]?.price_dollar}{currency}
                        </span>
                    )}
                    <span className='text-[14px] font-semibold block text-[#475467]'>{pkg.pricings[0]?.commitment.name}</span>
                </TabPanel>
                <TabPanel>
                  <span className='text-[36px] font-semibold block'>                   
                    {currency === 'TND' && (
                      <span className='text-[36px] font-semibold block'>
                        {pkg.pricings[1]?.price_tnd}{currency}
                        </span>
                    )}
                    {currency === '€' && (
                      <span className='text-[36px] font-semibold block'>
                        {pkg.pricings[1]?.price_euro}{currency}
                        </span>
                    )}
                    {currency === '$' && (
                      <span className='text-[36px] font-semibold block'>
                        {pkg.pricings[1]?.price_dollar}{currency}
                        </span>
                    )}
                  </span>
                  <span className='text-[14px] font-semibold block text-[#475467]'>{pkg.pricings[1]?.commitment.name}</span>
                </TabPanel>
              </TabView>
              <InputSwitch 
                className='my-3'
                checked={activeIndexes[index] === 1} 
                onChange={() => handleSwitchChange(index)} 
              />
              <ul className="text-[14px] font-normal block text-[#475467] leading-[25px]">
                  {pkg.plan.description.split('\n').map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              <TabView activeIndex={activeIndexes[index]} onTabChange={(e) => setActiveIndexes(prevIndexes => {
                const newIndexes = [...prevIndexes];
                newIndexes[index] = e.index;
                return newIndexes;
              })}>
                <TabPanel>
                  <button 
                    onClick={() => { onChoose(pkg.pricings[0]?.id) }}
                    className='text-white text-[18px] bg-[#7F56D9] rounded-[8px] font-semibold w-full mt-5	px-3 py-2'
                  >Get started</button>
                </TabPanel>
                <TabPanel>
                  <button 
                    onClick={() => { onChoose(pkg.pricings[1]?.id) }}
                    className='text-white text-[18px] bg-[#7F56D9] rounded-[8px] font-semibold w-full mt-5	px-3 py-2'
                  >Get started</button>
                </TabPanel>
              </TabView>
            </div>
          ))}
        </div>
        
      </div>
    );
};

export default Abonnements;
