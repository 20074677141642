import React, { useEffect, useState } from "react";
import { API, AppContext, SocialIcon, useUtils } from "../../../../utils";
import { Questions, Results, Egality } from "./BrandMeterData";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Tag } from "primereact/tag";
import style from "./BrandMeter.module.css";
import { confirmDialog } from "primereact/confirmdialog";

import bg1 from "../../../../images/Slice3.png"
import bg2 from "../../../../images/Slice2.png"


const QPage = (props) => {
  return (
    <div className="">
      {props.questions.elements.map((element, eidx) => {
        return (
          <div key={eidx} className="bg-white p-4 rounded-md m-4 shadow">
            <h3 className="font-semibold mb-4">{`${element.title}`}</h3>
            {element.choices.map((choice) => {
              return (
                <div key={choice.value} className={"items-center flex gap-1" }>
                  <RadioButton
                  className="mt-2"
                    inputId={`choice_${eidx}_${choice.value}`}
                    name={`choice_${eidx}`}
                    value={choice.value}
                    onChange={(e) => props.onChange(e.value, eidx)}
                    checked={props.selection[eidx] == choice.value}
                  />
                  <label htmlFor={`choice_${eidx}_${choice.value}`}>
                    {`${choice.text}`}
                  </label>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const BrandMeter = (props) => {
  const [status, setStatus] = useState({
    pageIdx: 0,
    selections: {},
    scores: {},
  });
  const [state, setState] = React.useContext(AppContext);

  useEffect(() => {
    if (state.currentPage.data?.brandmeter) {
      setStatus((s) => ({ ...s, bdata: state.currentPage.data?.brandmeter }));
    }
  }, []);

  let changePage = (nb, event) => {
    if (nb == 0 || nb > Questions.pages.length) return;
    if (
      status.pageIdx &&
      Object.keys(status.selections[status.pageIdx] || {}).length != 4 &&
      nb > status.pageIdx
    ) {
      confirmDialog({
        target: event.currentTarget,
        message: "Merci de compléter les questions de la page",
        rejectClassName: "hidden",
        accept: null,
        reject: null,
        acceptLabel: "OK",
        position: "bottom",
      });
      return;
    }
    if (nb === Questions.pages.length) {
      let bdata = {};
      bdata.max = 0;
      Questions.pages.forEach(
        (p, pidx) =>
          (bdata.max =
            (status.scores[pidx] || 0) > bdata.max
              ? status.scores[pidx]
              : bdata.max)
      );
      bdata.maxIdxs = Object.keys(status.scores).filter(
        (pidx) => status.scores[pidx] == bdata.max
      );
      bdata.max2 = 0;
      Questions.pages.forEach(
        (p, pidx) =>
          (bdata.max2 =
            (status.scores[pidx] || 0) > bdata.max2 &&
            status.scores[pidx] < bdata.max
              ? status.scores[pidx]
              : bdata.max2)
      );
      bdata.max2Idxs = Object.keys(status.scores).filter(
        (pidx) => status.scores[pidx] == bdata.max2
      );
      bdata.selection = { 0: bdata.maxIdxs[0] };
      bdata.choices = status.selections;
      console.log({ bdata, status });
      if (bdata.maxIdxs.length == 1) save(bdata);
      else {
        bdata.egality = bdata.maxIdxs.length;
        setStatus((s) => ({ ...s, bdata }));
      }
      return;
    }
    setStatus((s) => ({ ...s, pageIdx: nb }));
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };
  let save = (bdata) => {
    API.run(async () => {
      setStatus((s) => ({ ...s, saving: true }));
      await API.pages.put(state.currentPage.id, {
        data: { brandmeter: bdata },
      });
      setStatus((s) => ({ ...s, saving: false, bdata }));
    });
  };
  let onQChange = (val, idx) => {
    let selections = status.selections;
    if (!selections[status.pageIdx]) selections[status.pageIdx] = {};
    selections[status.pageIdx][idx] = val;
    let scores = status.scores;
    scores[status.pageIdx] = 0;
    Object.values(selections[status.pageIdx]).forEach(
      (e) => (scores[status.pageIdx] += parseInt(e))
    );
    console.log({ scores });
    setStatus((s) => ({ ...s, selections, scores }));
  };
  let restart = () => {
    setStatus((s) => ({ ...s, pageIdx: 1, bdata: null }));
  };
  let contact = (b = true) => {
    setStatus((s) => ({ ...s, contact: b }));
  };
  let questions = Questions.pages[status.pageIdx];

  let rs = [];
  if (status.bdata?.maxIdxs?.length) {
    rs.push(status.bdata.selection[0]);
    rs.push(status.bdata.max2Idxs[0]);
  }

  let renderQuestion = () => {
    if (status.pageIdx == 0)
      return (
        <div className="m-24">
          <h1 className="text-asap-blue dark:text-asap-white text-xl font-Montserrat">
            <strong>{"Nous creusons votre marque !"}</strong>
          </h1>
          <br />
          <br />
          <span className="parag paragTwo text-asap-blue dark:text-asap-white text-lg font-Montserrat">
            {"On y est presque!"}
          </span>
          <br />
          <br />
          <br />
          <br />
          <Button
            onClick={(event) => changePage(status.pageIdx + 1, event)}
            icon="pi pi-arrow-circle-right"
            iconPos="right"
            label={"Commencer"}
            className=" float-right bg-diggow-blue  rounded-md font-medium"
          />
        </div>
      );
    return (
      <div className="mt-4 mb-40">
        <div className="float-right mr-4 bg-indigo-400">
          <Tag
            value={`Page ${status.pageIdx} / ${Questions.pages.length - 1}`}
          ></Tag>
        </div>
        <QPage
          questions={questions}
          selection={status.selections[status.pageIdx] || {}}
          onChange={onQChange}
        />
        <Button
          onClick={(event) => changePage(status.pageIdx - 1, event)}
          icon="pi pi-arrow-circle-left"
          iconPos="left"
          label={"Précédent"}
          disabled={status.pageIdx == 1}
          className="bg-diggow-blue  rounded-md font-medium float-left m-4"
        />
        <Button
          onClick={(event) => changePage(status.pageIdx + 1, event)}
          icon="pi pi-arrow-circle-right"
          iconPos="right"
          label={
            status.pageIdx == Questions.pages.length - 1
              ? "Terminer"
              : "Suivant"
          }
          loading={status.saving}
          className="bg-diggow-blue  rounded-md font-medium float-right m-4"
        />
      </div>
    );
  };

  let renderEgality = () => {
    if (!status.bdata?.egality) return null;
    let n = "more_than_1";
    if (status.bdata.egality == 3) n = "more_than_2";
    else if (status.bdata.egality > 3) n = "more_than_3";
    let q = {
      elements: [
        {
          title: Egality[n],
          choices: status.bdata.maxIdxs.map((e) => ({
            value: e,
            text: Results[e].type,
          })),
        },
      ],
    };
    return (
      <div className="">
        {/* <Contact visible={status.contact} modal onHide={() => contact(false)} /> */}
        <div className="w-full text-right">
          <Button
            onClick={contact}
            label="Contactez-nous pour vous aider"
            className="p-button-default"
          />
        </div>
        <QPage
          questions={q}
          selection={status.bdata.selection || {}}
          onChange={(val) => {
            setStatus((s) => ({
              ...s,
              bdata: { ...status.bdata, selection: { 0: val } },
            }));
          }}
        />
        <Button
          onClick={() => save({ ...status.bdata, egality: false })}
          loading={status.saving}
          label="Choisir mon archetype de base"
          className="bg-diggow-blue  rounded-md font-medium float-right m-2"
        />
        <Button
          onClick={restart}
          label="Reprendre la questionnaire"
          className="bg-diggow-blue  rounded-md font-mediumy float-left m-2"
        />
      </div>
    );
  };

  let renderResult = (result, title) => {
    console.log("test" , result , title);
    if (!result) return null;
    return (

    
      <Card
        className=" rounded-md mt-14 mx-6 w-full"
        title={false}
        style={{
            backgroundImage: `url(${!title.includes('bord') ? bg1 : bg2})`,
            backgroundPosition: "right",
            // backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
      >
        <h1 className="text-indigo-500 font-bold ml-4">{title}</h1>
        <div
          className="mt-6 text-4xl font-bold ml-4 " 
        >
         <h1 className="text-black">{result.type}</h1> 
        </div>
        <div className="text-md  text-black z-50 w-11/12 ml-4 mt-12">
          {result.description}
        </div>
        <div className="bg-gradient-to-r p-6 rounded-lg from-cyan-500 via-indigo-500 to-indigo-500 w-full mt-6">
          <h1 className="text-white font-medium">{"Votre message clé"}</h1>
         <h2 className="mt-1 text-gray-100 text-lg">{result.message}</h2> 
        </div>
        <div className="mt-6 ml-4">
          <h1 className="font-bold text-black">{"Tonalité vocale"}</h1>
          <h2 className="text-black">{result.voice_tone}</h2>
        </div>

        <div className="">
          <h1 className="font-bold text-black mt-6 ml-4">
            {"Les marques qui vous ressemblent"}
          </h1>
<div className="grid grid-flow-col mt-6 ">
    

          {result.examples_name.map((el, idx) => (
            <div className="items-center flex flex-col" key={`b_${idx}`}>
              <img className="w-auto h-8" src={`/brand_logo/${result.examples_logo[idx]}.png`} />
              {el}
            </div>
          ))}
</div>
        </div>
      </Card>
    );
  };

  if (!status.bdata) return renderQuestion();
  if (status.bdata.egality) return renderEgality();
  return (
    <>
          <h1 className="mt-4 ml-4 font-bold text-2xl">Brand Reacap</h1>
    <div
      className={
        style.brandmeter_cnt + "  flex flex-col mx-auto  gap-4 md:flex-row"
      }
    >
      {renderResult(Results[rs[0]], "L'archétype central")}
      {renderResult(Results[rs[1]], "L'archétype du bord")}
    </div>
    </>
  );
};

export default BrandMeter;
