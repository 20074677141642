import React, { useCallback, useState } from "react";
// import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
// import ClusterPie from "./ClusterPie";
import { AppContext, SocialIcon, useUtils, getNextColor } from "../../utils";
// import { ReactComponent as I } from "../../images/i.svg";
// import { useMediaQuery } from "react-responsive";
// import { useTranslation } from 'react-i18next';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import html2canvas from "html2canvas";

import angryEmoji from "../../images/emojiAngry.png";
import emojiHaha from "../../images/emojiHaha.png";
import emojiLike from "../../images/emojiLike.png";
import emojiLove from "../../images/emojiLove.png";
import emojiSad from "../../images/emojiSad.png";
import emojiWow from "../../images/emojiWow.png";

import { ReactComponent as UpIcon } from "../../images/upIcon.svg";
import { ReactComponent as DownIcon } from "../../images/downIcon.svg";
import millify from "millify";

// const data = [
//   { name: "Photo", value: 500 },
//   { name: "Video", value: 300 }
// ];
let legends = [];
const COLORS = ["#596ae9", "#6955e6", "#22c4ac", "orange"];

// const RADIAN = Math.PI / 145;
// const renderCustomizedLabel = ({
//   cx,
//   cy,
//   midAngle,
//   innerRadius,
//   outerRadius,
//   percent,
//   index,
//   name
// }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.36;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text
//       x={x}
//       y={y}
//       fill="white"
//       textAnchor={x > cx ? "start" : "end"}
//       dominantBaseline="central"
//     >
//       {`${name} ${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };

// const PieCmp = (props) => {
//   const { t, i18n } = useTranslation();

//   let pied = [...props.cmpData.data]
//   // pied.forEach(el => {
//   //   if (!el.name.includes('Performance_Report')) {
//   //     el.name = t(`Performance_Report.${el.name}`)
//   //   }
//   // })

//   console.log("cmpd", pied);
//   const isSmallScreen = useMediaQuery({ minWidth: 869, maxWidth: 921 });
//   const isXsmallScreen = useMediaQuery({ query: "(max-width: 560px)" });
//   if (props.config.var == 'cluster') return <ClusterPie {...props} />;
//   return (
//     <ResponsiveContainer
//       width="99%"
//       height={480}
//     >
//       <PieChart >
//         <Tooltip />
//         <Pie
//           data={pied}
//           labelLine={true}
//           label={true}
//           outerRadius={isSmallScreen || isXsmallScreen ? 150 : 200}
//           fill="#8884d8"
//           dataKey={props.cmpData.dataKey || "value"}
//         >
//           {props.cmpData.data.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//           ))}
//         </Pie>
//       </PieChart>
//     </ResponsiveContainer>
//   )
// }

const PieCmp2 = (props) => {
  const socialType = props.data.socialType;

  const [state, setState] = React.useContext(AppContext);
  //console.log("state.reportingPeriod", state.reportingPeriod);

  // const { t, i18n } = useTranslation();
  const [color] = useState(getNextColor());
  // if (props.config.var == 'cluster') return <ClusterPie {...props} />;
  let options = {
    credits: false,
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 600,
      style: {
        fontFamily: "Inter",
      },
    },
    colors: COLORS,
    title: {
      text: "",
    },
    // subtitle: {
    //   text: props.config?.title || "",
    // },
    tooltip: {
      pointFormat: "<b>{point.percentage:.1f}%</b><br/>{point.y}",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        size: "70%",
      },
    },
    // legend: {
    //   accessibility: {
    //     enabled: true,
    //     keyboardNavigation: {
    //       enabled: true,
    //     },
    //   },
    //   bubbleLegend: {
    //     enabled: true,
    //     labels: {
    //       align: "right",
    //     },
    //   },
    //   className: "capitalize",
    //   enabled: true,
    //   labelFormat: "{name}",
    //   rtl: true,
    // },
    series: [
      {
        innerSize: "50%",
        pie: {},
        colorByPoint: true,
        data: [],
      },
    ],
  };
  options.series[0].data = [];
  props.cmpData.data.map((entry, index) => {
    options.series[0].data.push({
      name: entry.name,
      y: entry[props.cmpData.dataKey],
    });
  });

  let metric = props.data.metrics[props.config.metrics[0]];
  if (props.config.var == "cluster") {
    // options.series[0].innerSize = "80%";
    // options.plotOptions.pie.showInLegend = false;
    // options.plotOptions.pie.size = 100;
    // options.series[0].data.forEach((e) => {
    //   e.color = color;
    //   e.sliced = false;
    // });
    // options.title.text = metric?.name;
    // options.plotOptions.pie.dataLabels.connectorShape = "fixedOffset";
    // options.plotOptions.pie.dataLabels.softConnector = false;
    // options.plotOptions.pie.dataLabels.distance = 10;
    // options.plotOptions.pie.dataLabels.crop = false;
    // options.plotOptions.pie.dataLabels.overflow = "allow";
    // options.plotOptions.pie.ignoreHiddenPoint = false;
    // options.plotOptions.pie.dataLabels.enabled = false;
    return null;
  }
  //console.log("props", props);

  const highchartsCallback = (chart) => {
    //console.log("chart", chart);
    //     chart.series[0].userOptions?.data?.forEach((item, index)=>{
    // console.log("itemname",item.name);
    //     })
    // chart.options.colors.forEach((color) => {
    //   props.cmpData.data.forEach((entry, index) => {
    //     console.log(color, entry);
    //     legends.push({
    //       color,
    //       name: entry.name,
    //       value: entry[props.cmpData.dataKey],
    //     });
    //   });
    // });
  };

  let emojis = {
    posts_love: emojiLove,
    posts_likes: emojiLike,
    posts_angry: angryEmoji,
    posts_haha: emojiHaha,
    posts_sad: emojiSad,
    posts_wow: emojiWow,
  };

  let reactions = [];
  if (
    props.cmpData.dataKey == "posts_type_interactions" ||
    props.cmpData.dataKey == "posts_types"
  ) {
    Object.keys(emojis).forEach((el) => {
      reactions.push({ icon: emojis[el], value: props.data.total[el] });
    });

    // reactions = [
    //   { icon: emojis["posts_angry"], value: props.data.total.posts_angry },
    //   { icon: emojis["posts_haha"], value: props.data.total.posts_haha },
    // ];
  }

  //console.log("reactions", reactions);

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");

    html2canvas(parent, {
      onclone: (document) => {
        // document.querySelector(".info_chart_fans ul li:nth-child(3)").setAttribute("style", "position: relative; top: 7px;");
        // document.querySelector(".info_chart_fans .circle_title").setAttribute("style", "position: relative; top: 5px;");
        // document.querySelector(".info_chart_tabs .box_canvas").setAttribute("style", "display: flex;align-items: center;");
        //document.querySelector(".info_chart_tabs span").setAttribute("style", "transform: translateY(-5px);");
      },
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");
      console.log("data", data);
      console.log("link", link);
      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  const variation_engagement_posts =
    props.data.total.previous_total.variation_engagement_posts ?? 0;
  const variation_count_posts =
    props.data.total.previous_total.variation_count_posts ?? 0;

  const previous_total = props.data.total.previous_total
    ? props.data.total.previous_total
    : 0;

  const previous_end_date = props.data.date_info.previous_end_day ?? 0;
  const previous_start_date = props.data.date_info.previous_start_day ?? 0;

  const variation_posts_photos = previous_total?.previous_posts_photos
    ? ((props.data.total.posts_types.photo -
        previous_total.previous_posts_photos) /
        previous_total.previous_posts_photos) *
        100 ?? 0
    : 0;
  const variation_posts_videos = previous_total.previous_posts_videos
    ? ((props.data.total.posts_types.video -
        previous_total.previous_posts_videos) /
        previous_total.previous_posts_videos) *
        100 ?? 0
    : 0;
  const variation_posts_textes = previous_total.previous_posts_textes
    ? ((props.data.total.posts_types.texte -
        previous_total.previous_posts_textes) /
        previous_total.previous_posts_textes) *
        100 ?? 0
    : 0;

  const variation_posts_clicks = previous_total.previous_posts_clicks
    ? ((props.data.total.posts_clicks - previous_total.previous_posts_clicks) /
        previous_total.previous_posts_clicks) *
        100 ?? 0
    : 0;
  const variation_posts_reactions = previous_total.previous_reactions
    ? ((props.data.total.posts_reactions - previous_total.previous_reactions) /
        previous_total.previous_reactions) *
        100 ?? 0
    : 0;
  const variation_posts_comments = previous_total.previous_posts_comments
    ? ((props.data.total.posts_comments -
        previous_total.previous_posts_comments) /
        previous_total.previous_posts_comments) *
        100 ?? 0
    : 0;
  const variation_posts_shares = previous_total.previous_posts_shares
    ? ((props.data.total.posts_shares - previous_total.previous_posts_shares) /
        previous_total.previous_posts_shares) *
        100 ?? 0
    : 0;
  const variation_posts_other_clicks = previous_total.previous_other_clicks
    ? ((props.data.total.details_clicks.other_clicks -
        previous_total.previous_other_clicks) /
        previous_total.previous_other_clicks) *
        100 ?? 0
    : 0;
  const variation_posts_photo_view = previous_total.previous_photo_view
    ? ((props.data.total.details_clicks.photo_view -
        previous_total.previous_photo_view) /
        previous_total.previous_photo_view) *
        100 ?? 0
    : 0;
  const variation_posts_link_clicks = previous_total.previous_link_clicks
    ? ((props.data.total.details_clicks.link_clicks -
        previous_total.previous_link_clicks) /
        previous_total.previous_link_clicks) *
        100 ?? 0
    : 0;

  /*-- Total Engagement */
  const totalInteractions = props.data?.total?.posts_interactions || 0;
  const clicks = props.data?.total?.posts_type_interactions?.Clicks || 0;
  const total = totalInteractions + clicks;
  const formattedTotal = isNaN(total) ? 0 : total.toLocaleString();

  const renderTotal = () => {
    if (props.cmpData.dataKey == "posts_types") {
      return (
        <div>
          <div className="audience_total bg-rectangle rounded-[8px] mb-10">
            <div className="flex items-center">
              <div className="w-5/5">
                <span className="text-[#152935] text-[14px] font-['inter'] font-bold ">
                  Comportement de publication par type de contenu
                </span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-2/5">
                <h2 className="text-[#344563] text-[14px] font-['inter']">
                  Total des posts publiés
                </h2>
              </div>
              <div className="w-3/5">
                <span className="text-[#152935] text-[24px] font-['inter'] font-extrabold">
                  {props.data?.total?.posts_count} Posts
                </span>
              </div>
            </div>

            <div className="flex items-center mt-9">
              <div className="w-1/5">
                <span className="text-[#344563] text-[14px] font-['inter']  font-bold">
                  {variation_count_posts
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                  %
                </span>
              </div>
              <div className="w-4/5">
                <span className="text-[#344563] text-[14px] font-['inter']">
                  comparé à
                  <span className="font-bold">
                    {" "}
                    {previous_end_date} - {previous_start_date}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Photos :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_types?.photo
                    ? props.data.total.posts_types.photo
                    : 0}
                  {variation_posts_photos > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_photos.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Vidéos :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_types?.video ?? 0}
                  {variation_posts_videos > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_videos.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Textes :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_types?.texte ?? 0}
                  {variation_posts_textes > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_textes.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
          </div>
        </div>
      );
    }
    if (props.cmpData.dataKey == "posts_type_interactions") {
      return (
        <div>
          <div className="audience_total bg-rectangle rounded-[8px] mb-10">
            <div className="flex items-center">
              <div className="w-5/5">
                <span className="text-[#152935] text-[14px] font-['inter'] font-bold ">
                  Mesures de l'engagement
                </span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-2/5">
                <h2 className="text-[#344563] text-[14px] font-['inter']">
                  Total des engagements
                </h2>
              </div>
              <div className="w-3/5">
                <span className="text-[#152935] text-[24px] font-['inter'] font-extrabold">
                  {formattedTotal} Engagement
                </span>
              </div>
            </div>

            {socialType == "facebook" ? (
              <div className="flex items-center mt-9">
                <div className="w-1/5">
                  <span className="text-[#344563] text-[14px] font-['inter']  font-bold">
                    {variation_engagement_posts
                      .toString()
                      .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                    %
                  </span>
                </div>
                <div className="w-4/5">
                  <span className="text-[#344563] text-[14px] font-['inter']">
                    comparé à
                    <span className="font-bold">
                      {" "}
                      {previous_end_date} - {previous_start_date}
                    </span>
                  </span>
                </div>
              </div>
            ) : null}
          </div>

          <div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">
                  Réactions :
                  <div className="flex mx-8 gap-2 justify-center">
                    <div className="flex items-center">
                      <img src={emojiLike} alt="emojiLike" className="mr-1.5" />
                      <p>
                        {(props.data.total.posts_likes ?? 0) +
                          (props.data.total.posts_likes_ad ?? 0) ?? 0}
                      </p>
                    </div>
                    {socialType == "facebook" ? (
                      <div className="flex">
                        <div className="flex items-center">
                          <img
                            src={emojiLove}
                            alt="emojiLove"
                            className="mr-1.5"
                          />
                          <p>{props.data.total.posts_love ?? 0}</p>
                        </div>
                        <div className="flex items-center">
                          <img
                            src={emojiHaha}
                            alt="emojiHaha"
                            className="mr-1.5"
                          />
                          <p>{props.data.total.posts_haha ?? 0}</p>
                        </div>
                        <div className="flex items-center">
                          <img
                            src={angryEmoji}
                            alt="angryEmoji"
                            className="mr-1.5"
                          />
                          <p>{props.data.total.posts_angry ?? 0}</p>
                        </div>
                        <div className="flex items-center">
                          <img
                            src={emojiWow}
                            alt="emojiWow"
                            className="mr-1.5"
                          />
                          <p>{props.data.total.posts_wow ?? 0}</p>
                        </div>
                        <div className="flex items-center">
                          <img
                            src={emojiSad}
                            alt="emojiSad"
                            className="mr-1.5"
                          />
                          <p>{props.data.total.posts_sad ?? 0}</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <span className="title_1 flex items-center ml-auto">
                  {(props.data.total.posts_reactions ?? 0) +
                    (props.data.total.posts_likes_ad ?? 0) ?? 0}
                  {variation_posts_reactions > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_reactions.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Commentaires :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_comments ?? 0}
                  {variation_posts_comments > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_comments.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Partages :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_shares ?? 0}
                  {variation_posts_shares > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_shares.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>

            {socialType == "facebook" ? (
              <div className="text-xs font-bold">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex capitalize">Total clics :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {props.data.total.posts_clicks ?? 0}
                    {variation_posts_clicks > 0 ? (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "green",
                        }}
                      >
                        <UpIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "red",
                        }}
                      >
                        <DownIcon />
                      </div>
                    )}
                    {variation_posts_clicks.toFixed(2) || 0}%
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
            ) : null}

            {socialType == "facebook" ? (
              <div className="text-xs">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex">
                    Autres types de clics sur le(s) post(s) :
                  </div>
                  <span className="title_1 flex items-center ml-auto">
                    {props.data.total.details_clicks.other_clicks || 0}
                    {variation_posts_other_clicks > 0 ? (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "green",
                        }}
                      >
                        <UpIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "red",
                        }}
                      >
                        <DownIcon />
                      </div>
                    )}
                    {variation_posts_other_clicks.toFixed(2) || 0}%
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
            ) : null}

            {socialType == "facebook" ? (
              <div className="text-xs">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex ">Clics sur les photos :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {props.data.total.details_clicks.photo_view ?? 0}
                    {variation_posts_photo_view > 0 ? (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "green",
                        }}
                      >
                        <UpIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "red",
                        }}
                      >
                        <DownIcon />
                      </div>
                    )}
                    {variation_posts_photo_view.toFixed(2) || 0}%
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
            ) : null}

            {socialType == "facebook" ? (
              <div className="text-xs">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex">Clics sur des liens :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {props.data.total.details_clicks.link_clicks ?? 0}
                    {variation_posts_link_clicks > 0 ? (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "green",
                        }}
                      >
                        <UpIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "red",
                        }}
                      >
                        <DownIcon />
                      </div>
                    )}
                    {variation_posts_link_clicks.toFixed(2) || 0}%
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  };

  //console.log("legneds", legends);
  return (
    <div>
      <div
        data-value={props.config.title}
        className="style_title_chart highchart_block1 mb-5"
      >
        <div className="flex items-center">
          <div className="content_style_title">
            <h1>{props.config.title}</h1>
            <p>{props.data?.metrics[props.config.metrics[0]]?.description}</p>
          </div>

          <button
            data-html2canvas-ignore
            onClick={printChart}
            className="ml-auto mr-1.5 w-5 h-5 print_capture"
          ></button>
        </div>
        <div className="highchart_block flex">
          <div className="highchart_block1 full_width">
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              callback={highchartsCallback}
            />
          </div>

          <div className="highchart_block1 full_width">{renderTotal()}</div>
        </div>
      </div>
    </div>
  );
};

export default PieCmp2;
