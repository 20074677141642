import React from "react";
import Header from "./Header";
import ReportingOverview from "./ReportingOverview";
import GoalsOverview from "./GoalsOverview";
import { Layout as DataLayout } from "../../DataCmp/Layout";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PerformanceReport = (props) => {
  const options = {
    credits: false,
    xAxis: {
      type: "datetime",
    },
    chart: {
      type: "line",
    },
    title: {
      text: "My chart",
    },
    series: [
      {
        data: [
          [60, 3],
          [50, 60],
        ],
      },
    ],
  };
   //fix  full screen resizing
   setTimeout(() => window.dispatchEvent(new Event("resize")), 500);

  //console.log("reportingdata", props.reportingData);
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="content_reporting">
        <ReportingOverview {...props} />
        <GoalsOverview {...props} />

        <div className="dataLayout my-6">
          <DataLayout
            layout={props.reportingData.layouts.performance}
            data={props.reportingData}
          />
        </div>
      </div>
    </div>
  );
};

export default PerformanceReport;
