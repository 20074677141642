import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";
import { ReactComponent as CalendarIcon } from "../../images/images/calendar.svg";
import { ReactComponent as CalendarArrow } from "../../images/images/calendarArrow.svg";
import { Link } from "react-router-dom";

import { AppContext, API } from "../../utils";
import moment from "moment";
import dayjs from "dayjs";

//import { ReactComponent as AvatarArrow } from "../../images/images/avatarArrow.svg";

//import objectifIcon from "../../../images/objectif.png";

const BenchmarkHeader = () => {
  const [state, setState] = React.useContext(AppContext);
  const [status, setStatus] = useState("");
  const { t, i18n } = useTranslation();
  const current_page = state?.currentPage?.page_id;
  const [dates2, setDates2] = useState(
    state.reportingPeriod || [
      dayjs().subtract(1, "year").toDate(),
      dayjs().toDate(),
    ]
  );

  const currentPage = state?.currentPage;

  // useEffect(() => {
  //   setState({
  //     ...state,
  //     reportingPeriod: [dayjs().subtract(1, "year").toDate(), dayjs().toDate()],
  //   });
  // }, []);

  return (
    <div className="justify-between flex mt-4 items-center text-sm 2xl:text-base">
      {/* calendar */}
      <div className="flex items-center gap-6 w-full">
        <div className="flex bg-white border-gray-300  border  items-center rounded font-CityOfEmber calendar-navbar w-72  ">
          <label
            htmlFor="date_range"
            className="cursor-pointer pl-6 dark:text-asap-white text-asap-blue"
          >
            <CalendarIcon />
          </label>
          <Calendar
            inputId="date_range"
            dateFormat="yy/mm/dd"
            panelClassName="mt-6 bg-asap-gray-900 p-2 font-CityOfEmber text-gray-900 font-bold  "
            className="font-CityOfEmber text-gray-900 font-bold "
            inputClassName=" ml-2 cursor-pointer outline-none text-asap-blue text-sm text-gray-900 font-semibold "
            id="range"
            value={dates2}
            onChange={(e) => {
              setDates2(e.value);
              if (e.value[0] != null && e.value[1] != null)
                setState({ ...state, reportingPeriod: e.value });
            }}
            selectionMode="range"
            readOnlyInput
            numberOfMonths={1}
          />
          <label
            htmlFor="date_range"
            className="cursor-pointer pl-2 cursor-pointer dark:text-asap-white text-asap-blue"
          >
            <CalendarArrow />
          </label>
        </div>
        <div className="flex gap-2 text-[14px] font-semibold">
          <span>{t("Performance_report.period")}</span>
          {dates2[0] && (
            <div className="flex gap-2">
              <span>{dates2[0]?.toDateString()}</span> -
              <span>{dates2[1]?.toDateString()}</span>
            </div>
          )}
        </div>

        <div className="ml-auto flex items-center">
          {((currentPage && currentPage.page_id == 323) ||
            (currentPage && currentPage.page_id == 187507)) && (
            <Link
              state={state}
              to={`/reporting/${currentPage.page_id}/benchmark/sector_posts`}
              className="text-white bg-[#7f56d9] text-[14px] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] rounded-[4px] px-2 py-1"
            >
              Sector Posts Library
            </Link>
          )}

          {currentPage && currentPage.social_type == "facebook" && (
            <Link
              state={state}
              to={`/reporting/${currentPage.page_id}/benchmark/sector_ads`}
              className="ml-5 text-white bg-[#7f56d9] text-[14px] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] rounded-[4px] px-2 py-1"
            >
              Sector ADS Library
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default BenchmarkHeader;
