import React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Highcharts from "highcharts";
import { Dropdown } from "primereact/dropdown";
import HighchartsReact from "highcharts-react-official";
import { AppContext, API } from "../../../../utils";
import html2canvas from "html2canvas";
import DropdownImg from "../../../../images/dropdown1.png";

const ComparisonSectorYoutube = (props) => {
  props = props.state;
  const socialType = props.data.socialType;
  const { t, i18n } = useTranslation();
  const [state, setState] = React.useContext(AppContext);
  function convert_format_date(date_to_convert) {
    let date = new Date(date_to_convert);

    // Get year, month, and day part from the date
    let year = date.toLocaleString("default", { year: "numeric" });
    let month = date.toLocaleString("default", { month: "2-digit" });
    let day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    let formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }

  function day_or_month(date_one, date_two) {
    let date1 = new Date(date_one);
    let date2 = new Date(date_two);

    let diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let type = " ";
    if (diffDays <= 31) {
      type = "day";
    } else {
      type = "month";
    }

    return type;
  }

  let date_form = convert_format_date(state.reportingPeriod[0].toDateString());
  let date_to = convert_format_date(state.reportingPeriod[1].toDateString());

  const [type_option, setType_option] = useState(
    day_or_month(date_form, date_to)
  );
  const [valueDropdownOption, setValueDropdownOption] = useState(
    day_or_month(date_form, date_to)
  );

  let options = {
    credits: false,

    chart: {
      type: "spline",
      zoomType: "xy",
      style: {
        fontFamily: "Inter",
      },
    },
    title: {
      text: "",
      align: "left",
      margin: 50,
    },
    subtitle: {
      text: "",
      align: "left",
      margin: 50,
    },
    xAxis: {
      categories: [],
      accessibility: {
        description: "Months of the year",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value + "";
        },
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
          enabled: false,
        },
      },
    },
    series: [{}],
  };

  let options1 = {
    credits: false,

    chart: {
      type: "spline",
      zoomType: "xy",
      style: {
        fontFamily: "Inter",
      },
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      accessibility: {
        description: "Months of the year",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value + "";
        },
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
          enabled: false,
        },
      },
    },
    series: [{}],
  };

  //-- day data
  const day_array = [];
  const day_array_posts_count = [];
  const day_array_sector_posts_count = [];
  const day_array_posts_engagement = [];
  const day_array_sector_posts_engagement = [];
  const propsDay = Object.entries(props.data.by_day);
  for (const [key, value] of Object.entries(propsDay)) {
    day_array.push(value[0]);
    /*if (value[1].posts_count)*/ day_array_posts_count.push(
      value[1].posts_count ?? 0
    );
    /*if (value[1].sector_posts_count)*/ day_array_sector_posts_count.push(
      value[1].sector_posts_count ?? 0
    );
    /*if (value[1].posts_engagement)*/ day_array_posts_engagement.push(
      value[1].posts_engagement ?? 0
    );
    /*if (value[1].sector_posts_engagement)*/ day_array_sector_posts_engagement.push(
      value[1].sector_posts_engagement ?? 0
    );
  }
  const day_posts_count = {
    name: t("Benchmark.yours_number_posts"),
    data: day_array_posts_count,
  };
  const day_sector_posts_count = {
    name: t("Benchmark.average_competitors"),
    data: day_array_sector_posts_count,
  };
  const day_posts_engagement = {
    name: t("Benchmark.yours_engagement"),
    data: day_array_posts_engagement,
  };
  const day_sector_posts_engagement = {
    name: t("Benchmark.average_competitors"),
    data: day_array_sector_posts_engagement,
  };

  function sort_array(dates) {
    dates.sort(function (a, b) {
      let dateA = new Date(a);
      let dateB = new Date(b);
      return dateA - dateB;
    });
    return dates;
  }

  function day_data_option() {
    options.series.push(day_posts_count);
    options.series.push(day_sector_posts_count);
    sort_array(day_array).map((entry, index) => {
      options.xAxis.categories.push(entry);
    });

    options1.series.push(day_posts_engagement);
    options1.series.push(day_sector_posts_engagement);
    sort_array(day_array).map((entry, index) => {
      options1.xAxis.categories.push(entry);
    });
  }

  //-- month data
  const myData = props.cmpData.data;

  const posts_count_array = myData.map((item) => item.posts_count);
  const posts_count = {
    name: t("Benchmark.yours_number_posts"),
    data: posts_count_array,
  };

  const sector_posts_count_array = myData.map((item) =>
    parseFloat(item.sector_posts_count.toFixed(2))
  );
  const sector_posts_count = {
    name: t("Benchmark.average_competitors"),
    data: sector_posts_count_array,
  };

  const posts_engagement_array = myData.map((item) => item.posts_engagement);
  const posts_engagement = {
    name: t("Benchmark.yours_engagement"),
    data: posts_engagement_array,
  };

  const sector_posts_engagement_array = myData.map(
    (item) => item.sector_posts_engagement
  );
  const sector_posts_engagement = {
    name: t("Benchmark.average_competitors"),
    data: sector_posts_engagement_array,
  };

  const status = [
    { name: "month", code: "month" },
    { name: "day", code: "day" },
  ];

  function month_data_option() {
    options.series.push(posts_count);
    options.series.push(sector_posts_count);
    props.cmpData.data.map((entry, index) => {
      options.xAxis.categories.push(entry.name);
    });

    options1.series.push(posts_engagement);
    options1.series.push(sector_posts_engagement);
    props.cmpData.data.map((entry, index) => {
      options1.xAxis.categories.push(entry.name);
    });
  }

  //-- option
  type_option === "month" ? month_data_option() : day_data_option();
  const onOptionChange = (e) => {
    setValueDropdownOption(e.value);
    if (e.value === "month") setType_option("month");
    if (e.value === "day") setType_option("day");
  };

  function remove_empty_value(arr) {
    var index;
    arr.some(function (obj, i) {
      return Object.keys(obj).length === 0 && obj.constructor === Object
        ? ((index = i), true)
        : false;
    });
    arr.shift(index);
    return index;
  }
  remove_empty_value(options.series);
  remove_empty_value(options1.series);

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");

    html2canvas(parent, {
      onclone: (document) => {
        // document.querySelector(".info_chart_fans ul li:nth-child(3)").setAttribute("style", "position: relative; top: 7px;");
        // document.querySelector(".info_chart_fans .circle_title").setAttribute("style", "position: relative; top: 5px;");
        // document.querySelector(".info_chart_tabs .box_canvas").setAttribute("style", "display: flex;align-items: center;");
        //document.querySelector(".info_chart_tabs span").setAttribute("style", "transform: translateY(-5px);");
      },
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  return (
    <div className="style_title_chart spline_sectorielle">
      <div className="highchart_block flex">
        <div data-value="Détails Posts" className="highchart_block1 full_width">
          <div className="flex items-center">
            <div className="content_style_title">
              <h1>{t("Benchmark.Sector_Comparison")}</h1>
              <p>{t("Benchmark.Sector_Comparison_desc")}</p>
            </div>

            <button
              data-html2canvas-ignore
              onClick={printChart}
              className="mx-5 w-5 h-5 print_capture"
            ></button>
          </div>

          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
        <div data-value="Détails Posts" className="highchart_block1 full_width">
          <div className="flex items-center mb-13">
            <div
              data-html2canvas-ignore
              className="flex items-center ml-auto border rounded-md capitalize"
            >
              <img src={DropdownImg} className="pl-2" alt="DropdownImg" />
              <Dropdown
                value={valueDropdownOption}
                options={status}
                onChange={onOptionChange}
                defaultValue={setType_option}
                optionLabel="name"
                optionValue="code"
                placeholder="Choisir"
                className="font-medium"
              />
            </div>
            <button
              data-html2canvas-ignore
              onClick={printChart}
              className="ml-5 mr-1.5 w-5 h-5 print_capture"
            ></button>
          </div>
          <HighchartsReact highcharts={Highcharts} options={options1} />
        </div>
      </div>
    </div>
  );
};
export default ComparisonSectorYoutube;
