import React, { useState, useEffect, useRef } from "react";

import 'primeicons/primeicons.css';
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

import SkeletonImage from "./SkeletonTiktok";

import dayjs from "dayjs";
  
import API from "../../utils/Api";
import { AppContext, Loader } from "../../utils";

import { useTranslation } from 'react-i18next';

import Pagination from "./Pagination";

import dropdownIcon from "../../images/dropdown1.png";
import { ReactComponent as CalendarIcon } from "../../images/images/calendar.svg";
import { ReactComponent as CalendarArrow } from "../../images/images/calendarArrow.svg";

const BenchmarkADS = () => {

  const { t, i18n } = useTranslation();
  const [status, setStatus] = useState("loading");
  const [state, setState] = React.useContext(AppContext);
  
  const [per_page, setPerPage] = useState();
  const [lastPage, setLastPage] = useState(1);
  const [paginatePage, setPaginatePage] = useState(1);

  const [allPosts, setPosts] = useState();
  const [filterStatus, setfilterStatus] = useState();
  const [filterStatusType, setfilterStatusType] = useState();
  
  const toast = useRef(null);

  const [dates2, setDates2] = useState(
    state.reportingPeriod || [
      dayjs().subtract(1, "year").toDate(),
      dayjs().toDate(),
    ]
  );
  
  const brandsADS = state.currentKpis.brands;
  const brandsSID = Object.values(brandsADS).map(item => item.social_id || '');

  const to = dayjs(state.reportingPeriod[1]).format("YYYY-MM-DD");
  const from = dayjs(state.reportingPeriod[0]).format("YYYY-MM-DD");

  const loadPosts = async () => {
    try {
      setStatus("loading");
      const res = await API.pages.getSectorADSApi(brandsADS, brandsSID, paginatePage, filterStatusType, filterStatus, from, to);
      setPosts(res.data.data);
      setLastPage(res.data.total);
      setPerPage(res.data.per_page);
      setStatus("");
    } catch (e) {
      console.log(e);
      toast.error("Somethong went wrong, please try again.");
    }
  };
  
  useEffect(() => {
    loadPosts();
  }, [state.reportingPeriod, paginatePage, filterStatusType, filterStatus]);
  
  let pageNames = Object.values(brandsADS).map((item) => item.name);

  // Utiliser map pour créer un tableau d'objets à partir des noms de page
  const filterTypeOptions = [{ name: t("Benchmark.marque"), code: "" }];
  const pageCodePairs = pageNames.map((pageName, index) => {
    return { name: pageName, code: brandsSID[index] || '' };
  });
  filterTypeOptions.push(...pageCodePairs);

  const onFilterTypeChange = (e) => {
    setfilterStatusType(e.value);
    setPaginatePage(1);
  };

  const filterStatusOptions = [
    { name: t("ads_search.Status"), code: "" },
    { name: t("ads_search.Active"), code: "Actif" },
    { name: t("ads_search.Inactive"), code: "Inactive" }
  ];

  const onFilterStatusChange = (e) => {
    setfilterStatus(e.value);
    setPaginatePage(1);
  };

  const handlePageChange = (page) => {
    setPaginatePage(page);
  };

  if (status == "loading") return <Loader />;

  const getPlatformIconClass = (platform) => {
    switch (platform.toLowerCase()) {
      case 'facebook':
        return 'pi-facebook';
      case 'instagram':
        return 'pi-instagram';
      case 'messenger':
        return 'pi-telegram';
      default:
        return 'pi-globe';
    }
  };
  
  return (
    <div>      
      <div className="justify-between flex mt-4 mx-6 items-center text-sm 2xl:text-base">
        {/* calendar */}
        <div className="flex items-center gap-6">
          <div className="flex bg-white border-gray-300  border  items-center rounded font-CityOfEmber calendar-navbar w-72">
            <label htmlFor="date_range" className="cursor-pointer pl-6 dark:text-asap-white text-asap-blue">
              <CalendarIcon />
            </label>
            <Calendar
              inputId="date_range"
              dateFormat="yy/mm/dd"
              panelClassName="mt-6 bg-asap-gray-900 p-2 font-CityOfEmber text-gray-900 font-bold  "
              className="font-CityOfEmber text-gray-900 font-bold "
              inputClassName=" ml-2 cursor-pointer   outline-none  text-asap-blue text-sm text-gray-900 font-semibold "
              id="range"
              value={dates2}
              onChange={(e) => {
                setDates2(e.value);
                if (e.value[0] != null && e.value[1] != null)
                  setState({ ...state, reportingPeriod: e.value });
              }}
              selectionMode="range"
              readOnlyInput
              numberOfMonths={1}
            />
            <label htmlFor="date_range" className="cursor-pointer pl-2 cursor-pointer dark:text-asap-white text-asap-blue">
              <CalendarArrow />
            </label>
          </div>
          <div className="flex gap-2">
            <span>Période définie pour le rapport :</span>
            {dates2[0] && (
              <div className="flex gap-2">
                <span>{dates2[0]?.toDateString()}</span> -
                <span>{dates2[1]?.toDateString()}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* selectbar */}
      <div className="flex items-center justify-between my-8">
        
        <div className="flex items-center">
          <h1 className="text-2xl float-left font-bold ml-4">Sector ADS Library</h1>
          <span className="ml-5 bg-[#EAECF0] text-[12px] text-[#344054] font-semibold p-[5px] px-2 py-1 rounded-[16px]">{lastPage}</span>
        </div>

        <div className=" float-right mr-8 flex gap-2  ">

          <div className="flex items-center border bg-white rounded border-gray-300 h-10">
            <img className="w-6 h-6 ml-4" src={dropdownIcon} alt="" />
            <div>
              <Dropdown
                className=" float-right text-gray-800 "
                value={filterStatusType}
                options={filterTypeOptions}
                onChange={onFilterTypeChange}
                optionLabel="name"
                placeholder={t("Benchmark.marque")}
              />
            </div>
          </div>

          <div className="flex items-center border bg-white rounded border-gray-300 h-10">
            <img className="w-6 h-6 ml-4" src={dropdownIcon} alt="" />
            <div>
              <Dropdown
                className=" float-right text-gray-800 "
                value={filterStatus}
                options={filterStatusOptions}
                onChange={onFilterStatusChange}
                optionLabel="name"
                placeholder={t("ads_search.Status")}
              />
            </div>
          </div>
        </div>

      </div>

      {allPosts.length > 0 && (
        <div className="grid grid-cols-4 gap-4">
            {allPosts.map((ad, key) => (
              <a href={ad.link} target="_blank" key={key} className="bg-white shadow rounded-[5px] p-4">

                <span className="text-[14px] text-semibold"><span className="text-[14px] text-black font-semibold">ID dans la bibliothèque :</span> {ad.library_id}</span>
                
                {/* Status */}
                <div>
                  {ad.status && (
                    <div className="text-[14px] text-semibold">
                      {ad.status == "Actif" ? (
                        <i className="text-[13px] mr-2 pi pi-check-circle text-[#008000]"></i> 
                      ) : (
                        <i className="text-[13px] mr-2 pi pi-times-circle text-[#ff0000]"></i>
                      )}
                      {ad.status}
                    </div>
                  )}
                </div>
                
                {/* Date */}
                <span className="block text-[14px] text-semibold"><span className="text-[14px] text-black font-semibold">Début de diffusion le</span> {ad.start_date}</span>
                {ad.end_date && 
                  <span className="block text-[14px] text-semibold"><span className="text-[14px] text-black font-semibold">Fin de diffusion le</span> {ad.end_date}</span>
                }

                {/* Plateformes */}
                <div className="flex items-center">
                  <span className="text-[14px] text-black font-semibold">Plateformes</span>
                  <ul>
                    {ad.platform.split(', ').map((platform, i) => (
                      <li key={i} className="ml-2 float-left">
                        <span className={`text-[14px] pi ${getPlatformIconClass(platform)}`}></span>{" "}
                      </li>
                    ))}
                  </ul>
                </div>

                {/* post */}
                <div className="flex items-center mt-5">
                  <img className="w-10 h-10 rounded-full" src={`https://graph.facebook.com/${ad.social_id}/picture`} alt={`Image de profil de ${ad.name}`} />
                  <div className="ml-3">
                    <span className="font-medium">{ad.name}</span>
                    <span className="block text-sm text-gray-500 dark:text-gray-400 font-light">{dayjs(ad.start_date).format("ddd MMM DD YYYY")}</span>
                  </div>
                </div>
                  
                {ad.description &&
                  <p className="my-5 text-asap-blue leading-snug md:leading-normal text-xs line-clamp-3">{ad.description}</p>
                }
                
                <SkeletonImage imageUrl={ad.media} />
                  
                {ad.button &&
                  <button className="ml-auto mt-5 table bg-[#EAECF0] text-[14px] text-[#344054] font-semibold p-[5px] px-2 py-1 rounded-[3px]">{ad.button}</button>
                }
                    
              </a>
            ))}
        </div>
      )}      

      {allPosts.length > 0 && (
        <div className="flex justify-center py-5 mt-5 border border-[#eaecf0] rounded-[12px] bg-white">
          <Pagination
            onPageChange={handlePageChange}
            totalCount={lastPage}
            currentPage={paginatePage}
            pageSize={per_page} 
            className="custom-pagination-class"
          />
        </div>
      )}    

    </div>
  );
};

export default BenchmarkADS;
