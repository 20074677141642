import React from "react";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import html2canvas from "html2canvas";
import millify from "millify";

//import exporting from "highcharts/modules/exporting";
//exporting(Highcharts);

const PRColumnChart = (props) => {
  const myData = props.cmpData.data;
  //console.log('PRColumnChart myData =>', myData);
  ///////////graph2////
  const { t, i18n } = useTranslation();
  let options2 = {
    chart: {
      type: "column",
    },
    title: {
      text: t("Benchmark.Average_Interactions_per_Post"),
      align: "left",
      margin: 50,
    },
    subtitle: {
      text: t("Benchmark.Average_Interactions_per_Post_desc"),
      align: "left",
      margin: 10,
    },
    subtitle: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [],
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          format: "{point.y:.1f}",
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [{}],
  };

  const totalBench = props.data?.dataBench?.total;

  const new_array_eng = [];
  for (const tt in totalBench) {
    let number_post_eng = totalBench[tt].total_posts
      ? parseFloat(
          totalBench[tt].interactions.sum / totalBench[tt].total_posts
        ).toFixed(2)
      : 0;
    new_array_eng.push(+number_post_eng);
  }
  const posts_engagement = {
    name: "Interaction par post",
    data: new_array_eng,
    color: "#22c4ac",
  };

  options2.series.push(posts_engagement);
  //console.log('posts_engagement', posts_engagement);

  props.cmpData.data.map((entry, index) => {
    options2.xAxis.categories.push(entry.name);
  });

  ////////graph31/////////
  let options31 = {
    chart: {
      type: "column",
    },
    title: {
      text: "Total interactions",
      align: "left",
      margin: 50,
    },
    subtitle: {
      text: t("Benchmark.interaction_sector_desc"),
      align: "left",
      margin: 10,
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
          textOutline: "none",
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      floating: false,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "white",
      shadow: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [{}],
  };

  //console.log('myData ==>', props.data.dataBench.total);
  const myDataInteractions = props.data.dataBench.total;
  const ids_sector = props.data?.ids_sector?.finalResult;
  //const posts_reactions_array = myData.map((item) => item.posts_reactions ?? 0);
  const posts_reactions_array = ids_sector.map(
    (item) => item.interactions.reactions ?? 0
  );

  const posts_reactions_stacked = {
    name: "Reaction",
    color: "#22c4ac",
    data: posts_reactions_array,
  };

  //const posts_shares_array = myData.map((item) => item.posts_shares ?? 0);
  const posts_shares_array = ids_sector.map(
    (item) => item.interactions.shares ?? 0
  );
  const posts_shares_stacked = {
    name: "Shares",
    color: "#6941c6",
    data: posts_shares_array,
  };

  //const posts_comments_array = myData.map((item) => item.posts_comments ?? 0);
  const posts_comments_array = ids_sector.map(
    (item) => item.interactions.comments ?? 0
  );
  const posts_comments_stacked = {
    name: "Comments",
    color: "Orange",
    data: posts_comments_array,
  };

  const posts_clicks_array = myData.map((item) => item.posts_clicks ?? 0);
  const posts_clicks_stacked = {
    name: "Clicks",
    data: posts_clicks_array,
  };

  options31.series.push(posts_reactions_stacked);
  options31.series.push(posts_shares_stacked);
  options31.series.push(posts_comments_stacked);
  //options31.series.push(posts_clicks_stacked);
  ids_sector.map((entry, index) => {
    //console.log('entry =>', entry.interactions);
    options31.xAxis.categories.push(entry.page_name);
  });

  ////////graph32/////////
  let options32 = {
    chart: {
      type: "column",
    },
    title: {
      text: t("Performance_report.post_type"),
      align: "left",
      margin: 50,
    },
    subtitle: {
      text: t("Benchmark.post_type_sector_desc"),
      align: "left",
      margin: 10,
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
          textOutline: "none",
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      floating: false,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "white",
      shadow: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [{}],
  };

  const dataBench = props.data?.dataBench?.total;
  const socialType = props?.data?.socialType;
  const data_bench = socialType == "facebook" ? ids_sector : dataBench;
  //const photoListSector = data_bench.map(item => item.post_type.photos ?? 0);
  const photoListSector =
    socialType == "facebook"
      ? data_bench.map((item) => item.post_type.photos ?? 0)
      : data_bench.map(
          (item) => item.post_type.photos + item.post_type.albums ?? 0
        );
  const videoListSector = data_bench.map((item) => item.post_type.videos ?? 0);
  //const otherListSector = data_bench.map(item => item.post_type.autres ?? 0);
  const otherListSector =
    socialType == "facebook"
      ? data_bench.map((item) => item.post_type.autres ?? 0)
      : data_bench.map(
          (item) => item.post_type.autres - item.post_type.albums ?? 0
        );

  const reelListSector = data_bench.map((item) => item.post_type.reels ?? 0);
  
  const photoArray = {
    name: "Photos",
    //color: "#22c4ac",
    data: photoListSector,
  };
  const videoArray = {
    name: "Videos",
    //color: "#6941c6",
    data: videoListSector,
  };

  const reelArray = {
    name: "Reels",
    //color: "#6941c6",
    data: reelListSector,
  };

  const otherArray = {
    name: "Textes",
    //color: "Orange",
    data: otherListSector,
  };

  options32.series.push(photoArray);
  options32.series.push(videoArray);
  options32.series.push(reelArray);
  options32.series.push(otherArray);
  data_bench.map((entry) => {
    options32.xAxis.categories.push(entry.page_name);
  });

  function remove_empty_value(arr) {
    var index;
    arr.some(function (obj, i) {
      return Object.keys(obj).length === 0 && obj.constructor === Object
        ? ((index = i), true)
        : false;
    });
    arr.shift(index);
    return index;
  }
  remove_empty_value(options2.series);
  remove_empty_value(options31.series);
  remove_empty_value(options32.series);

  //-- exist_data */
  function exist_data(option_data) {
    let exist = false;
    for (let i = 0; i < option_data.length; i++) {
      if (option_data[i].data && option_data[i].data.length > 0) {
        for (let j = 0; j < option_data[i].data.length; j++) {
          if (option_data[i].data[j] !== 0) {
            exist = true;
            break;
          }
        }
      }
    }
    // if(exist){
    //   console.log("Au moins une donnée n'est pas égale à zéro");
    // } else {
    //   console.log("Toutes les données sont égales à zéro");
    // }
    return exist;
  }

  //-- end exist_data */
  let exist_data_op2 = exist_data(options2.series);
  let exist_data_op31 = exist_data(options31.series);
  let exist_data_op32 = exist_data(options32.series);

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");
    parent.classList.add("capture_style");

    html2canvas(parent, {
      onclone: (document) => {},
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        parent.classList.remove("capture_style");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  // KPI 5 Best Engagement
  const sectorPagesEls = props.data?.dataBench?.best_posts_eng;

  function convert_date(date_to) {
    const date = new Date(date_to);
    const formattedDate = date
      .toLocaleString("en-us", {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
      })
      .replace(/,/g, "");
    return formattedDate;
  }

  const ProductTemplate = () => {
    return (
      <div className="carousel_eng">
        <div className="flex flex-row justify-center mb-4 justify-around flex-wrap">
          {sectorPagesEls.map((sectorPagesEl, index) => (
            <div
              key={index}
              className="bg-white shadow rounded-lg w-72 mt-8 mb-4"
            >
              <div className="">
                <div className="flex mb-2 px-4 pt-4">
                  <img
                    className="w-12 h-12 rounded-full"
                    src={sectorPagesEl?.logo}
                  />
                  <div className="ml-2 mt-0.5">
                    <a
                      href={`https://www.facebook.com/+${sectorPagesEl?.social_id}`}
                      target="_blank"
                    >
                      <span className="block font-medium text-base leading-snug text-black ">
                        {sectorPagesEl?.name_page}
                      </span>
                    </a>
                    <span className="block text-sm text-gray-500 dark:text-gray-400 font-light leading-snug">
                      {convert_date(sectorPagesEl?.creation_time)}
                    </span>
                  </div>
                </div>

                <p className="text-asap-blue  leading-snug md:leading-normal text-xs px-4 line-clamp-3 ">
                  {sectorPagesEl?.message}
                </p>

                <div className="img_post_eng my-4">
                  <img src={sectorPagesEl?.image_post} />
                </div>
              </div>

              <div className="grid grid-cols-3  gap-4 mx-auto mb-4 ml-4">
                <div className="total-fans">
                  <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                    {sectorPagesEl?.angry +
                      sectorPagesEl?.wow +
                      sectorPagesEl?.haha +
                      sectorPagesEl?.likes +
                      sectorPagesEl?.love +
                      sectorPagesEl?.none +
                      sectorPagesEl?.sad +
                      sectorPagesEl?.thankful}
                  </h1>
                  <p className="text-asap-text font-Montserrat text-xs">
                    Reactions
                  </p>
                  <hr className="mt-2" />
                </div>

                <div className="flex items-center gap-2">
                  <div className="total-fans">
                    <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                      {sectorPagesEl?.comments}
                    </h1>
                    <p className="text-asap-text font-Montserrat text-xs">
                      Comments
                    </p>
                    <hr className="mt-2" />
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <div className="total-fans">
                    <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                      {sectorPagesEl?.shares}
                    </h1>
                    <p className="text-asap-text font-Montserrat text-xs">
                      Shares
                    </p>
                    <hr className="mt-2" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // End KPI 5 Best Engagement

  return (
    <div>
      <div className="highchart_block">
        <div
          data-value="Nombre des posts I Commentaires"
          className="highchart_block1"
        >
          <div className="flex items-center">
            <button
              data-html2canvas-ignore
              onClick={printChart}
              className="ml-auto mr-1.5 w-5 h-5 print_capture"
            ></button>
          </div>
          <HighchartsReact highcharts={Highcharts} options={options32} />
        </div>
      </div>
      <div className="highchart_block">
        <div data-value="Total interactions" className="highchart_block1">
          <div className="flex items-center">
            <button
              data-html2canvas-ignore
              onClick={printChart}
              className="ml-auto mr-1.5 w-5 h-5 print_capture"
            ></button>
          </div>
          <HighchartsReact highcharts={Highcharts} options={options31} />
        </div>
      </div>
      {/* Interactions par post */}
      <div className="highchart_block">
        <div
          data-value="total moyenne des interactions par post"
          className="highchart_block1"
        >
          <div className="flex items-center">
            <button
              data-html2canvas-ignore
              onClick={printChart}
              className="ml-auto mr-1.5 w-5 h-5 print_capture"
            ></button>
          </div>
          <HighchartsReact highcharts={Highcharts} options={options2} />
        </div>
      </div>
      {/* 5 Best Engagement */}
      <ProductTemplate />
      {/* Enf 5 Best Engagement */}
    </div>
  );
};
export default PRColumnChart;
