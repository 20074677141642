import { ReactComponent as FansIcon } from "../../../../images/svg/icon_total_fans.svg";
import { ReactComponent as NewFansIcon } from "../../../../images/svg/icon_new_fans.svg";
import { ReactComponent as TotalEngIcon } from "../../../../images/svg/icon_total_eng.svg";
import { ReactComponent as TauxEngIcon } from "../../../../images/svg/icon_taux_eng.svg";
import { ReactComponent as ArrowUp } from "../../../../images/arrowUp.svg";
import { ReactComponent as MultipleVideoIcon } from "../../../../images/svg/icon_video.svg";
import { ReactComponent as PlusIcon } from "../../../../images/svg/icon_plus.svg";

import { useUtils } from "../../../../utils";
import { useTranslation } from "react-i18next";
import React from "react";

export const YoutubeOverview = (rest) => {
  const { t, i18n } = useTranslation();
  let utils = useUtils();

  const youtubeData = rest.props.youtube;
  const youtube_basicMetrics = youtubeData.basic_metrics;

  const total_fans = parseInt(youtube_basicMetrics.subs);
  const variation_page_fans = 0.0;
  const variation_taux_engagement_page = 10000;
  const variation_reachs = youtube_basicMetrics.previous.averageTime;
  const variation_engagement_posts_page =
    youtube_basicMetrics.previous.videosCount;
  const total_engagement = parseInt(youtube_basicMetrics.engagement);
  const variation_engagement_posts = youtube_basicMetrics.previous.engagement;
  const page_post_engagements = youtube_basicMetrics.videosCount;
  const taux_engagement = 12.14;
  const reach = parseFloat(youtube_basicMetrics.averageTime);
  // console.log("youtubeData : ",youtubeData);
  return (
    <div>
      <div className="flex font-bold text-2xl pt-20">
        {/* Récapitulatif des performances */}
        <h1>{t("Performance_report.performance_overview")}</h1>
        <div
          onClick={() => utils.goTo("/reporting/:pageId/stats")}
          className="flex items-center text-[#7f56d9] font-semibold text-[14px] ml-auto cursor-pointer bg-white px-3 rounded-[8px]"
        >
          <span className="mr-2">
            <PlusIcon />
          </span>
          {t("Performance_report.indicators")}{" "}
        </div>
      </div>
      <div className="flex justify-between mx-6"></div>

      <div
        id="performance_overview"
        className=" mt-8 flex gap-6 justify-center text-gray-700 text-sm 2xl:text-base"
      >
        {/* cards */}
        <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
          <div className="flex items-center">
            <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
              {/* <NewFansIcon /> */}
              <MultipleVideoIcon />
            </div>
            <h2 className="text-[#101828] text-[16px] font-medium ml-4">
              {t("Performance_report.total_videos")}
            </h2>
          </div>
          <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
            {page_post_engagements.toLocaleString()}
          </h3>
          <div className="flex mt-6 items-center">
            <div className="check_upgrade mr-2">
              <ArrowUp
                className={`${
                  variation_engagement_posts_page > 0 ? null : "arrowDown"
                } `}
              />
            </div>
            <span
              className={`${
                variation_engagement_posts_page > 0
                  ? "text-[#027a48]"
                  : "text-[#b42318]"
              } text-[14px] font-['inter'] font-bold`}
            >
              {variation_engagement_posts_page > 0 ? "+" : null}
              {variation_engagement_posts_page
                .toString()
                .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
              %
            </span>
          </div>
        </div>

        {/* cards */}
        <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
          <div className="flex items-center">
            <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
              <TotalEngIcon />
            </div>
            <h2 className="text-[#101828] text-[16px] font-medium ml-4">
              {t("Performance_report.Videos_Engagements")}
            </h2>
          </div>
          <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
            {total_engagement.toLocaleString() || 0}
          </h3>
          <div className="flex mt-6 items-center">
            <div className="check_upgrade mr-2">
              <ArrowUp
                className={`${
                  variation_engagement_posts > 0 ? null : "arrowDown"
                } `}
              />
            </div>
            <span
              className={`${
                variation_engagement_posts > 0
                  ? "text-[#027a48]"
                  : "text-[#b42318]"
              } text-[14px] font-['inter'] font-bold`}
            >
              {variation_engagement_posts > 0 ? "+" : null}
              {variation_engagement_posts
                .toString()
                .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
              %
            </span>
          </div>
        </div>

        {/* cards */}
        <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
          <div className="flex items-center">
            <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
              <TauxEngIcon />
            </div>

            <h2 className="text-[#101828] text-[16px] font-medium ml-4">
              {t("Performance_report.Average_view")}
            </h2>
          </div>
          <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
            {reach.toFixed(2)}
          </h3>
          <div className="flex mt-6 items-center">
            <div className="check_upgrade mr-2">
              <ArrowUp
                className={`${variation_reachs > 0 ? null : "arrowDown"} `}
              />
            </div>
            <span
              className={`${
                variation_reachs > 0 ? "text-[#027a48]" : "text-[#b42318]"
              } text-[14px] font-['inter'] font-bold`}
            >
              {variation_reachs > 0 ? "+" : null}
              {variation_reachs.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] ||
                0}
              %
            </span>
          </div>
        </div>

        {/* cards */}
        <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
          <div className="flex items-center">
            <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
              <FansIcon />
            </div>
            <h2 className="text-[#101828] text-[16px] font-medium ml-4">
              {t("Performance_report.total_Subscribers")}
            </h2>
          </div>
          <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
            {total_fans
              ? total_fans.toLocaleString()
              : t("Performance_report.not_available")}
          </h3>
          {/* <div className="flex mt-6 items-center">
            <div className="check_upgrade mr-2">
              <ArrowUp
                className={`${variation_page_fans > 0 ? null : "arrowDown"} `}
              />
            </div>
            <span
              className={`${
                variation_page_fans > 0 ? "text-[#027a48]" : "text-[#b42318]"
              } text-[14px] font-['inter'] font-bold`}
            >
              {variation_page_fans > 0 ? "+" : null}
              {variation_page_fans
                .toString()
                .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
              %
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};
