import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
// import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
// import ClusterPie from "./ClusterPie";
import { AppContext, SocialIcon, useUtils, getNextColor } from "../../utils";
// import { ReactComponent as I } from "../../images/i.svg";
// import { useMediaQuery } from "react-responsive";
// import { useTranslation } from 'react-i18next';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import html2canvas from "html2canvas";

import angryEmoji from "../../images/emojiAngry.png";
import emojiHaha from "../../images/emojiHaha.png";
import emojiLike from "../../images/emojiLike.png";
import emojiLove from "../../images/emojiLove.png";
import emojiSad from "../../images/emojiSad.png";
import emojiWow from "../../images/emojiWow.png";

import { ReactComponent as UpIcon } from "../../images/upIcon.svg";
import { ReactComponent as DownIcon } from "../../images/downIcon.svg";
import { ReactComponent as PlusIcon } from "../../images/svg/icon_plus.svg";
import millify from "millify";

const COLORS = ["#596ae9", "#6955e6", "#22c4ac", "orange"];
const PieComportement = (props) => {
  const socialType = props.data.socialType;
  const { t, i18n } = useTranslation();
  const [color] = useState(getNextColor());
  let options = {
    credits: false,
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 600,
      style: {
        fontFamily: "Inter",
      },
    },
    colors: COLORS,
    title: {
      text: "",
    },
    // subtitle: {
    //   text: props.config?.title || "",
    // },
    tooltip: {
      pointFormat: "<b>{point.percentage:.1f}%</b><br/>{point.y}",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        size: "70%",
      },
    },
    series: [
      {
        innerSize: "50%",
        pie: {},
        colorByPoint: true,
        data: [],
      },
    ],
  };
  options.series[0].data = [];
  props.cmpData.data.map((entry, index) => {
    options.series[0].data.push({
      name: entry.name,
      y: entry[props.cmpData.dataKey],
    });
  });

  const highchartsCallback = (chart) => {
    //console.log("chart", chart);
    //     chart.series[0].userOptions?.data?.forEach((item, index)=>{
    // console.log("itemname",item.name);
    //     })
    // chart.options.colors.forEach((color) => {
    //   props.cmpData.data.forEach((entry, index) => {
    //     console.log(color, entry);
    //     legends.push({
    //       color,
    //       name: entry.name,
    //       value: entry[props.cmpData.dataKey],
    //     });
    //   });
    // });
  };

  let emojis = {
    posts_love: emojiLove,
    posts_likes: emojiLike,
    posts_angry: angryEmoji,
    posts_haha: emojiHaha,
    posts_sad: emojiSad,
    posts_wow: emojiWow,
  };

  let reactions = [];
  if (
    props.cmpData.dataKey == "posts_type_interactions" ||
    props.cmpData.dataKey == "posts_types"
  ) {
    Object.keys(emojis).forEach((el) => {
      reactions.push({ icon: emojis[el], value: props.data.total[el] });
    });
  }

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");

    html2canvas(parent, {
      onclone: (document) => {
        // document.querySelector(".info_chart_fans ul li:nth-child(3)").setAttribute("style", "position: relative; top: 7px;");
        // document.querySelector(".info_chart_fans .circle_title").setAttribute("style", "position: relative; top: 5px;");
        // document.querySelector(".info_chart_tabs .box_canvas").setAttribute("style", "display: flex;align-items: center;");
        //document.querySelector(".info_chart_tabs span").setAttribute("style", "transform: translateY(-5px);");
      },
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");
      console.log("data", data);
      console.log("link", link);
      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  const variation_engagement_posts =
    props.data.total.previous_total.variation_engagement_posts ?? 0;
  const variation_count_posts =
    props.data.total.previous_total.variation_count_posts ?? 0;

  const previous_total = props.data.total.previous_total
    ? props.data.total.previous_total
    : 0;

  const previous_end_date = props.data.date_info.previous_end_day ?? 0;
  const previous_start_date = props.data.date_info.previous_start_day ?? 0;

  const variation_posts_photos = previous_total?.previous_posts_photos
    ? ((props.data.total?.posts_types?.photo -
        previous_total.previous_posts_photos) /
        previous_total.previous_posts_photos) *
        100 ?? 0
    : 0;
  const variation_posts_videos = previous_total.previous_posts_videos
    ? ((props.data?.videos?.total?.count_videos -
        previous_total.previous_posts_videos) /
        previous_total.previous_posts_videos) *
        100 ?? 0
    : 0;
  const variation_posts_reals = previous_total.previous_posts_reals
    ? ((props.data?.videos?.total?.count_reals -
        previous_total.previous_posts_reals) /
        previous_total.previous_posts_reals) *
        100 ?? 0
    : 0;
  const variation_posts_textes = previous_total.previous_posts_textes
    ? ((props.data.total.posts_types?.texte -
        previous_total.previous_posts_textes) /
        previous_total.previous_posts_textes) *
        100 ?? 0
    : 0;

  const variation_posts_clicks = previous_total.previous_posts_clicks
    ? ((props.data.total.posts_clicks - previous_total.previous_posts_clicks) /
        previous_total.previous_posts_clicks) *
        100 ?? 0
    : 0;
  const variation_posts_reactions = previous_total.previous_reactions
    ? ((props.data.total.posts_reactions - previous_total.previous_reactions) /
        previous_total.previous_reactions) *
        100 ?? 0
    : 0;
  const variation_posts_comments = previous_total.previous_posts_comments
    ? ((props.data.total.posts_comments -
        previous_total.previous_posts_comments) /
        previous_total.previous_posts_comments) *
        100 ?? 0
    : 0;
  const variation_posts_shares = previous_total.previous_posts_shares
    ? ((props.data.total.posts_shares - previous_total.previous_posts_shares) /
        previous_total.previous_posts_shares) *
        100 ?? 0
    : 0;
  const variation_posts_other_clicks = previous_total.previous_other_clicks
    ? ((props.data.total.details_clicks.other_clicks -
        previous_total.previous_other_clicks) /
        previous_total.previous_other_clicks) *
        100 ?? 0
    : 0;
  const variation_posts_photo_view = previous_total.previous_photo_view
    ? ((props.data.total.details_clicks.photo_view -
        previous_total.previous_photo_view) /
        previous_total.previous_photo_view) *
        100 ?? 0
    : 0;
  const variation_posts_link_clicks = previous_total.previous_link_clicks
    ? ((props.data.total.details_clicks.link_clicks -
        previous_total.previous_link_clicks) /
        previous_total.previous_link_clicks) *
        100 ?? 0
    : 0;

  /*-- Total Engagement */
  const totalInteractions = props.data?.total?.posts_interactions || 0;
  const clicks = props.data?.total?.posts_type_interactions?.Clicks || 0;
  const total = totalInteractions + clicks;
  const formattedTotal = isNaN(total) ? 0 : total.toLocaleString();

  const [isNuageVisible, setIsNuageVisible] = useState(false);

  const handleToggleNuage = () => {
    setIsNuageVisible(!isNuageVisible);
    console.log("isNuageVisible", isNuageVisible);

    if (isNuageVisible) {
      setTimeout(() => {
        const nuageWord = document.querySelector(".nuage_word");
        nuageWord.classList.add("hidden"); // Remplacez 'myClass' par le nom de la classe que vous souhaitez ajouter
      }, 500);
    }
  };

  const renderTotal = () => {
    if (props.cmpData.dataKey == "posts_types") {
      return (
        <div>
          <div className="audience_total bg-rectangle rounded-[8px] mb-10 relative">
            {/* <button 
              onClick={handleToggleNuage}
              className="btn_nuage flex items-center text-[#6941c6] text-[12px] font-['inter'] font-bold absolute top-[10px] right-[10px]"
            >Tendances des mots clés <span className="ml-1 text-[16px]">+</span></button> */}
            <div className="flex items-center">
              <div className="w-5/5">
                <span className="text-[#152935] text-[14px] font-['inter'] font-bold ">
                  {t("Performance_report.post_type_title")}
                </span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-2/5">
                <h2 className="text-[#344563] text-[14px] font-['inter']">
                  {t("Performance_report.total_posts")}
                </h2>
              </div>
              <div className="w-3/5">
                <span className="text-[#152935] text-[24px] font-['inter'] font-extrabold">
                  {props.data?.total?.posts_count +
                    (props.data?.videos?.total?.count_reals ?? 0) +
                    (props.data.total.posts_types?.stories || 0)}{" "}
                  Posts
                </span>
              </div>
            </div>

            <div className="flex items-center mt-9">
              <div className="w-1/5">
                <span className="text-[#344563] text-[14px] font-['inter']  font-bold">
                  {variation_count_posts
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                  %
                </span>
              </div>
              <div className="w-4/5">
                <span className="text-[#344563] text-[14px] font-['inter']">
                  {t("Options.compared")}
                  <span className="font-bold">
                    {" "}
                    {previous_end_date} - {previous_start_date}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Photos :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_types?.photo
                    ? props.data.total.posts_types.photo
                    : 0}
                  {variation_posts_photos > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_photos.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Vidéos :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data?.videos?.total?.count_videos ?? 0}
                  {variation_posts_videos > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_videos
                    ? variation_posts_videos.toFixed(2)
                    : 0}
                  %
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>

            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Reels :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data?.videos?.total?.count_reals ?? 0}
                  {variation_posts_reals > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_reals ? variation_posts_reals.toFixed(2) : 0}
                  %
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>

            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Textes :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_types?.texte ?? 0}
                  {variation_posts_textes > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_textes.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>

            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Stories :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_types?.stories
                    ? props.data.total.posts_types.stories
                    : 0}
                  {variation_posts_photos > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_photos.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
          </div>
        </div>
      );
    }
    if (props.cmpData.dataKey == "posts_type_interactions") {
      return (
        <div>
          <div className="audience_total bg-rectangle rounded-[8px] mb-10">
            <div className="flex items-center">
              <div className="w-5/5">
                <span className="text-[#152935] text-[14px] font-['inter'] font-bold ">
                  Mesures de l'engagement
                </span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-2/5">
                <h2 className="text-[#344563] text-[14px] font-['inter']">
                  Total des engagements
                </h2>
              </div>
              <div className="w-3/5">
                <span className="text-[#152935] text-[24px] font-['inter'] font-extrabold">
                  {formattedTotal}
                  Engagement
                </span>
              </div>
            </div>

            {socialType == "facebook" ? (
              <div className="flex items-center mt-9">
                <div className="w-1/5">
                  <span className="text-[#344563] text-[14px] font-['inter']  font-bold">
                    {variation_engagement_posts
                      .toString()
                      .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                    %
                  </span>
                </div>
                <div className="w-4/5">
                  <span className="text-[#344563] text-[14px] font-['inter']">
                    comparé à
                    <span className="font-bold">
                      {" "}
                      {previous_end_date} - {previous_start_date}
                    </span>
                  </span>
                </div>
              </div>
            ) : null}
          </div>

          <div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">
                  Réactions :
                  <div className="flex mx-8 gap-2 justify-center">
                    <div className="flex items-center">
                      <img src={emojiLike} alt="emojiLike" className="mr-1.5" />
                      <p>{props.data.total.posts_likes ?? 0}</p>
                    </div>
                    {socialType == "facebook" ? (
                      <div className="flex">
                        <div className="flex items-center">
                          <img
                            src={emojiLove}
                            alt="emojiLove"
                            className="mr-1.5"
                          />
                          <p>{props.data.total.posts_love ?? 0}</p>
                        </div>
                        <div className="flex items-center">
                          <img
                            src={emojiHaha}
                            alt="emojiHaha"
                            className="mr-1.5"
                          />
                          <p>{props.data.total.posts_haha ?? 0}</p>
                        </div>
                        <div className="flex items-center">
                          <img
                            src={angryEmoji}
                            alt="angryEmoji"
                            className="mr-1.5"
                          />
                          <p>{props.data.total.posts_angry ?? 0}</p>
                        </div>
                        <div className="flex items-center">
                          <img
                            src={emojiWow}
                            alt="emojiWow"
                            className="mr-1.5"
                          />
                          <p>{props.data.total.posts_wow ?? 0}</p>
                        </div>
                        <div className="flex items-center">
                          <img
                            src={emojiSad}
                            alt="emojiSad"
                            className="mr-1.5"
                          />
                          <p>{props.data.total.posts_sad ?? 0}</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_reactions ?? 0}
                  {variation_posts_reactions > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_reactions.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Commentaires :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_comments ?? 0}
                  {variation_posts_comments > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_comments.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Partages :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_shares ?? 0}
                  {variation_posts_shares > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_shares.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>

            {socialType == "facebook" ? (
              <div className="text-xs font-bold">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex capitalize">Total clics :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {props.data.total.posts_clicks ?? 0}
                    {variation_posts_clicks > 0 ? (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "green",
                        }}
                      >
                        <UpIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "red",
                        }}
                      >
                        <DownIcon />
                      </div>
                    )}
                    {variation_posts_clicks.toFixed(2) || 0}%
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
            ) : null}

            {socialType == "facebook" ? (
              <div className="text-xs">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex">
                    Autres types de clics sur le(s) post(s) :
                  </div>
                  <span className="title_1 flex items-center ml-auto">
                    {props.data.total.details_clicks.other_clicks || 0}
                    {variation_posts_other_clicks > 0 ? (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "green",
                        }}
                      >
                        <UpIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "red",
                        }}
                      >
                        <DownIcon />
                      </div>
                    )}
                    {variation_posts_other_clicks.toFixed(2) || 0}%
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
            ) : null}

            {socialType == "facebook" ? (
              <div className="text-xs">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex ">Clics sur les photos :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {props.data.total.details_clicks.photo_view ?? 0}
                    {variation_posts_photo_view > 0 ? (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "green",
                        }}
                      >
                        <UpIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "red",
                        }}
                      >
                        <DownIcon />
                      </div>
                    )}
                    {variation_posts_photo_view.toFixed(2) || 0}%
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
            ) : null}

            {socialType == "facebook" ? (
              <div className="text-xs">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex">Clics sur des liens :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {props.data.total.details_clicks.link_clicks ?? 0}
                    {variation_posts_link_clicks > 0 ? (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "green",
                        }}
                      >
                        <UpIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "red",
                        }}
                      >
                        <DownIcon />
                      </div>
                    )}
                    {variation_posts_link_clicks.toFixed(2) || 0}%
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  };

  const isArabic = (word) => {
    const arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(word);
  };

  const getGroupIndex = (index) => Math.floor(index / 5);

  //-- getWordCloud
  const getWordCloud = props.data.nauge_word;
  // Convert object to array of key-value pairs
  const wordArray = getWordCloud ? Object.entries(getWordCloud) : [];

  // Sort the array by values in descending order
  const sortedWordArray = wordArray.sort((a, b) => b[1] - a[1]);

  // Create an array of shuffled indices
  const shuffledIndices = Array.from(
    { length: sortedWordArray.length },
    (_, index) => index
  );
  for (let i = shuffledIndices.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledIndices[i], shuffledIndices[j]] = [
      shuffledIndices[j],
      shuffledIndices[i],
    ];
  }

  return (
    <div>
      <div
        data-value={props.config.title}
        className="style_title_chart highchart_block1 mb-5"
      >
        <div className="flex items-center">
          <div className="content_style_title">
            <h1>{t("Performance_report.post_type")}</h1>
            <p>{t("Performance_report.post_type_desc")}</p>
          </div>

          <button
            data-html2canvas-ignore
            onClick={printChart}
            className="ml-auto mr-1.5 w-5 h-5 print_capture"
          ></button>
        </div>

        <div className="highchart_block flex">
          <div className="highchart_block1 full_width">
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              callback={highchartsCallback}
            />
          </div>

          <div className="highchart_block1 full_width">{renderTotal()}</div>

          {/* <div className={`nuage_word ${isNuageVisible ? 'show_nauge' : 'hide_nauge'}`}>
            {shuffledIndices && shuffledIndices.length > 0 && (
              <div className="wordCloud flex flex-wrap justify-center box_post border border-[#eaecf0] rounded-[12px] py-5 bg-white mt-5 p-4 mr-8">
                {shuffledIndices.map((shuffledIndex) => {
                  const [word, count] = sortedWordArray[shuffledIndex];
                  const index = wordArray.findIndex(([originalWord]) => originalWord === word);
                  return (
                    <div
                      key={word}
                      className={`flex items-end mr-2 mb-2 ${isArabic(word) ? 'word_arabic flex' : ''} element_${getGroupIndex(index)}`}
                    >
                      <span>{word}</span>
                      <span>({count})</span>
                    </div>
                  );
                })}
              </div>
            )} 
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PieComportement;
