const Questions = {
    "title": "TYPE OF BRAND",
    "logoPosition": "right",
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "html",
                    "name": "paragraphe1",
                    "html": "<h2>We are Diggin' your brand!</h2>\n\n"
                },
                {
                    "type": "html",
                    "name": "paragraphe2",
                    "html": "<span class=\"parag paragTwo\">On y est presque! Afin de mieux connaître votre marque, nous aimerions que vous essayez notre questionnaire de bienvenue. Cela nous aidera à mieux comprendre comment vous faites les choses, afin que nous sachions comment nous devons faire NOS choses. Génial, n'est-ce pas ? </span>"
                }
            ]
        },
        {
            "name": "page2",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "question1",
                    "title": "La mission de notre marque est de rendre les gens plus heureux",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question2",
                    "title": "Notre produit/service peut être décrit comme simple, pur, ou traditionaliste",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question3",
                    "title": "Notre marque est optimiste et a une vision optimiste de la vie",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question4",
                    "title": "Notre entreprise a des croyances solides. (droits de la femme/ égalité/ écologie/ …)",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                }
            ]
        },
        {
            "name": "page3",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "question5",
                    "title": "L'objectif de notre entreprise est de fournir des informations et de diffuser des connaissances",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question6",
                    "title": "Notre marque aide les personnes qui recherchent la vérité et des informations fiables",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question7",
                    "title": "Notre marque valorise la transparence et l'objectivité",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question8",
                    "title": "Notre marque encourage la découverte de la vérité et la liberté de pensée",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                }
            ]
        },
        {
            "name": "page4",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "question9",
                    "title": "La mission de notre marque est d'amener les gens à garder leur authenticité et leur individualité",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question10",
                    "title": "Notre cible a soif d'aventure et recherche des occasions pour se sentir vivant et libre",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question11",
                    "title": "Notre marque aide les gens à découvrir de nouvelles perspectives et expériences",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question12",
                    "title": "Notre marque encourage les gens à être libres et à suivre leur propre chemin",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                }
            ]
        },
        {
            "name": "page5",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "question13",
                    "title": "Notre marque est contre le statu quo, les règles et les figures d'autorité",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question14",
                    "title": "Notre cible en a assez des législateurs et se sent sous la pression des normes de la société",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question15",
                    "title": "Notre marque encourage les idées radicales et la libre pensée pour apporter un changement",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question16",
                    "title": "Nous pensons que notre marque est libératrice, indépendante, et réformiste",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                }
            ]
        },
        {
            "name": "page6",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "question18",
                    "title": "Nous pensons que notre marque peut inspirer les gens",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question19",
                    "title": "La mission de notre marque est d'apporter un changement dans la vie de quelqu'un",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question20",
                    "title": "Notre marque fait naître le possible de situations impossibles",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question21",
                    "title": "Nous pouvons décrire notre marque comme étant perspicace et intuitive",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                }
            ]
        },
        {
            "name": "page7",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "question22",
                    "title": "La mission de notre marque est de lutter contre une idée, un comportement, un concept, ... et/ou de sauver quelqu'un/quelque chose",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question23",
                    "title": "Notre marque peut être décrite comme audacieuse et prête à relever des défis",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question24",
                    "title": "Notre produit/service inspire les gens à réaliser de grandes choses",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question25",
                    "title": "Notre marque s'adresse aux personnes qui aiment les défis",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                }
            ]
        },
        {
            "name": "page8",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "question26",
                    "title": "La mission de notre marque est d'attirer, de donner, de recevoir et d'entretenir l'amour",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question27",
                    "title": "Notre produit/service inspire les gens à vivre des relations (relations amoureuses, amicales et familiales)",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question28",
                    "title": "Notre cible est dans un besoin constant d'être aimée et de sentir la connexion",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question29",
                    "title": "Je peux décrire notre marque comme un amoureux romantique et passionné qui apprécie la beauté et les choses sensuelles",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                }
            ]
        },
        {
            "name": "page9",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "question30",
                    "title": "La mission de notre marque est de prendre les choses à la légère, de défier les conventions d'une manière rafraîchissante et de déformer les significations d'une manière ludique",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question31",
                    "title": "Notre marque s'adresse à un public jeune qui aime s'amuser",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question32",
                    "title": "Notre produit/service encourage les gens à se détendre, à se relaxer et à profiter pleinement de la vie",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question33",
                    "title": "Nous pouvons décrire notre marque comme joyeuse, vivant le moment présent, audacieusement originale et espiègle",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                }
            ]
        },
        {
            "name": "page10",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "question34",
                    "title": "La mission de notre marque est de permettre aux gens de se connecter les uns aux autres",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question35",
                    "title": "Nous pouvons décrire notre marque comme étant décontractée, facile à vivre et familière",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question36",
                    "title": "Notre marque accueille tout le monde, quelles que soient leurs différences",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question37",
                    "title": "Notre marque encourage les gens à s'intégrer et à être ordinaires",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                }
            ]
        },
        {
            "name": "page11",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "question38",
                    "title": "Notre marque se consacre à protéger et servir les gens",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question39",
                    "title": "Notre marque encourage les gens à prendre soin les uns des autres et à être altruistes",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question40",
                    "title": "En cas de crise, notre marque reste silencieuse, positive et diplomate",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question41",
                    "title": "Nous pouvons décrire notre marque comme altruiste, compatissante, empathique et optimiste",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                }
            ]
        },
        {
            "name": "page12",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "question42",
                    "title": "L'objectif de notre marque est de dominer le marché à travers des produits de haut niveau en instaurant la sûreté et la sécurité",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question43",
                    "title": "Notre marque soutient les structures stables qui respectent l’ordre et qui fonctionnent selon des processus bien déterminés et régularisés",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question44",
                    "title": "Notre marque est faite pour les personnes qui se soucient de leur image et de leur prestige qui sont attachés aux traditions",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question45",
                    "title": "Nous pouvons décrire notre marque comme un expert/leader qui est prestigieux et confiant",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                }
            ]
        },
        {
            "name": "page13",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "question46",
                    "title": "La mission de notre marque est de réinventer, réimaginer, et défier l’ordinaire.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question48",
                    "title": "Notre marque valorise l'expression de soi, la réflexion, et elle s'entoure de clients qui ont un sens de l'esthétique",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question47",
                    "title": "Notre cible s’exprime pleinement, elle innove, elle cogite, et elle crée",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "question49",
                    "title": "Nous pouvons décrire notre marque comme innovante, expressive, orientée vers la réussite et perspicace",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Non"
                        },
                        {
                            "value": "2",
                            "text": "Partiellement d’accord"
                        },
                        {
                            "value": "3",
                            "text": "D’accord"
                        },
                        {
                            "value": "4",
                            "text": "Tout à fait d'accord"
                        },
                        {
                            "value": "5",
                            "text": "Bingo"
                        }
                    ]
                }
            ]
        }
    ],
    "showTitle": false,
    "showQuestionNumbers": "off",
    "requiredText": ""
};

const Results = [
    {},
    {
        
        color:"#3F61AA",
        color2: "#ffffff",
        type: "L’innocent",
        description: "Vous êtes une marque optimiste, qui recherche des plaisirs simples et qui est digne de confiance. Vous vivez vos accomplissements lorsque les gens se sentent en sécurité en utilisant votre produit/service.",
        voice_tone: "Optimiste, honnête, sûr",
        message: "“La vie est simple”",
        examples_name: ["Dove soap","Nestle"],
        examples_logo: ["dove","nestle"],        
    },
    {
        
        color:"#45a3db",
        color2: "#ffffff",
        type: "Le sage",
        description: "Vous croyez au pouvoir de l'apprentissage pour atteindre l'épanouissement. Vous respectez les capacités intellectuelles des autres et vous êtes prêt à les éduquer autant que possible.",
        voice_tone: "sage, informatif, compréhensif",
        message: "La vérité vous affranchira",
        examples_name: ["BBC","National Geographic","Google"],
        examples_logo: ["bbc","national","google"],        
    },
    {
        
        color:"#4cbbb6",
        color2: "#ffffff",
        type: "L’explorateur",
        description: "Vous êtes doté d’un esprit libre qui est en quête constante d'épanouissement.Vous êtes une marque qui invente et crée de la nouveauté. Vous êtes connu pour repousser les limites et vous réjouir des découvertes inattendues",
        voice_tone: "Libre, intrépide, audacieux",
        message: "Ne cessez jamais d'explorer",
        examples_name: ["The North Face","Jeep","Red Bull"],
        examples_logo: ["northface","jeep","redbull"],        
    },
    {
       
        color:"#05405d",
        color2: "#ffffff",
        type: "Le Rebel",
        description: "Tu fais peur aux gens et tu aimes ça ! Vous défiez le statu quo et vous détruisez ce qui ne fonctionne pas. Vous visez la libération et la liberté.",
        voice_tone: "perturbateur, puissant, rebel",
        message: "Les règles ont été faites pour être brisées",
        examples_name: ["MTV","Diesel","Harley Davidson"],
        examples_logo: ["mtv","diesel","harley"],
    },
    {
        
        color:"#429fbd",
        color2: "#ffffff",
        type: "Le Magicien",
        description: "Vous croyez que vous pouvez changer le monde et vous travaillez pour cela ! Vous êtes intuitif, perspicace et inspirant. Votre pouvoir est de faire en sorte que les choses se produisent et que les rêves deviennent réalité.",
        voice_tone: "Rassurant, éloquent, enchanteur",
        message: "“Les rêves deviennent réalité”, « Je peux changer le monde. »",
        examples_name: ["Disney","Polaroid","Tesla"],
        examples_logo: ["disney","polaroid","tesla"],
    },
    {
        
        type: "L'Héro",
        color:"#f9a61b",
        color2: "#ffffff",
        description: "Vous êtes courageux et audacieux. Non seulement vous vous efforcez de résoudre des problèmes difficiles, mais vous incitez également les autres à faire de même. Votre mission est d'améliorer le monde et d'en faire un meilleur endroit.",
        voice_tone: "honnête, courageux, provocateur.",
        message: "Là où il y a une volonté, il y a un moyen.",
        examples_name: ["Nike","BMW","Duracell"],
        examples_logo: ["nike","bmw","duracell"],
    },
    {
        
        color:"#F7AD1B",
        type: "L’amoureux",
        description: "Vous aidez les gens à se sentir spéciaux, à devenir attractifs, et à établir des relations. Vous êtes passionné et sensuel et cela se voit à travers votre esthétique.",
        voice_tone: "apaisant, chaleureux, affectueux.",
        message: "Tu es le seul que je veux.",
        examples_name: ["Victoria’s Secret","Hermès","Chanel"],
        examples_logo: ["victoria","hermes","chanel"],
    },
    {
       
        color:"#05405d",
        type: "Le bouffon",
        color2: "#ffffff",
        description: "Vous êtes jeune de cœur et vous le serez toujours. Votre mission est de rappeler aux gens de garder leur enfant intérieur. Vous êtes le boute-en-train du groupe, vous apportez la joie et le rire à la foule.",
        voice_tone: "Enjoué, drôle, enthousiaste",
        message: "La vie est trop courte",
        examples_name: ["M&M’s","Geico","Skittles"],
        examples_logo: ["mms","geico","skittles"],
    },
    {
        
        color:"#429fbd",
        type: "Mr. Tout le monde",
        color:"#e26fab",
        color2: "#ffffff",
        description: "Vous avez les pieds sur terre et vous cherchez toujours un sentiment d'appartenance. Vous voulez vivre une vie simple, dans une communauté où vous vous intégrez.Vous êtes une personne ordinaire, et c'est là que réside votre point fort.",
        voice_tone: "Amical, encourageant, humble",
        message: "“Tu es comme moi, je suis comme toi.”",
        examples_name: ["Target","IKEA","eBay"],
        examples_logo: ["target","ikea","ebay"],
    },
    {
        type: "Le soignant",
        color:"#eb3d95",
        color2: "#ffffff",
        description: "Quand vous dites que vous vous souciez des autres, c'est que vous le faites vraiment. Vous êtes solidaire et altruiste. Les gens vous associent toujours à une figure parentale qui vous rappelle constamment de bien manger.",
        voice_tone: "chaud, doux, accueillant.",
        message: "“Pour tous les enfants.”",
        examples_name: ["UNICEF","WWF","Johnson&Johnson"],
        examples_logo: ["unicef","wwf","johnson"],
    },
    {
        type: "Le Chef",
        color:"#c429ff",
        color2: "#ffffff",
        description: "Vous êtes le parrain du groupe, un leader, un modèle qu'on suit. Vous attendez des autres qu'ils soient aussi responsables que vous et votre attitude autoritaire est parfois intimidante. Vous aspirez au succès et n'y renoncerez jamais.",
        voice_tone: "Décisif, fort, autoritaire",
        message: "”Le pouvoir n'est pas tout, c'est la seule chose. “",
        examples_name: ["Hugo Boss","Mercedes-Benz","Rolex"],
        examples_logo: ["hugo","benz","rolex"],
    },
    {
        type: "Le créateur",
        color:"#653796",
        color2: "#ffffff",
        description: "Vous avez un œil novateur qui voit le monde comme sa propre muse. Tout est une occasion d'innover et de créer pour vous. Vous avez une vision et un plan pour créer le contrôle et le maintenir",
        voice_tone: "Innovant, Inspirant, Provocateur",
        message: "“Penser différemment”",
        examples_name: ["Apple","LEGO","Youtube"],
        examples_logo: ["apple","lego","youtube"],
    },    
];

const Egality = {
    'more_than_1':"Il semble que vous ayez eu une petite confusion en répondant à la questionnaire. Deux archétypes ont obtenu le même score. Vous pouvez choisir ce qui convient le mieux à votre vision comme core et comme edge. Cependant, nous vous conseillons de refaire le test en vous concentrant davantage sur la personnalité de base de votre marque ou de nous contacter pour vous aider dans ce processus.",
    'more_than_2':"Il semble que vous ayez eu une petite confusion en répondant à la questionnaire. Trois ou plusieurs archétypes ont obtenu le même score. Vous pouvez choisir ce qui convient le mieux à votre vision comme core et comme edge. Cependant, nous vous conseillons de refaire le test en vous concentrant davantage sur la personnalité de base de votre marque ou de nous contacter pour vous aider dans ce processus.",
    'more_than_3':"Il semble que vous ayez eu une petite confusion en répondant à la questionnaire. Quatre archétypes ou plus ont obtenu le même score. Nous vous conseillons de refaire le test en vous concentrant davantage sur la personnalité fondamentale de votre marque ou de nous contacter pour vous aider dans cette démarche.",
}

export {Questions, Results, Egality};