import React, { useState, useEffect, useContext } from "react";
import SideBar from "../../../front_v2/src/cmp/SideBar";
import { AppContext, API, useUtils } from "../utils";
import FixedBar from "./FixedBar";
import Header from "./Header";
import { AppRoutes, Routes } from "../routes";
import { BrowserRouter } from "react-router-dom";

export default (props) => {
  const utils = useUtils();

  const [state, setState] = useContext(AppContext);
  const loaded = state && state.userData && state.token;
  
  useEffect(() => {
    if (!loaded) API.init(state, setState, utils);
  }, []);

  return (
    <>
      <div className=" flex min-h-screen" style={{ background: "#f7f8fe" }}>
        <div className="bars-container flex flex-row gap-20 bg-white">
          <div className="">
            <SideBar />
          </div>
        </div>

        <div className="right_block full_width">
          <Header />

          <div className="style_block">
            <AppRoutes />
          </div>
        </div>
      </div>
    </>
  );
};
