import React, { useState } from "react";

import { AppContext, SocialIcon, useUtils, getNextColor } from "../../utils";
// import { ReactComponent as I } from "../../images/i.svg";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const LineCmp = (props) => {
  console.log("props.line", props);

  let options = {
    credits: false,

    chart: {
      type: "line",
    },

    title: {
      align: "left",
      text: "",
    },
    xAxis: {
        gridLineWidth: 1,
        allowDecimals: false,
        tickInterval: 1,
        categories: [],
        title: {
          text: null,
        },
    },
    // subtitle: {
    //   align: "left",
    //   text: props.config?.description || "",
    //   style: { "font-weight": "50px", color: "#a", "margin-bottom": "20px" },
    // },
    series: [
        {
        data: [],
      },
    ],
  };

  options.series[0].data = [];
  props.cmpData.data.map((entry, index) => {
    options.xAxis.categories.push(entry.name);
    options.series[0].data.push({
      name: entry.name,
      y: entry[props.cmpData.dataKey],
      
    });
  });

//   if (props.config.var == "sync") {
//     return null;
//   }

  return (
    <div className="p-4">
    <h1 className="text-xl font-bold mb-4 ">
        {props.data.metrics[props.config.metrics[0]].name}
      </h1>
      <p className="text-xs mb-8">
        {props.data.metrics[props.config.metrics[0]].description}
      </p>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default LineCmp;
 