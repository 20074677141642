import React from "react";
import { useTranslation } from "react-i18next";
import millify from "millify";
import { useState } from "react";
import Highcharts from "highcharts";
import { Dropdown } from "primereact/dropdown";
import HighchartsReact from "highcharts-react-official";
import { AppContext, API } from "../../utils";
import html2canvas from "html2canvas";
import DropdownImg from "../../images/dropdown1.png";

import { ReactComponent as UpIcon } from "../../images/upIcon.svg";
import { ReactComponent as DownIcon } from "../../images/downIcon.svg";

const SpLineStories = (props) => {
  const [state, setState] = React.useContext(AppContext);
  const { t, i18n } = useTranslation();
  function convert_format_date(date_to_convert) {
    let date = new Date(date_to_convert);

    // Get year, month, and day part from the date
    let year = date.toLocaleString("default", { year: "numeric" });
    let month = date.toLocaleString("default", { month: "2-digit" });
    let day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    let formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }

  function day_or_month(date_one, date_two) {
    let date1 = new Date(date_one);
    let date2 = new Date(date_two);

    let diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let type = " ";
    if (diffDays <= 31) {
      type = "day";
    } else {
      type = "month";
    }

    return type;
  }

  let date_form = convert_format_date(state.reportingPeriod[0].toDateString());
  let date_to = convert_format_date(state.reportingPeriod[1].toDateString());

  const [type_option, setType_option] = useState(
    day_or_month(date_form, date_to)
  );
  const [valueDropdownOption, setValueDropdownOption] = useState(
    day_or_month(date_form, date_to)
  );

  let options = {
    credits: false,

    chart: {
      type: "spline",
      zoomType: "xy",
      style: {
        fontFamily: "Inter",
      },
    },
    title: {
      text: t("Performance_report.stories_performance"),
      align: "left",
      margin: 50,
    },
    subtitle: {
      text: t("Performance_report.stories_performance_desc"),
      align: "left",
      margin: 50,
    },
    xAxis: {
      categories: [],
      accessibility: {
        description: "Months of the year",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value + "";
        },
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
          enabled: false,
        },
      },
    },
    series: [{}],
  };

  //-- day data
  const day_array = [];
  const day_videos_count = [];
  const propsDay = props.data.instagram.story.by_day
    ? Object.entries(props.data.instagram.story.by_day)
    : [];
  for (const [key, value] of Object.entries(propsDay)) {
    day_array.push(value[0]);
    day_videos_count.push(value[1].count_stories);
  }

  const day_count = {
    name: "Stories count",
    data: day_videos_count,
  };

  function day_data_option() {
    options.series.push(day_count);
    sort_array(day_array).map((entry, index) => {
      options.xAxis.categories.push(entry);
    });
  }

  //-- month data
  const month_array = [];
  const month_videos_count = [];
  const propsMonth = props.data.instagram.story.by_month
    ? Object.entries(props.data.instagram.story.by_month)
    : [];
  for (const [key, value] of Object.entries(propsMonth)) {
    console.log("value :", value);
    var dateObj = new Date(value[0] + "-01");
    var monthName = dateObj.toLocaleString("default", { month: "short" });
    month_array.push(monthName ?? "");
    month_videos_count.push(value[1].count_stories ?? 0);
  }

  const month_count = {
    name: "Videos count",
    data: month_videos_count,
  };

  function month_data_option() {
    options.series.push(month_count);
    month_array.map((entry, index) => {
      options.xAxis.categories.push(entry);
    });
  }

  //-- option
  type_option === "month" ? month_data_option() : day_data_option();
  const onOptionChange = (e) => {
    setValueDropdownOption(e.value);
    if (e.value === "month") setType_option("month");
    if (e.value === "day") setType_option("day");
  };

  function remove_empty_value(arr) {
    var index;
    arr.some(function (obj, i) {
      return Object.keys(obj).length === 0 && obj.constructor === Object
        ? ((index = i), true)
        : false;
    });
    arr.shift(index);
    return index;
  }
  remove_empty_value(options.series);

  function sort_array(dates) {
    dates.sort(function (a, b) {
      let dateA = new Date(a);
      let dateB = new Date(b);
      return dateA - dateB;
    });
    return dates;
  }

  const previous_total = props.data.total.previous_total;

  const previous_end_date = props.data.date_info.previous_end_day ?? 0;
  const previous_start_date = props.data.date_info.previous_start_day ?? 0;

  //const count_video = props.data.videos.total.count_videos ?? 0;
  //const total_video_views = millify(props.data.videos.total.total_video_views) ?? 0;
  //const total_video_views_paid = millify(props.data.videos.total.total_video_views_paid) ?? 0;
  //const total_video_views_organic = millify(props.data.videos.total.total_video_views_organic) ?? 0;
  //const total_video_views_autoplayed = millify(props.data.videos.total.total_video_views_autoplayed) ?? 0;
  //const total_video_views_clicked_to_play = millify(props.data.videos.total.total_video_views_clicked_to_play) ?? 0;

  //const variation_total_video_views = (previous_total.previous_total_video_views) ? ( ( (props.data.videos.total.total_video_views - previous_total.previous_total_video_views) / previous_total.previous_total_video_views ) * 100 ?? 0) : 0;
  //const variation_total_video_views_paid = (previous_total.previous_total_video_views_paid) ? ( ( (props.data.videos.total.total_video_views_paid - previous_total.previous_total_video_views_paid) / previous_total.previous_total_video_views_paid ) * 100 ?? 0) : 0;
  //const variation_total_video_views_organic = (previous_total.previous_total_video_views_organic) ? ( ( (props.data.videos.total.total_video_views_organic - previous_total.previous_total_video_views_organic) / previous_total.previous_total_video_views_organic ) * 100 ?? 0) : 0;
  //const variation_total_video_views_autoplayed = (previous_total.previous_total_video_views_autoplayed) ? ( ( (props.data.videos.total.total_video_views_autoplayed - previous_total.previous_total_video_views_autoplayed) / previous_total.previous_total_video_views_autoplayed ) * 100 ?? 0) : 0;
  //const variation_total_video_views_clicked_to_play = (previous_total.previous_total_video_views_clicked_to_play) ? ( ( (props.data.videos.total.total_video_views_clicked_to_play - previous_total.previous_total_video_views_clicked_to_play) / previous_total.previous_total_video_views_clicked_to_play ) * 100 ?? 0) : 0;

  const my_data = props.data?.instagram?.total?.story;
  const count_stories = my_data.count ?? 0;
  const views_stories = my_data.views ?? 0;
  const exits_stories = my_data.exits ?? 0;
  const reach_stories = my_data.reach ?? 0;
  const replies_stories = my_data.replies ?? 0;
  const taps_back_stories = my_data.taps_back ?? 0;
  const taps_forward_stories = my_data.taps_forward ?? 0;
  const shares_stories = my_data.shares ?? 0;

  const status = [
    { name: "month", code: "month" },
    { name: "day", code: "day" },
  ];

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");
    parent.classList.add("capture_style");

    html2canvas(parent, {
      onclone: (document) => {},
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        parent.classList.remove("capture_style");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  return (
    <div>
      <div className="highchart_block">
        <div data-value="Stories de la page" className="highchart_block1">
          <div data-html2canvas-ignore className="flex items-center">
            <div className="flex items-center ml-auto border rounded-md capitalize">
              <img src={DropdownImg} className="pl-2" alt="DropdownImg" />
              <Dropdown
                value={valueDropdownOption}
                options={status}
                onChange={onOptionChange}
                defaultValue={setType_option}
                optionLabel="name"
                optionValue="code"
                placeholder="Choisir"
                className="font-medium"
              />
            </div>

            <button
              onClick={printChart}
              className="mx-5 w-5 h-5 print_capture"
            ></button>
          </div>

          <HighchartsReact highcharts={Highcharts} options={options} />

          <div className="info_chart_fans flex mt-15">
            <div className="w-1/2">
              <ul className="flex pb-0.8 border-[#dee0e1] border-b font-bold">
                <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                  <div className="w-3 h-3 rounded-full bg-[#5596e6] mr-1.5"></div>
                  {t("Performance_report.total_views")}
                </li>
                <li className="ml-auto flex items-center">{views_stories}</li>
                {/* <li className="ml-auto flex items-center">
                    {variation_total_video_views > 0
                      ?
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'green'}}><UpIcon /></div>
                      : 
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'red'}}><DownIcon /></div>
                    }
                    </li>
                    <li className="ml-auto flex items-center">
                    {variation_total_video_views.toFixed(2) || 0}%  
                  </li> */}
              </ul>
              <ul className="flex pb-0.8 border-[#dee0e1] border-b">
                <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                  <div className="w-3 h-3 rounded-full bg-[#22c4ac] mr-1.5"></div>
                  {t("Performance_report.reached_accounts")}
                </li>
                <li className="ml-auto flex items-center">{reach_stories}</li>
                {/* <li className="ml-auto flex items-center">
                    {variation_total_video_views_paid > 0
                      ?
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'green'}}><UpIcon /></div>
                      : 
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'red'}}><DownIcon /></div>
                    }
                    </li>
                    <li className="ml-auto flex items-center">
                    {variation_total_video_views_paid.toFixed(2) || 0}%  
                  </li> */}
              </ul>
              <ul className="flex pb-0.8 border-[#dee0e1] border-b">
                <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                  <div className="w-3 h-3 rounded-full bg-[#6955e6] mr-1.5"></div>
                  {t("Performance_report.exit")}
                </li>
                <li className="ml-auto flex items-center">{exits_stories}</li>
                {/* <li className="ml-auto flex items-center">
                    {variation_total_video_views_organic > 0
                      ?
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'green'}}><UpIcon /></div>
                      : 
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'red'}}><DownIcon /></div>
                    }
                    </li>
                    <li className="ml-auto flex items-center">
                    {variation_total_video_views_organic.toFixed(2) || 0}%  
                  </li> */}
              </ul>
              <ul className="flex pb-0.8 border-[#dee0e1] border-b">
                <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                  <div className="w-3 h-3 rounded-full bg-[#f6d991] mr-1.5"></div>
                  {t("Performance_report.response")}
                </li>
                <li className="ml-auto flex items-center">{replies_stories}</li>
                {/* <li className="ml-auto flex items-center">
                    {variation_total_video_views_autoplayed > 0
                      ?
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'green'}}><UpIcon /></div>
                      : 
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'red'}}><DownIcon /></div>
                    }
                    </li>
                    <li className="ml-auto flex items-center">
                    {variation_total_video_views_autoplayed.toFixed(2) || 0}%  
                  </li> */}
              </ul>
              <ul className="flex pb-0.8 border-[#dee0e1] border-b">
                <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                  <div className="w-3 h-3 rounded-full bg-[#f6d991] mr-1.5"></div>
                  {t("Performance_report.shares")}
                </li>
                <li className="ml-auto flex items-center">{shares_stories}</li>
                {/* <li className="ml-auto flex items-center">
                    {variation_total_video_views_autoplayed > 0
                      ?
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'green'}}><UpIcon /></div>
                      : 
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'red'}}><DownIcon /></div>
                    }
                    </li>
                    <li className="ml-auto flex items-center">
                    {variation_total_video_views_autoplayed.toFixed(2) || 0}%  
                  </li> */}
              </ul>
              <ul className="flex pb-0.8 border-[#dee0e1] border-b">
                <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                  <div className="w-3 h-3 rounded-full bg-[#f1c658] mr-1.5"></div>
                  {t("Performance_report.previous")}
                </li>
                <li className="ml-auto flex items-center">
                  {taps_back_stories}
                </li>
                {/* <li className="ml-auto flex items-center">
                    {variation_total_video_views_clicked_to_play > 0
                      ?
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'green'}}><UpIcon /></div>
                      : 
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'red'}}><DownIcon /></div>
                    }
                    </li>
                    <li className="ml-auto flex items-center">
                    {variation_total_video_views_clicked_to_play.toFixed(2) || 0}%  
                  </li> */}
              </ul>
              <ul className="flex pb-0.8 border-[#dee0e1] border-b">
                <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                  <div className="w-3 h-3 rounded-full bg-[#f1c658] mr-1.5"></div>
                  {t("Performance_report.next")}
                </li>
                <li className="ml-auto flex items-center">
                  {taps_forward_stories}
                </li>
                {/* <li className="ml-auto flex items-center">
                    {variation_total_video_views_clicked_to_play > 0
                      ?
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'green'}}><UpIcon /></div>
                      : 
                      <div style={{width:15, marginLeft:15, marginRight:5, fill:'red'}}><DownIcon /></div>
                    }
                    </li>
                    <li className="ml-auto flex items-center">
                    {variation_total_video_views_clicked_to_play.toFixed(2) || 0}%  
                  </li> */}
              </ul>
            </div>

            <div className="w-1/2">
              <div className="audience_total bg-rectangle rounded-[8px] ml-10 h-full">
                <div className="flex items-center">
                  <div className="w-1/5">
                    <h2 className="text-[#344563] text-[16px] font-['inter']">
                      {t("Performance_report.total_stories")}
                    </h2>
                  </div>
                  <div className="w-4/5">
                    <span className="text-[#152935] text-[24px] font-['inter'] font-extrabold">
                      {count_stories} Stories
                    </span>
                  </div>
                </div>

                {/* <div className="flex items-center mt-9">
                      <div className="w-1/5">
                        <span className="text-[#344563] text-[14px] font-['inter']  font-bold">{variation_total_video_views.toFixed(2) || 0}%</span>
                      </div>
                      <div className="w-4/5">
                      < span className="text-[#344563] text-[14px] font-['inter']">comparé à  
                        <span className="font-bold"> {previous_end_date} - {previous_start_date}</span>
                      </span>
                      </div>
                    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SpLineStories;
