import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext, API } from "../../utils";
import { Dialog } from "primereact/dialog";
import reverseLeft from "../../images/reverse-left.png";
import iconBack from "../../images/arrow-narrow-left.png";
import reverseLeftActive from "../../images/reverseLeftActive.png";
import { ReactComponent as CronIcon } from "../../images/svg/refresh-ccw-01.svg";

import Pagination from "./Pagination";

import star from "../../images/star-06.png";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import * as XLSX from "xlsx";

const PostDetail = () => {
  const location = useLocation();
  const [state, setState] = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  let dataPage = state.currentPage;
  const post = location.state.post;
  const getKeys = location.state.getKeys;

  const [inputTexte, setInputText] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [responseData, setResponseData] = useState("");
  const [sentimentFilter, setSentimentFilter] = useState("All");
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [selectedCommentId, setSelectedCommentId] = useState(null);

  const [comment_list, getCommentList] = useState([]);
  const [listSentiments, setListSentiments] = useState([]);
  const [paginatePage, setPaginatePage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [getWordCloud, setWordCloud] = useState(1);

  const [visibleInputId, setVisibleInputId] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [loading, setLoading] = useState(false);

  const [cronActivationSuccess, setCronActivationSuccess] = useState("");
  const [visible, setVisible] = useState(false);

  //-- send reponse to message
  const toast = useRef(null);

  //-- Get comments
  const current_post = post.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.pages.getCommentsApi(
          current_post,
          paginatePage,
          searchValue,
          sentimentFilter
        );
        getCommentList(response.data.data.data);
        setLastPage(response.data.data.total);
        setWordCloud(response.data.wordCloud[current_post]);
        setListSentiments(response.data.sentiments);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [paginatePage, searchValue, sentimentFilter]);

  const handlePageChange = (page) => {
    setPaginatePage(page);
  };

  const show = () => {
    formik.setErrors({}); // Réinitialisation des erreurs du formulaire

    const submittedDescription = formik.values.description || responseData;
    console.log("submittedDescription", submittedDescription);
    saveRepMessage(submittedDescription);
    toast.current.show({
      severity: "success",
      summary: "Form Submitted",
      detail: submittedDescription,
    });

    // Définir activeCommentId à null après avoir affiché le toast avec succès
    setActiveCommentId(null);
  };

  const formik = useFormik({
    initialValues: {
      description: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.description && !responseData) {
        errors.description = "Description is required.";
      }

      return errors;
    },
    onSubmit: (data) => {
      // Vous n'avez pas besoin de cette vérification car la validation gère les erreurs.
      // data && show();
      if (Object.keys(formik.errors).length === 0) {
        // Le formulaire est valide, vous pouvez maintenant envoyer les données.
        show();
        formik.resetForm();
      }
    },
  });

  const isFormFieldInvalid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  // Filtrer les commentaires

  //const listComments = post?.list_comments;

  let totalVideoViews = 0;
  if (
    dataPage.social_type === "instagram" ||
    dataPage.social_type === "youtube"
  ) {
    totalVideoViews = post?.views ?? 0;
  } else {
    const parsedData = post && post.data ? post.data : null;
    totalVideoViews = parsedData?.data?.total_video_views ?? 0;
  }

  const navigate = useNavigate();
  let url_post = "";
  if (dataPage.social_type == "facebook") {
    url_post = "https://www.facebook.com/" + post.social_id;
  } else if (dataPage.social_type == "instagram") {
    url_post = post.url;
  } else if (dataPage.social_type == "youtube") {
    url_post = "https://www.youtube.com/watch?v=" + post.video_id;
  }

  if (!post) {
    return <div>{t("Post_Library.data_post_not_available")}</div>;
  }

  const redirectToPostLibrary = () => {
    navigate(`/reporting/${dataPage.page_id}/posts`);
  };

  const fetchComments = async (message) => {
    try {
      console.log("message", message);
      const response = await API.pages.getMsgComment(message);
      setResponseData(response.data.trim());
      console.log(response.data); // Vous pouvez ajuster cette partie pour gérer la réponse comme vous le souhaitez.
    } catch (error) {
      console.error(error);
    }
  };

  const saveRepMessage = async (repMsg) => {
    try {
      const response = await API.pages.saveRepMsg(
        post?.social_id,
        dataPage?.page_id,
        selectedCommentId,
        repMsg,
        dataPage.token
      );

      const updatedListComments = comment_list.concat(response.data);

      // Mettez à jour l'état listComments avec les nouvelles données
      getCommentList(updatedListComments);

      // Réinitialisez responseData à une chaîne vide
      setResponseData("");

      // Réinitialisez le champ description dans le formulaire Formik (InputTextarea)
      formik.setFieldValue("description", "");

      // Réinitialisez les variables et champs du formulaire
      setInputText("");
      setActiveCommentId(null);

      toast.current.show({
        severity: "success",
        summary: "Response Saved",
        detail: "Your response has been saved successfully.",
      });
    } catch (error) {
      console.error(error);
    }
  };
  let countWithEnfants = 0;

  // Iterate through comments array
  comment_list.forEach((comment) => {
    // Check if comment has enfants property and it's not empty
    if (comment.enfants && comment.enfants.length > 0) {
      countWithEnfants++;
    }
  });

  console.log("Count of comments with enfants:::", countWithEnfants);

  /*--------------------------Get number of keys---------------------------------*/
  // Créez un objet pour stocker le nombre d'occurrences de chaque mot-cle
  const occurrences = {};
  getKeys.forEach((key) => {
    const mot_cle = key.mot_cle;
    occurrences[mot_cle] = 0;
    comment_list.forEach((comment) => {
      const message = comment.message;
      if (message.includes(mot_cle)) {
        occurrences[mot_cle]++; // Incrémentez le compteur si le mot-cle est trouvé
      }
    });
  });
  /*-----------------------------------------------------------*/

  /*----------------- export data for excel */
  const exportToExcel = async () => {
    try {
      setLoading(true);
      const response = await API.pages.getCommentsExcel(current_post);
      console.log(response.data);

      const keysTable = Object.keys(response.data[0]);
      const value_comment = response.data.map((comment) => {
        return keysTable.map((key) => comment[key]);
      });

      // Formatez les noms des propriétés
      const formattedKeys = keysTable.map((key) => {
        return key.replace(/_/g, " ").replace(/^\w/, (c) => c.toUpperCase());
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet([formattedKeys, ...value_comment]);

      XLSX.utils.book_append_sheet(wb, ws, "Comment Data");
      XLSX.writeFile(wb, "comment_data.xlsx");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Désactiver le chargement après l'exportation
    }
  };

  /*------ filter */
  const handleFilterClick = (sentiment) => {
    setSentimentFilter(sentiment);
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
    if (e.target.value == "") {
      setPaginatePage(1);
      setSearchValue("");
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      setPaginatePage(1);
      setSearchValue(inputTexte);
    }
  };

  const isArabic = (word) => {
    const arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(word);
  };

  const getGroupIndex = (index) => Math.floor(index / 5);

  //-- getWordCloud

  // Convert object to array of key-value pairs
  const wordArray = getWordCloud ? Object.entries(getWordCloud) : [];

  // Sort the array by values in descending order
  const sortedWordArray = wordArray.sort((a, b) => b[1] - a[1]);

  // Create an array of shuffled indices
  const shuffledIndices = Array.from(
    { length: sortedWordArray.length },
    (_, index) => index
  );
  for (let i = shuffledIndices.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledIndices[i], shuffledIndices[j]] = [
      shuffledIndices[j],
      shuffledIndices[i],
    ];
  }

  //-- Score
  const score_color = {
    "A+": "#8ed55c",
    A: "#4a932b",
    B: "#f9cb33",
    C: "#f5c499",
    D: "#e5e5e5",
  };

  //-- Tag
  const handleButtonClick = () => {
    setVisibleInputId(true);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const accept = async () => {
    if (!inputValue) {
      // Handle validation error
      return;
    }

    setVisibleInputId(false); // Hide the input field
    setInputValue(""); // Clear the input value

    try {
      const response = await API.pages.tag_save(post.id, inputValue);
      toast.current.show({
        severity: "success",
        summary: "Tag",
        detail: "Ajouté avec succès",
        life: 3000,
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }

    console.log("Accepted value:", inputValue);
  };

  const reject = () => {
    setVisibleInputId(false); // Hide the input field
    setInputValue(""); // Clear the input value
    console.log("Rejected");
  };

  const TexteLimite = ({ texte, longueurLimite }) => {
    if (!texte) {
      return <span>Tag</span>;
    }

    if (texte.length <= longueurLimite) {
      return <span>{texte}</span>;
    } else {
      const texteLimite = texte.slice(0, longueurLimite) + "...";
      return <span>{texteLimite}</span>;
    }
  };

  const onHide = () => {
    setVisible(false);
  };

  const refreshPage = () => {
    window.location.href = `/reporting/${state.currentPage.page_id}`;
  };

  const footerContent = (
    <button
      className="p-button p-component p-button-warning table !m-auto"
      onClick={refreshPage}
    >
      <span className="">Rafraichir la page</span>
    </button>
  );

  const cronEvent = async () => {
    const post_id = post.id;

    console.log(`Activating cron for post_id: ${post_id}`);

    setCronActivationSuccess(true);
    try {
      const response = await API.pages.cronComments(post_id);
      console.log(response.data);

      // Set the state to indicate success for each page_id
      setCronActivationSuccess(false);
    } catch (error) {
      //console.error(`Error for page_id ${list_page.page_id}:`, error);
      // Handle errors if needed
    } finally {
      // Reset the state to indicate success or failure
      setCronActivationSuccess(true);
    }

    setVisible(true);
    console.log("Cron activation for social_id completed.");
  };

  return (
    <div className="detail_post">
      <Dialog
        header=""
        visible={visible}
        position="top"
        style={{ width: "50vw" }}
        onHide={onHide}
        footer={footerContent}
        draggable={false}
        resizable={false}
      >
        <p className="text-[#101828] font-semibold text-[24px] text-center">
          La mise à jour de vos données
          <br />a été bien effectuée!
        </p>
      </Dialog>

      <div className="flex border-b-2 border-[#eaecf0] mb-8 pb-4">
        <button
          className="flex items-center mr-7"
          onClick={redirectToPostLibrary}
        >
          <img className="mr-2" src={iconBack} alt="" />
          <span className="text-[#475467] text-[14px] font-semibold">
            Back to post library
          </span>
        </button>
        <h1 className="text-[#101828] text-[30px] font-black">
          Welcome, {dataPage.page.name}
        </h1>
      </div>

      <div className="content_post">
        <div className="lg:flex">
          <div className="lg:w-1/3">
            <div className="info_post pr-8">
              <div className="review_social flex items-center border-b-2 border-[#eaecf0] pb-4 mb-4">
                <span className="text-[#101828] text-[18px] font-black">
                  Post
                </span>
                <a
                  href={url_post}
                  className="table ml-auto border border-[#d0d5dd] rounded-[8px] text-[#344054] text-[14px] font-semibold drop-shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)] px-3 py-3"
                  target="_blank"
                >
                  Preview on {post?.social_type}
                </a>
              </div>

              <div className="box_post border border-[#eaecf0] rounded-[12px] py-5 bg-white">
                <div className="flex mb-2 px-4 pt-4">
                  <div className="header_post flex mb-5 px-4">
                    <img
                      className="w-12 h-12 rounded-full"
                      src={dataPage.page?.picture}
                    />
                    <div className="ml-2 mt-0.5">
                      <div>
                        <span className="block font-medium text-base leading-snug text-black">
                          {dataPage.page?.name}
                        </span>
                      </div>
                      <span className="block text-sm text-gray-500 dark:text-gray-400 font-light leading-snug">
                        {new Date(post?.creation_time).toDateString()}
                      </span>
                    </div>
                  </div>

                  <div className="ml-auto btn_tag">
                    <Toast ref={toast} />
                    <Button
                      onClick={handleButtonClick}
                      tooltip={post.tag}
                      tooltipOptions={{ position: "top" }}
                    >
                      <TexteLimite texte={post.tag} longueurLimite={10} />
                    </Button>
                    {visibleInputId && (
                      <div className="absolute right-0 z-10 bg-white px-5 py-5 rounded-[5px] shadow">
                        <h2 className="text-center font-medium mb-2">
                          Merci d'ajouter un Tag
                        </h2>
                        <div className="border border-[#d0d5dd] rounded-[5px] mb-2">
                          <InputText
                            type="text"
                            onChange={handleChange}
                            value={inputValue}
                            placeholder={post.tag}
                            className="p-2"
                          />
                        </div>
                        <div className="footer_tag ml-auto table">
                          <button
                            className="bg-[#f9f5ff] px-4 py-2 text-[14px] text-[#6941c6] font-semibold rounded-[5px] mr-2"
                            onClick={accept}
                          >
                            {t("Options.add")}
                          </button>
                          <button
                            className="bg-[#f9f5ff] px-4 py-2 text-[14px] text-[#6941c6] font-semibold rounded-[5px] reject_btn"
                            onClick={reject}
                          >
                            {t("Options.cancel")}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="content_post">
                  <span className="text-[#475467] text-[14px] mb-5 px-4 block">
                    {post?.message}
                  </span>
                  {post.picture ? <img src={post?.picture} /> : <div></div>}
                </div>

                {/* statique */}

                <div className="relative">
                  {post.score_new && (
                    <div
                      className="flex items-center justify-center text-[12px] text-[#ffffff] rank_score rounded-full font-semibold w-[25px] h-[25px] absolute top-[-30px] right-[5%]"
                      style={{ backgroundColor: score_color[post.score_new] }}
                    >
                      {post.score_new}
                    </div>
                  )}

                  <span className="px-4 mt-4 block text-[#6941C6] text-[14px] font-semibold">
                    {post.reach_paid ? "Sponsorisé" : "Organic"}
                  </span>

                  <div className="grid grid-cols-3 gap-4 mx-auto px-4">
                    <div className="total-fans w-full bg-[#f2f4f7]">
                      <h1 className="text-asap-text text-xs text-[#101828] text-[14px] font-semibold">
                        {dataPage.social_type === "youtube"
                          ? post?.video_likes | 0
                          : post?.likes +
                            post?.wow +
                            post?.sad +
                            post?.haha +
                            post?.angry +
                            post?.none +
                            post?.love +
                            post?.thankful +
                            post?.saved}
                      </h1>
                      <p className="text-asap-text text-xs text-[#475467] text-[14px]">
                        {dataPage.social_type == "youtube"
                          ? t("Performance_report.likes")
                          : t("Performance_report.reactions")}
                      </p>
                      <hr className="mt-2" />
                    </div>

                    <div className="flex items-center gap-2">
                      <div className="total-fans w-full">
                        <h1 className="text-asap-text text-xs text-[#101828] text-[14px] font-semibold">
                          {post?.comments}
                        </h1>
                        <p className="text-asap-text text-xs text-[#475467] text-[14px]">
                          {t("Performance_report.comments")}
                        </p>
                        <hr className="mt-2" />
                      </div>
                    </div>
                    {dataPage.social_type !== "youtube" && (
                      <div className="flex items-center gap-2">
                        <div className="total-fans w-full">
                          <h1 className="text-asap-text text-xs text-[#101828] text-[14px] font-semibold">
                            {post.shares ? post.shares : 0}
                          </h1>
                          <p className="text-asap-text text-xs text-[#475467] text-[14px] ">
                            {t("Performance_report.shares")}
                          </p>
                          <hr className="mt-2" />
                        </div>
                      </div>
                    )}
                    <div className="flex items-center gap-2">
                      <div className="total-fans w-full">
                        <h1 className="text-asap-text text-xs text-[#101828] text-[14px] font-semibold">
                          {/* {post.engagement.toFixed(2)} */}
                          {dataPage.social_type === "youtube"
                            ? (+post.comments || 0) + (+post.views || 0)
                            : dataPage.social_type === "instagram"
                            ? post.reach
                              ? (
                                  ((post.clicks +
                                    post.comments +
                                    post.shares +
                                    (post.likes +
                                      post.wow +
                                      post.sad +
                                      post.haha +
                                      post.angry +
                                      post.none +
                                      post.love +
                                      post.thankful +
                                      post.saved)) /
                                    post.reach) *
                                  100
                                ).toFixed(2) + "%"
                              : 0
                            : post.reach_paid || post.reach_organic
                            ? (
                                ((post.clicks +
                                  post.comments +
                                  post.shares +
                                  (post.likes +
                                    post.wow +
                                    post.sad +
                                    post.haha +
                                    post.angry +
                                    post.none +
                                    post.love +
                                    post.thankful +
                                    post.saved)) /
                                  (post.reach_paid + post.reach_organic)) *
                                100
                              ).toFixed(2) + "%"
                            : 0}
                        </h1>
                        <p className="text-asap-text text-xs text-[#101828] text-[14px]">
                          {t("Performance_report.engagement")}
                        </p>
                        <hr className="mt-2" />
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="total-fans w-full">
                        <h1 className="text-asap-text text-xs text-[#101828] text-[14px] font-semibold">
                          {totalVideoViews}
                        </h1>
                        <p className="text-asap-text text-xs text-[#475467] text-[14px]">
                          {t("Performance_report.views")}
                        </p>
                        <hr className="mt-2" />
                      </div>
                    </div>
                    <div className="flex items-center gap-2 mr-2">
                      <div className="total-fans w-full">
                        <h1 className="text-asap-text text-xs text-[#101828] text-[14px] font-semibold">
                          {dataPage.social_type === "youtube"
                            ? post?.video_time | 0
                            : dataPage.social_type === "instagram"
                            ? post.reach
                              ? post.reach
                              : 0
                            : post.reach_paid + post.reach_organic}
                        </h1>
                        <p className="text-asap-text text-xs text-[#101828] text-[14px]">
                          {dataPage.social_type === "youtube"
                            ? t("Performance_report.length")
                            : t("Performance_report.reach")}
                        </p>
                        <hr className="mt-2" />
                      </div>
                    </div>
                    {dataPage.social_type !== "youtube" && (
                      <div className="flex items-center gap-2 mr-2">
                        <div className="total-fans w-full">
                          <h1 className="text-asap-text text-xs text-[#101828] text-[14px] font-semibold">
                            {post.clicks ? post.clicks : 0}
                          </h1>
                          <p className="text-asap-text text-xs text-[#475467] text-[14px]">
                            {t("Performance_report.clics")}
                          </p>
                          <hr className="mt-2" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* end statique */}
              </div>
            </div>

            {shuffledIndices && shuffledIndices.length > 0 && (
              <div className="wordCloud flex flex-wrap justify-center box_post border border-[#eaecf0] rounded-[12px] py-5 bg-white mt-5 p-4 mr-8">
                {shuffledIndices.map((shuffledIndex) => {
                  const [word, count] = sortedWordArray[shuffledIndex];
                  const index = wordArray.findIndex(
                    ([originalWord]) => originalWord === word
                  );
                  return (
                    <div
                      key={word}
                      className={`flex items-end mr-2 mb-2 ${
                        isArabic(word) ? "word_arabic flex" : ""
                      } element_${getGroupIndex(index)}`}
                    >
                      <span>{word}</span>
                      <span>({count})</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="lg:w-2/3">
            <div className="list_comments pl-8 border-l-2 border-[#eaecf0]">
              <div className="border border-[#eaecf0] rounded-[12px] bg-white">
                <div className="flex items-center">
                  <div className="flex items-center">
                    <h2 className="text-[#101828] text-[18px] font-semibold mr-3 p-6">
                      clics{t("Performance_report.comments")}
                    </h2>
                    <span className="bg-[#f9f5ff] text-[12px] text-[#6941c6] font-semibold p-[5px] px-2 py-1 rounded-[12px]">
                      {lastPage ?? 0}
                    </span>
                  </div>

                  <div className="flex items-center ml-auto mr-4">
                    <button
                      className={`table ml-auto mr-2 border border-[#d0d5dd] rounded-[8px] text-[#344054] text-[14px] font-semibold drop-shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)] px-3 py-3 ${
                        loading ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      onClick={exportToExcel}
                      disabled={loading}
                    >
                      {loading ? "Exporting..." : "Exporter Comments"}
                    </button>

                    <button
                      className={`ml-auto px-4 py-3 border border-[#d0d5dd] rounded-[6px] bg-white`}
                      onClick={cronEvent}
                    >
                      <CronIcon
                        className={`${
                          cronActivationSuccess ? "rotate-animation" : ""
                        }`}
                      />
                    </button>
                  </div>
                </div>

                <div className="mx-4 mb-9">
                  <span className="p-input-icon-left w-full border border-[#d0d5dd] rounded-[6px]">
                    <i className="pi pi-search" />
                    <InputText
                      value={inputTexte}
                      placeholder="Search"
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      className="text-[#667085] text-[16px] placeholder:text-[#667085] w-full py-2"
                    />
                  </span>
                </div>

                <div className="box_comments">
                  <div className="list_filter flex items-center mx-4 pb-5">
                    <button
                      className={`text-[14px] font-semibold mr-2 ${
                        sentimentFilter === "All"
                          ? "active text-[#6941c6]"
                          : "text-[#667085]"
                      }`}
                      onClick={() => handleFilterClick("All")}
                    >
                      All
                    </button>
                    <button
                      className={`flex items-center ml-5 ${
                        sentimentFilter === "Positive" ? "active" : ""
                      }`}
                      onClick={() => handleFilterClick("Positive")}
                    >
                      <span
                        className={`text-[14px] font-semibold mr-2 ${
                          sentimentFilter === "Positive"
                            ? "active text-[#027a48]"
                            : "text-[#667085]"
                        }`}
                      >
                        Positive
                      </span>
                      <div
                        className={`text-[12px] font-semibold p-[5px] px-2 py-1 rounded-[12px] ${
                          sentimentFilter === "Positive"
                            ? "active bg-[#ecfdf3] text-[#027a48]"
                            : "bg-[#f2f4f7] text-[#344054]"
                        }`}
                      >
                        {listSentiments.postive}
                      </div>
                    </button>
                    <button
                      className={`flex items-center ml-5 ${
                        sentimentFilter === "Negative" ? "active" : ""
                      }`}
                      onClick={() => handleFilterClick("Negative")}
                    >
                      <span
                        className={`text-[14px] font-semibold mr-2 ${
                          sentimentFilter === "Negative"
                            ? "active text-[#d92d20]"
                            : "text-[#667085]"
                        }`}
                      >
                        Negative
                      </span>
                      <div
                        className={`text-[12px] font-semibold p-[5px] px-2 py-1 rounded-[12px] ${
                          sentimentFilter === "Negative"
                            ? "active bg-[#fef3f2] text-[#d92d20]"
                            : "bg-[#f2f4f7] text-[#344054]"
                        }`}
                      >
                        {listSentiments.negative}
                      </div>
                    </button>
                    <button
                      className={`flex items-center ml-5 ${
                        sentimentFilter === "Neutral" ? "active" : ""
                      }`}
                      onClick={() => handleFilterClick("Neutral")}
                    >
                      <span
                        className={`text-[14px] font-semibold mr-2 ${
                          sentimentFilter === "Neutral"
                            ? "active text-[#6172f3]"
                            : "text-[#667085]"
                        }`}
                      >
                        Neutral
                      </span>
                      <div
                        className={`text-[12px] font-semibold p-[5px] px-2 py-1 rounded-[12px] ${
                          sentimentFilter === "Neutral"
                            ? "active bg-[#eef4ff] text-[#667085]"
                            : "bg-[#f2f4f7] text-[#344054]"
                        }`}
                      >
                        {listSentiments.neutre}
                      </div>
                    </button>
                  </div>

                  <div className="reponse_post">
                    {comment_list.length > 0 &&
                      comment_list.map((comment, index) => {
                        return (
                          <div key={index} className="box_rep">
                            <div
                              data-sentiment={comment.sentiment}
                              className={`header_rep box_comment px-9 py-4 border-t-2 border-[#eaecf0] ${
                                activeCommentId === comment.social_id
                                  ? "active bg-[#eaecf0]"
                                  : ""
                              }`}
                            >
                              {/* Affichez les détails du commentaire */}
                              <div className="flex items-center">
                                <div className="flex items-center">
                                  {/* <img className="w-10 h-10 rounded-full" src={comment?.from_pic} alt="User Profile" /> */}
                                  <div>
                                    <div>
                                      <span className="block font-medium text-black text-[14px] text-[#344054]">
                                        {comment?.from_name}
                                      </span>
                                    </div>
                                    <span className="block text-sm text-gray-500 dark:text-gray-400 font-light leading-snug">
                                      {new Date(
                                        comment?.creation_time
                                      ).toDateString()}
                                    </span>
                                  </div>
                                </div>

                                {/* sentiment */}

                                {/* sentiment Positive */}
                                {comment?.sentiment == "Positive" && (
                                  <div className="table ml-auto bg-[#ecfdf3] px-2 py-1 rounded-[16px]">
                                    <div className="flex items-center">
                                      <div className="bg-[#12b76a] w-2 h-2 rounded-full mr-1"></div>
                                      <span className="text-[#027a48] text-[12px] font-semibold">
                                        {comment?.sentiment}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {/* sentiment Negative */}
                                {comment?.sentiment == "Negative" && (
                                  <div className="table ml-auto bg-[#fef3f2] px-2 py-1 rounded-[16px]">
                                    <div className="flex items-center">
                                      <div className="bg-[#f04438] w-2 h-2 rounded-full mr-1"></div>
                                      <span className="text-[#d92d20] text-[12px] font-semibold">
                                        {comment?.sentiment}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {/* sentiment Neutral */}
                                {comment?.sentiment == "Neutral" && (
                                  <div className="table ml-auto bg-[#eef4ff] px-2 py-1 rounded-[16px]">
                                    <div className="flex items-center">
                                      <div className="bg-[#6172f3] w-2 h-2 rounded-full mr-1"></div>
                                      <span className="text-[#3538cd] text-[12px] font-semibold">
                                        {comment?.sentiment}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <p
                                className={`text-[14px] text-[#475467] py-4 ${
                                  isArabic(comment?.message)
                                    ? "word_arabic text-left"
                                    : ""
                                }`}
                              >
                                {comment?.message}
                              </p>
                              {comment.attachment && (
                                <img
                                  className="pb-4"
                                  src={comment.attachment}
                                  alt=""
                                />
                              )}

                              <button
                                className={`reponse_message flex items-center text-[14px] font-bold ${
                                  activeCommentId === comment.social_id
                                    ? "active text-[#6941c6]"
                                    : "text-[#475467]"
                                }`}
                                data-id={comment?.social_id}
                                onClick={() => {
                                  fetchComments(comment?.message);
                                  setSelectedCommentId(comment?.social_id); // Mettez à jour l'état avec data-id
                                  setActiveCommentId(
                                    activeCommentId === comment.social_id
                                      ? null
                                      : comment.social_id
                                  );
                                }}
                              >
                                <img
                                  className="mr-2"
                                  src={
                                    activeCommentId === comment.social_id
                                      ? reverseLeftActive
                                      : reverseLeft
                                  }
                                  alt=""
                                />
                                Reply
                              </button>
                            </div>

                            {/* Afficher les enfants si existent */}
                            {comment.enfants &&
                              comment.enfants.length > 0 &&
                              comment.enfants.map((child, childIndex) => {
                                return (
                                  <div
                                    key={childIndex}
                                    className="box_rep pl-5 bg-[#f2f4f7]"
                                  >
                                    <div
                                      data-sentiment={child.sentiment}
                                      className={`header_rep box_comment px-9 py-4 border-t-2 border-[#eaecf0] ${
                                        activeCommentId === child.social_id
                                          ? "active bg-[#eaecf0]"
                                          : ""
                                      }`}
                                    >
                                      {/* Affichez les détails du commentaire */}
                                      <div className="flex items-center">
                                        <div className="flex items-center">
                                          {/* <img className="w-10 h-10 rounded-full" src={comment?.from_pic} alt="User Profile" /> */}
                                          <div>
                                            <div>
                                              <span className="block font-medium text-black text-[14px] text-[#344054]">
                                                {child?.from_name}
                                              </span>
                                            </div>
                                            <span className="block text-sm text-gray-500 dark:text-gray-400 font-light leading-snug">
                                              {new Date(
                                                child?.creation_time
                                              ).toDateString()}
                                            </span>
                                          </div>
                                        </div>

                                        {/* sentiment */}

                                        {/* sentiment Positive */}
                                        {child?.sentiment == "Positive" && (
                                          <div className="table ml-auto bg-[#ecfdf3] px-2 py-1 rounded-[16px]">
                                            <div className="flex items-center">
                                              <div className="bg-[#12b76a] w-2 h-2 rounded-full mr-1"></div>
                                              <span className="text-[#027a48] text-[12px] font-semibold">
                                                {child?.sentiment}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* sentiment Negative */}
                                        {child?.sentiment == "Negative" && (
                                          <div className="table ml-auto bg-[#fef3f2] px-2 py-1 rounded-[16px]">
                                            <div className="flex items-center">
                                              <div className="bg-[#f04438] w-2 h-2 rounded-full mr-1"></div>
                                              <span className="text-[#d92d20] text-[12px] font-semibold">
                                                {child?.sentiment}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* sentiment Neutral */}
                                        {child?.sentiment == "Neutral" && (
                                          <div className="table ml-auto bg-[#eef4ff] px-2 py-1 rounded-[16px]">
                                            <div className="flex items-center">
                                              <div className="bg-[#6172f3] w-2 h-2 rounded-full mr-1"></div>
                                              <span className="text-[#3538cd] text-[12px] font-semibold">
                                                {child?.sentiment}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                      <p
                                        className={`text-[14px] text-[#475467] py-4 ${
                                          isArabic(child?.message)
                                            ? "word_arabic text-left"
                                            : ""
                                        }`}
                                      >
                                        {child?.message}
                                      </p>
                                      {comment.attachment && (
                                        <img
                                          className="pb-4"
                                          src={child.attachment}
                                          alt=""
                                        />
                                      )}

                                      <button
                                        className={`reponse_message flex items-center text-[14px] font-bold ${
                                          activeCommentId === child.social_id
                                            ? "active text-[#6941c6]"
                                            : "text-[#475467]"
                                        }`}
                                        data-id={child?.social_id}
                                        onClick={() => {
                                          fetchComments(child?.message);
                                          setSelectedCommentId(
                                            child?.social_id
                                          ); // Mettez à jour l'état avec data-id
                                          setActiveCommentId(
                                            activeCommentId === child.social_id
                                              ? null
                                              : child.social_id
                                          );
                                        }}
                                      >
                                        <img
                                          className="mr-2"
                                          src={
                                            activeCommentId === child.social_id
                                              ? reverseLeftActive
                                              : reverseLeft
                                          }
                                          alt=""
                                        />
                                        Reply
                                      </button>
                                      {/* Ajoutez ici la logique pour afficher d'autres détails des enfants si nécessaire */}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center py-5	mt-5 ml-8 border border-[#eaecf0] rounded-[12px] bg-white">
              <Pagination
                onPageChange={handlePageChange}
                totalCount={lastPage}
                currentPage={paginatePage}
                pageSize={5} // ajustez la taille de la page selon vos besoins
                className="custom-pagination-class"
              />
            </div>

            {responseData && (
              <div className="suggestion_openia border border-[#7f56d9] rounded-[8px] px-3 py-5 mt-6 ml-8">
                <h2 className="flex items-center text-[#7f56d9] text-[14px] font-bold">
                  <img src={star} className="mr-4" alt="" />
                  Response generated by open Ai
                </h2>
                <div className="text-[#101828] text-[16px] mt-4">
                  {responseData}
                </div>
              </div>
            )}

            <form onSubmit={formik.handleSubmit} className="mt-6 ml-8">
              <Toast ref={toast} />
              <InputTextarea
                inputid="description"
                name="description"
                rows={4}
                cols={30}
                className={classNames(
                  { "p-invalid": isFormFieldInvalid("description") },
                  "w-full inputTextarea_style"
                )}
                value={formik.values.description || responseData}
                onChange={(e) => {
                  formik.setFieldValue("description", e.target.value);
                }}
              />
              {getFormErrorMessage("description")}
              <Button
                className="save_rep table ml-auto"
                label="Send"
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default PostDetail;
