import React from "react";
import { useTranslation } from "react-i18next";
import millify from "millify";
import { useState } from "react";
import Highcharts from "highcharts";
import { Dropdown } from "primereact/dropdown";
import HighchartsReact from "highcharts-react-official";
import { AppContext, API } from "../../utils";
import html2canvas from "html2canvas";
import DropdownImg from "../../images/dropdown1.png";

import { ReactComponent as UpIcon } from "../../images/upIcon.svg";
import { ReactComponent as DownIcon } from "../../images/downIcon.svg";

const SpLineFans = (props) => {
  const [state, setState] = React.useContext(AppContext);
  function convert_format_date(date_to_convert) {
    let date = new Date(date_to_convert);

    // Get year, month, and day part from the date
    let year = date.toLocaleString("default", { year: "numeric" });
    let month = date.toLocaleString("default", { month: "2-digit" });
    let day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    let formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }
  const { t, i18n } = useTranslation();

  function day_or_month(date_one, date_two) {
    let date1 = new Date(date_one);
    let date2 = new Date(date_two);

    let diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let type = " ";
    if (diffDays <= 31) {
      type = "day";
    } else {
      type = "month";
    }

    return type;
  }

  let date_form = convert_format_date(state.reportingPeriod[0].toDateString());
  let date_to = convert_format_date(state.reportingPeriod[1].toDateString());

  const [type_option, setType_option] = useState(
    day_or_month(date_form, date_to)
  );
  const [valueDropdownOption, setValueDropdownOption] = useState(
    day_or_month(date_form, date_to)
  );

  let options = {
    credits: false,

    chart: {
      type: "spline",
      zoomType: "xy",
      style: {
        fontFamily: "Inter",
      },
    },
    title: {
      text: t("Performance_report.page_reach"),
      align: "left",
      margin: 50,
    },
    subtitle: {
      text: t("Performance_report.page_reach_desc"),
      align: "left",
      margin: 50,
    },
    xAxis: {
      categories: [],
      accessibility: {
        description: "Months of the year",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value + "";
        },
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        marker: {
          radius: 4,
          //   lineColor: "#596ae9",
          lineWidth: 1,
          enabled: false,
        },
      },
    },
    series: [{}],
  };

  //-- day data
  const day_array = [];
  const day_array_page_reach_organic = [];
  const day_array_page_reach_paid = [];
  const propsDay = Object.entries(props.data.by_day);
  for (const [key, value] of Object.entries(propsDay)) {
    day_array.push(value[0]);
    day_array_page_reach_organic.push(
      value[1].page_impressions_organic_unique ?? 0
    );
    day_array_page_reach_paid.push(value[1].page_impressions_paid_unique ?? 0);
  }

  const day_page_reach_organic = {
    name: t("Performance_report.organic_reach"),
    data: day_array_page_reach_organic,
  };
  const day_page_reach_paid = {
    name: t("Performance_report.paid_reach"),
    data: day_array_page_reach_paid,
  };

  function sort_array(dates) {
    dates.sort(function (a, b) {
      let dateA = new Date(a);
      let dateB = new Date(b);
      return dateA - dateB;
    });
    return dates;
  }

  function day_data_option() {
    options.series.push(day_page_reach_organic);
    options.series.push(day_page_reach_paid);
    sort_array(day_array).map((entry, index) => {
      options.xAxis.categories.push(entry);
    });
  }

  //-- month data
  const keys = Object.keys(props.data.total.reach_page.by_month);

  const porte_data_month = Object.values(props.data.total.reach_page.by_month);

  const page_reach_organic_array = porte_data_month.map(
    (item) => +item.page_organic
  );
  const page_reach_organic = {
    name: t("Performance_report.organic_reach"),
    data: page_reach_organic_array,
  };

  const page_reach_paid_array = porte_data_month.map((item) => +item.page_paid);
  const page_reach_paid = {
    name: t("Performance_report.paid_reach"),
    data: page_reach_paid_array,
  };

  const status = [
    { name: t("Options.month"), code: "month" },
    { name: t("Options.day"), code: "day" },
  ];

  function month_data_option() {
    options.series.push(page_reach_organic);
    options.series.push(page_reach_paid);
    keys.forEach((key) => {
      options.xAxis.categories.push(key);
    });
  }

  //-- option
  type_option === "month" ? month_data_option() : day_data_option();
  const onOptionChange = (e) => {
    setValueDropdownOption(e.value);
    if (e.value === "month") setType_option("month");
    if (e.value === "day") setType_option("day");
  };

  function remove_empty_value(arr) {
    var index;
    arr.some(function (obj, i) {
      return Object.keys(obj).length === 0 && obj.constructor === Object
        ? ((index = i), true)
        : false;
    });
    arr.shift(index);
    return index;
  }
  remove_empty_value(options.series);

  const previous_end_date = props.data.date_info.previous_end_day ?? 0;
  const previous_start_date = props.data.date_info.previous_start_day ?? 0;

  const previous_total = props.data.total.previous_total;
  const variation_reachs = props.data.total.previous_total.variation_reachs;
  const variation_posts_reach_organic =
    previous_total.previous_posts_reach_organic
      ? ((props.data.total.posts_reach_organic -
          previous_total.previous_posts_reach_organic) /
          previous_total.previous_posts_reach_organic) *
          100 ?? 0
      : 0;
  const variation_posts_reach_paid = previous_total.previous_posts_reach_paid
    ? ((props.data.total.posts_reach_paid -
        previous_total.previous_posts_reach_paid) /
        previous_total.previous_posts_reach_paid) *
        100 ?? 0
    : 0;

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");
    parent.classList.add("capture_style");

    html2canvas(parent, {
      onclone: (document) => {},
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        parent.classList.remove("capture_style");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  return (
    <div>
      <div className="highchart_block">
        <div data-value="Croissance Portée" className="highchart_block1">
          <div data-html2canvas-ignore className="flex items-center">
            <div className="flex items-center ml-auto border rounded-md capitalize">
              <img src={DropdownImg} className="pl-2" alt="DropdownImg" />
              <Dropdown
                value={valueDropdownOption}
                options={status}
                onChange={onOptionChange}
                defaultValue={setType_option}
                optionLabel="name"
                optionValue="code"
                placeholder="Choisir"
                className="font-medium"
              />
            </div>

            <button
              onClick={printChart}
              className="mx-5 w-5 h-5 print_capture"
            ></button>
          </div>

          <HighchartsReact highcharts={Highcharts} options={options} />

          <div className="info_chart_fans flex mt-15">
            <div className="w-1/2">
              <ul className="flex pb-0.8 border-[#dee0e1] border-b font-bold">
                <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                  <div className="w-3 h-3 rounded-full bg-[#5596e6] mr-1.5"></div>
                  {t("Performance_report.total_reach")}
                </li>
                <li className="ml-auto flex items-center">
                  {props.data.total?.reach_page.impressions_uniques_total
                    ? props.data.total.reach_page.impressions_uniques_total.toLocaleString()
                    : 0}
                  {props.data.total?.reach_page
                    .var_impressions_uniques_total_prev > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {props.data.total?.reach_page.var_impressions_uniques_total_prev.toFixed(
                    2
                  ) || 0}
                  %
                </li>
              </ul>
              <ul className="flex pb-0.8 border-[#dee0e1] border-b">
                <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                  <div className="w-3 h-3 rounded-full bg-[#6955e6] mr-1.5"></div>
                  {t("Performance_report.organic_reach")}
                </li>
                <li className="ml-auto flex items-center">
                  {props.data.total?.reach_page.impressions_uniques_organic
                    ? props.data.total.reach_page.impressions_uniques_organic.toLocaleString()
                    : 0}
                  {props.data.total?.reach_page
                    .var_impressions_uniques_organic_prev > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {props.data.total?.reach_page.var_impressions_uniques_organic_prev.toFixed(
                    2
                  ) || 0}
                  %
                </li>
              </ul>
              <ul className="flex pb-0.8 border-[#dee0e1] border-b">
                <li className="flex items-center text-[#344563] text-[14px] font-['inter']">
                  <div className="w-3 h-3 rounded-full bg-[#22c4ac] mr-1.5"></div>
                  {t("Performance_report.paid_reach")}
                </li>
                <li className="ml-auto flex items-center">
                  {props.data.total?.reach_page.impressions_uniques_paid
                    ? props.data.total.reach_page.impressions_uniques_paid.toLocaleString()
                    : 0}
                  {props.data.total?.reach_page
                    .var_impressions_uniques_paid_prev > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {props.data.total?.reach_page.var_impressions_uniques_paid_prev.toFixed(
                    2
                  ) || 0}
                  %
                </li>
              </ul>
            </div>
            <div className="w-1/2">
              <div className="audience_total bg-rectangle rounded-[8px] ml-10 h-full">
                <div className="flex items-center">
                  <div className="w-1/5">
                    <h2 className="text-[#344563] text-[16px] font-['inter']">
                      {t("Performance_report.total_reach")}
                    </h2>
                  </div>
                  <div className="w-4/5">
                    <span className="text-[#152935] text-[24px] font-['inter'] font-extrabold">
                      {props.data.total?.reach_page.impressions_uniques_total
                        ? props.data.total.reach_page.impressions_uniques_total.toLocaleString()
                        : 0}{" "}
                    </span>
                  </div>
                </div>

                <div className="flex items-center mt-9">
                  <div className="w-1/5">
                    <span className="text-[#344563] text-[14px] font-['inter']  font-bold">
                      {props.data.total.reach_page.var_impressions_uniques_total_prev
                        .toString()
                        .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                      %
                    </span>
                  </div>
                  <div className="w-4/5">
                    <span className="text-[#344563] text-[14px] font-['inter']">
                      {t("Options.compared")}
                      <span className="font-bold">
                        {" "}
                        {previous_end_date} - {previous_start_date}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SpLineFans;
