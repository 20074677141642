import React from "react";
import { Skeleton } from "primereact/skeleton";



const MediaSkeleton = (props) => {
    let post = props.post;

    let pictrue_post = post.picture;
    /*if ((post.social_type == "facebook" && post.status_type == "storie_photo") || (post.social_type === "instagram" && post.status_type === "STORY")) {
      //pictrue_post = process.env.REACT_APP_API_URL + pictrue_post;
      //pictrue_post = pictrue_post;
    }*/



    let media = <img className={
      "img_post mt-4 w-full " + (!post.status ? "hidden" : "")
    }
      onLoad={props.onLoad}
      onError={props.onError}
      src={pictrue_post}
    />;

    if ( (post.social_type === "facebook" && post.status_type === "storie_video") || (post.type && post.type.toLowerCase() == 'video') ) {
      media = <video

        className={
          "img_post mt-4 w-full " + (!post.status ? "hidden" : "")
        }
        onLoadedMetaData={props.onLoad}
        onLoadedData={props.onLoad}
        // _loadedmetadata={props.onLoad}
        onError={props.onError}
        src={pictrue_post}
        controls
        muted
        autoPlay
        loop
      />
    }
  
    if (!pictrue_post) media = null;
    return (
      <div className="grid formgrid mb-8 mt-2">
        <div className="field col-12 md:col-6  w-full">
          <div className="custom-skeleton">
            {post.status == "loaded" || !post.status ? (media) : null}
  
            {post.status != "loaded" && pictrue_post ? (
              <Skeleton width="100%" height="224px"></Skeleton>
            ) : null}
  
            {!pictrue_post ? (
              <div>
              <div style={{ width: "100%", height: 242, display:'flex', alignItems:'center', justifyContent:'center' }}>
                <i>No Media</i>
                </div>
                </div>
            ) : null}
  
          </div>
        </div>
      </div>
    );
  };
  
  export default MediaSkeleton;