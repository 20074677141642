import React from "react";
import navIcon from "../../../images/images/dashboardMain/navIcon.svg";

import { Menu, Transition } from "@headlessui/react";
import { AppContext, SocialIcon, useUtils } from "../../../utils";

import millify from "millify";

const GoalsOverview = (props) => {
  const [state, setState] = React.useContext(AppContext);
  const utils = useUtils();
  const updateObjectif = () => {
    utils.goTo("/reporting/:pageId/goals");
  };

  let goals = state.currentPage?.goals || {};
  //console.log("goals", goals);
  let goalsData = Object.keys(goals);
  let total = {};
  let metrics = {};

  let kpis = state.currentKpis || {};
  if (kpis.total) {
    total = { ...kpis.total };
    metrics = { ...kpis.metrics };
  }

  return (
    <div className="mt-6  font-bold text-xl p-6 pt-6 mx-6 bg-white rounded-md hide_el">
      <h1 className="text-gray-900  font-bold  text-xl has-tooltip">
        Suivez Votre Objectif
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button className="  float-right ">
                <span className="">
                  <img className=" cursor-pointer" src={navIcon} alt="" />
                </span>
              </Menu.Button>

              <Transition show={open} enter="transition ease-out duration-100">
                <Menu.Items
                  static
                  className=" right-8 w-50 float-right -mt-2  origin-top-right bg-asap-white border border-asap-gray-900 divide-y divide-gray-100 rounded-xl shadow-lg outline-none"
                >
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => updateObjectif()}
                          className={`${
                            active
                              ? "bg-asap-gray text-asap-blue dark:text-diggow-blue "
                              : "text-asap-text"
                          } flex justify-between w-full px-6 py-1 text-sm leading-5 text-left font-medium font-Montserrat cursor-pointer`}
                        >
                          Mettre a jour les objectifs
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </h1>

      <div className="flex  justify-between mt-10 items-center mb-6 ">
        {goalsData.map((key, index) => (
          <div className="flex flex-col" key={index}>
            <div className="flex flex-row gap-8 2xl:gap-16 items-center">
              <h1 className=" text-xs 2xl:text-sm font-semibold">
                {metrics[key]?.name}
              </h1>

              <div className="flex items-center font-semibold">
                <span className=" text-xs 2xl:text-sm mr-2">
                  {total[key] ? total[key].toFixed(1) : total[key]}
                </span>
                /
                <span className="text-xs 2xl:text-sm ml-2 text-blue-400">
                  {(goals[key]).toLocaleString()}
                </span>
                <span className="ml-6 text-xs 2xl:text-sm font-bold">
                  {((total[key] * 100) / goals[key]).toFixed(2)}%
                </span>
              </div>
            </div>

            <div className="2xl:w-full bg-gray-200 rounded-full h-2 mt-2 dark:bg-gray-700">
              <div
                className="bg-gradient-to-r from-blue-600    to-blue-300 h-2 rounded-full"
                style={{
                  width: `${
                    (total[key] * 100) / goals[key] < 100
                      ? (total[key] * 100) / goals[key]
                      : 100
                  }%`,
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoalsOverview;
