import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AppContext, API, Loader } from "../../utils";
import Pagination from "./Pagination";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { ReactComponent as CalendarIcon } from "../../images/images/calendar.svg";
import { ReactComponent as CalendarArrow } from "../../images/images/calendarArrow.svg";

const TendanceDetail = () => {
    
    const location = useLocation();
    const [status, setStatus] = useState("loading");
    const [state, setState] = useContext(AppContext);
    const { t } = useTranslation();
    const word = location.state.word;
    const page_id = location.state.page_id;
    const start_date = location.state.start_date;
    const end_date = location.state.end_date;

    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [paginatePage, setPaginatePage] = useState(1);
    const [comment_list, getCommentList] = useState([]);
    const [listSentiments, setListSentiments] = useState([]);
    const [sentimentFilter, setSentimentFilter] = useState("All");   

    // Utilisation de useState pour créer des objets Date à partir des dates formatées
    const [dates, setDates] = useState([
        new Date(start_date),
        new Date(end_date),
    ]);
    
    const to = dayjs(dates[1]).format("YYYY-MM-DD 23:59:59");
    const from = dayjs(dates[0]).format("YYYY-MM-DD 00:00:00");

    const fetchData = async (isExport = false) => {
        try {
            setStatus("loading");
            const response = await API.pages.getTendanceApi(
                word,
                page_id,
                sentimentFilter,
                paginatePage,
                isExport,
                from,
                to,
            );
            if (isExport) {
                return response.data.comments; // return the data if it's an export request
            } else {
                getCommentList(response.data.comments.data);
                setLastPage(response.data.comments.total);
            }
            setStatus("");
            console.log('response.data', response);
        } catch (error) {
            console.error(error);
            setStatus("error");
        }
    };
    
    useEffect(() => {
        fetchData();
    }, [state.reportingPeriod, paginatePage, sentimentFilter]);

    const handlePageChange = (page) => {
      setPaginatePage(page);
    };

    const isArabic = (word) => {
        const arabicRegex = /[\u0600-\u06FF]/;
        return arabicRegex.test(word);
    };

    /*----------------- export data for excel */
    const exportToExcel = async () => {
        try {
            setStatus("loading");
            const comments = await fetchData(true); // call fetchData with isExport = true
            console.log('comments', comments);
            // Vérifiez si les commentaires sont définis et non vides avant d'exporter
            if (comments && comments.length > 0) {
                const keysTable = Object.keys(comments[0]);
                const value_comment = comments.map((comment) => {
                    return keysTable.map((key) => comment[key]);
                });
    
                // Format the property names
                const formattedKeys = keysTable.map((key) => {
                    return key.replace(/_/g, " ").replace(/^\w/, (c) => c.toUpperCase());
                });
    
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.aoa_to_sheet([formattedKeys, ...value_comment]);
    
                XLSX.utils.book_append_sheet(wb, ws, "Comment Data");
                XLSX.writeFile(wb, "comment_data.xlsx");
            } else {
                console.warn("No valid comments data found.");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setStatus("");
        }
    };
    
    

    /*------ filter */
    const handleFilterClick = (sentiment) => {
      setSentimentFilter(sentiment);
    };

    if (status == "loading") return <Loader />;

    return (
        <div>
            
            {/* calendar */}
            <div className="justify-between flex my-4 items-center text-sm 2xl:text-base">
                <div className="flex items-center gap-6">
                    <div className="flex bg-white border-gray-300 border items-center rounded font-CityOfEmber calendar-navbar w-72">
                        <span className="pl-6 dark:text-asap-white text-asap-blue">
                            <CalendarIcon />
                        </span>
                        <Calendar
                            id="range"
                            value={dates}
                            dateFormat="yy/mm/dd"
                            className="font-CityOfEmber text-gray-900 font-bold"
                            panelClassName="mt-6 bg-asap-gray-900 p-2 font-CityOfEmber text-gray-900 font-bold"
                            inputClassName="ml-2 cursor-pointer outline-none text-asap-blue text-sm text-gray-900 font-semibold"
                            onChange={(e) => {
                                setDates(e.value);
                                if (e.value[0] != null && e.value[1] != null)
                                    setState({ ...state, reportingPeriod: e.value });
                            }}
                            readOnlyInput
                            numberOfMonths={1}
                            selectionMode="range"
                        />
                        <span className="pl-2 cursor-pointer dark:text-asap-white text-asap-blue">
                            <CalendarArrow />
                        </span>
                    </div>
                    <div className="flex gap-2">
                        <h1>Période définie pour le rapport :</h1>
                        {dates[0] && (
                            <div className="flex gap-2">
                                <h1>{dates[0]?.toDateString()}</h1> -
                                <h1>{dates[1]?.toDateString()}</h1>
                            </div>
                        )}
                    </div>
                </div>       

            </div>

            <div className="list_comments">
                <div className="border border-[#eaecf0] rounded-[12px] bg-white">
                    <div className="flex items-center">
                        <div className="flex items-center">
                            <h2 className="text-[#101828] text-[18px] font-semibold mr-3 p-6">
                                {t("Performance_report.comments")}
                            </h2>
                            <span className="bg-[#f9f5ff] text-[12px] text-[#6941c6] font-semibold p-[5px] px-2 py-1 rounded-[12px]">
                                {lastPage ?? 0}
                            </span>
                        </div>

                        <div className="flex items-center ml-auto mr-4">
                            <button
                                className={`table ml-auto mr-2 border border-[#d0d5dd] rounded-[8px] text-[#344054] text-[14px] font-semibold drop-shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)] px-3 py-3 ${
                                loading ? "opacity-50 cursor-not-allowed" : ""
                                }`}
                                onClick={exportToExcel}
                                disabled={loading}
                            >
                                {loading ? "Exporting..." : "Exporter Comments"}
                            </button>
                        </div>
                    </div>

                    <div className="box_comments">

                        
                        <div className="list_filter flex items-center mx-4 pb-5">
                            <button
                            className={`text-[14px] font-semibold mr-2 ${
                                sentimentFilter === "All"
                                ? "active text-[#6941c6]"
                                : "text-[#667085]"
                            }`}
                            onClick={() => handleFilterClick("All")}
                            >
                            All
                            </button>
                            <button
                            className={`flex items-center ml-5 ${
                                sentimentFilter === "Positive" ? "active" : ""
                            }`}
                            onClick={() => handleFilterClick("Positive")}
                            >
                            <span
                                className={`text-[14px] font-semibold mr-2 ${
                                sentimentFilter === "Positive"
                                    ? "active text-[#027a48]"
                                    : "text-[#667085]"
                                }`}
                            >
                                Positive
                            </span>
                            <div
                                className={`text-[12px] font-semibold p-[5px] px-2 py-1 rounded-[12px] ${
                                sentimentFilter === "Positive"
                                    ? "active bg-[#ecfdf3] text-[#027a48]"
                                    : "bg-[#f2f4f7] text-[#344054]"
                                }`}
                            >
                                {listSentiments.postive ?? 0}
                            </div>
                            </button>
                            <button
                            className={`flex items-center ml-5 ${
                                sentimentFilter === "Negative" ? "active" : ""
                            }`}
                            onClick={() => handleFilterClick("Negative")}
                            >
                            <span
                                className={`text-[14px] font-semibold mr-2 ${
                                sentimentFilter === "Negative"
                                    ? "active text-[#d92d20]"
                                    : "text-[#667085]"
                                }`}
                            >
                                Negative
                            </span>
                            <div
                                className={`text-[12px] font-semibold p-[5px] px-2 py-1 rounded-[12px] ${
                                sentimentFilter === "Negative"
                                    ? "active bg-[#fef3f2] text-[#d92d20]"
                                    : "bg-[#f2f4f7] text-[#344054]"
                                }`}
                            >
                                {listSentiments.negative ?? 0}
                            </div>
                            </button>
                            <button
                            className={`flex items-center ml-5 ${
                                sentimentFilter === "Neutral" ? "active" : ""
                            }`}
                            onClick={() => handleFilterClick("Neutral")}
                            >
                            <span
                                className={`text-[14px] font-semibold mr-2 ${
                                sentimentFilter === "Neutral"
                                    ? "active text-[#6172f3]"
                                    : "text-[#667085]"
                                }`}
                            >
                                Neutral
                            </span>
                            <div
                                className={`text-[12px] font-semibold p-[5px] px-2 py-1 rounded-[12px] ${
                                sentimentFilter === "Neutral"
                                    ? "active bg-[#eef4ff] text-[#667085]"
                                    : "bg-[#f2f4f7] text-[#344054]"
                                }`}
                            >
                                {listSentiments.neutre ?? 0}
                            </div>
                            </button>
                        </div>

                        <div className="reponse_post">
                            {comment_list.length > 0 &&
                                comment_list.map((comment, index) => {
                                    return (
                                        <div key={index} className="box_rep">
                                            <div
                                                data-sentiment={comment.sentiment}
                                                className={`header_rep box_comment px-9 py-4 border-t-2 border-[#eaecf0]`}
                                            >
                                                <div className="flex items-center">
                                                    <div className="flex items-center">
                                                        <div>
                                                            <div>
                                                                <span className="block font-medium text-black text-[14px] text-[#344054]">
                                                                    {comment?.from_name}
                                                                    {comment?.id}
                                                                </span>
                                                            </div>
                                                            <span className="block text-sm text-gray-500 dark:text-gray-400 font-light leading-snug">
                                                                {new Date(comment?.creation_time).toDateString()}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {/* sentiment */}

                                                    {/* sentiment Positive */}
                                                    {comment?.sentiment === "Positive" && (
                                                        <div className="table ml-auto bg-[#ecfdf3] px-2 py-1 rounded-[16px]">
                                                            <div className="flex items-center">
                                                                <div className="bg-[#12b76a] w-2 h-2 rounded-full mr-1"></div>
                                                                <span className="text-[#027a48] text-[12px] font-semibold">
                                                                    {comment?.sentiment}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {/* sentiment Negative */}
                                                    {comment?.sentiment === "Negative" && (
                                                        <div className="table ml-auto bg-[#fef3f2] px-2 py-1 rounded-[16px]">
                                                            <div className="flex items-center">
                                                                <div className="bg-[#f04438] w-2 h-2 rounded-full mr-1"></div>
                                                                <span className="text-[#d92d20] text-[12px] font-semibold">
                                                                    {comment?.sentiment}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {/* sentiment Neutral */}
                                                    {comment?.sentiment === "Neutral" && (
                                                        <div className="table ml-auto bg-[#eef4ff] px-2 py-1 rounded-[16px]">
                                                            <div className="flex items-center">
                                                                <div className="bg-[#6172f3] w-2 h-2 rounded-full mr-1"></div>
                                                                <span className="text-[#3538cd] text-[12px] font-semibold">
                                                                    {comment?.sentiment}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <p className={`text-[14px] text-[#475467] py-4 ${isArabic(comment?.message) ? "word_arabic text-left" : ""}`}>
                                                    {comment?.message}
                                                </p>
                                                {comment.attachment && (
                                                    <img className="pb-4" src={comment.attachment} alt="" />
                                                )}
                                            </div>

                                            {/* Afficher les enfants si existent */}
                                            {comment.enfants &&
                                                comment.enfants.length > 0 &&
                                                comment.enfants.map((child, childIndex) => {
                                                    return (
                                                        <div key={childIndex} className="box_rep pl-5 bg-[#f2f4f7]">
                                                            <div
                                                                data-sentiment={child.sentiment}
                                                                className={`header_rep box_comment px-9 py-4 border-t-2 border-[#eaecf0]`}
                                                            >
                                                                <div className="flex items-center">
                                                                    <div className="flex items-center">
                                                                        <div>
                                                                            <div>
                                                                                <span className="block font-medium text-black text-[14px] text-[#344054]">
                                                                                    {child?.from_name}
                                                                                </span>
                                                                            </div>
                                                                            <span className="block text-sm text-gray-500 dark:text-gray-400 font-light leading-snug">
                                                                                {new Date(child?.creation_time).toDateString()}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    {/* sentiment */}

                                                                    {/* sentiment Positive */}
                                                                    {child?.sentiment === "Positive" && (
                                                                        <div className="table ml-auto bg-[#ecfdf3] px-2 py-1 rounded-[16px]">
                                                                            <div className="flex items-center">
                                                                                <div className="bg-[#12b76a] w-2 h-2 rounded-full mr-1"></div>
                                                                                <span className="text-[#027a48] text-[12px] font-semibold">
                                                                                    {child?.sentiment}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    {/* sentiment Negative */}
                                                                    {child?.sentiment === "Negative" && (
                                                                        <div className="table ml-auto bg-[#fef3f2] px-2 py-1 rounded-[16px]">
                                                                            <div className="flex items-center">
                                                                                <div className="bg-[#f04438] w-2 h-2 rounded-full mr-1"></div>
                                                                                <span className="text-[#d92d20] text-[12px] font-semibold">
                                                                                    {child?.sentiment}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    {/* sentiment Neutral */}
                                                                    {child?.sentiment === "Neutral" && (
                                                                        <div className="table ml-auto bg-[#eef4ff] px-2 py-1 rounded-[16px]">
                                                                            <div className="flex items-center">
                                                                                <div className="bg-[#6172f3] w-2 h-2 rounded-full mr-1"></div>
                                                                                <span className="text-[#3538cd] text-[12px] font-semibold">
                                                                                    {child?.sentiment}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <p className={`text-[14px] text-[#475467] py-4 ${isArabic(child?.message) ? "word_arabic text-left" : ""}`}>
                                                                    {child?.message}
                                                                </p>
                                                                {child.attachment && (
                                                                    <img className="pb-4" src={child.attachment} alt="" />
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
            {comment_list.length > 0 &&
            <div className="flex justify-center py-5 mt-5 ml-8 border border-[#eaecf0] rounded-[12px] bg-white">
                <Pagination
                    onPageChange={handlePageChange}
                    totalCount={lastPage}
                    currentPage={paginatePage}
                    pageSize={5} // ajustez la taille de la page selon vos besoins
                    className="custom-pagination-class"
                />
            </div>
            }
        </div>
    );
};

export default TendanceDetail;
