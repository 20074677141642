import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import dayjs from "dayjs";
import { VirtualScroller } from "primereact/virtualscroller";

import { AppContext, Loader } from "../../utils";
import API from "../../utils/Api";

import Skeleton from "./Skeleton";

import dropdownIcon from "../../images/dropdown1.png";
import { ReactComponent as CalendarIcon } from "../../images/images/calendar.svg";
import { ReactComponent as CalendarArrow } from "../../images/images/calendarArrow.svg";

const BenchmarkPosts = () => {

  const [status, setStatus] = useState("loading");
  const [state, setState] = React.useContext(AppContext);
  const { t, i18n } = useTranslation();
  let currentPage = state.currentPage || {};
  const [posts, setPosts] = useState();

  const toast = useRef(null);

  const [dates2, setDates2] = useState(
    state.reportingPeriod || [
      dayjs().subtract(1, "year").toDate(),
      dayjs().toDate(),
    ]
  );

  const dataBench = state.currentKpis.dataBench.total;
  const to = dayjs(state.reportingPeriod[1]).format("YYYY-MM-DD 23:59:59");
  const from = dayjs(state.reportingPeriod[0]).format("YYYY-MM-DD 00:00:00");

  let pageNames = dataBench.map((item) => item.page_name);

  const loadPosts = async () => {
    try {
      setStatus("loading");
      const res = await API.pages.getSectorPostsApi(dataBench, from, to);
      setPosts(res.data.data_result);
      setStatus("");
    } catch (e) {
      console.log(e);
      toast.error("Somethong went wrong, please try again.");
    }
  };

  useEffect(() => {
    loadPosts();
  }, [state.reportingPeriod]);

  let code_view = "total_video_views";
  if (currentPage.social_type == "instagram") code_view = "views";

  const sortCriteriasOptions = [
    { name: t("Post_Library.Les plus récents"), code: "mdate", asc: false },
    { name: t("Post_Library.Les plus partagés"), code: "share", asc: false },
    { name: t("Post_Library.top_commented"), code: "comments", asc: false },
    { name: t("Post_Library.top_clicked"), code: "reactions", asc: false },

    { name: t("Post_Library.Least_Recent"), code: "mdate", asc: true },
    { name: t("Post_Library.Les moins partagés"), code: "share", asc: true },
    {
      name: t("Post_Library.Les moins commentés"),
      code: "comments",
      asc: true,
    },
    { name: t("Post_Library.Les moins likés"), code: "reactions", asc: true },
  ];

  // Fonction pour nettoyer les noms de page et obtenir les codes correspondants
  function cleanCode(pageName) {
    if (typeof pageName !== "string") {
      return ""; // Si pageName n'est pas une chaîne, retourner une chaîne vide
    }
    // Supprimer les caractères spéciaux et convertir en minuscules
    let cleaned = pageName
      .trim()
      .toLowerCase()
      .replace(/[^\w\s]/gi, "");
    // Remplacer les espaces par des tirets
    cleaned = cleaned.replace(/\s+/g, "-");
    return cleaned;
  }

  // Utiliser map pour créer un tableau d'objets à partir des noms de page
  const filterTypeOptions = [{ name: t("Benchmark.marque"), code: "" }];
  pageNames.forEach((pageName) => {
    filterTypeOptions.push({ name: pageName, code: cleanCode(pageName) });
  });

  const [filterStatusType, setfilterStatusType] = useState(
    filterTypeOptions[0]
  );

  const onFilterTypeChange = (e) => {
    setfilterStatusType(e.value);
  };

  const filterCriteriasOptions = [
    { name: t("advanced_search.media_type"), code: "" },
    { name: "Photos", code: "photo" },
    { name: "Vidéos", code: "video" },
    { name: "Autres", code: "other" },
  ];

  const [sortCriteria, setSortCriteria] = useState(sortCriteriasOptions[0]);
  const [filterCriteria, setFilterCriteria] = useState(
    filterCriteriasOptions[0]
  );
  const onSortChange = (e) => {
    console.log(e);
    setSortCriteria(e.value);
  };

  const onFilterChange = (e) => {
    setFilterCriteria(e.value);
  };

  const postsToRender = (posts || []).filter((el) => {
    // Fonction pour le filtre de marque
    const statusTypeFilter = () => {
      if (!filterStatusType || !filterStatusType.code) return true;

      return el.name === filterStatusType.name;
    };

    // Fonction pour le filtre de critères
    const criteriaFilter = () => {
      if (!filterCriteria || !filterCriteria.code) return true;
      switch (filterCriteria.code) {
        case "photo":
          return el.type == "photo"
            ? el.type == "photo"
            : el.status_type == "added_photos" || el.type == "IMAGE";
        case "video":
          return el.type == "video"
            ? el.type == "video"
            : el.status_type == "added_video" || el.type == "VIDEO";
        case "other":
          return (
            (el.status_type != "added_photos" || el.type != "IMAGE") &&
            (el.status_type != "added_video" || el.type != "VIDEO")
          );
        default:
          if (el.postType) {
            return el.type.toLowerCase() == filterCriteria.code;
          } else {
            return (
              (el.status_type != "added_photos" || el.type != "IMAGE") &&
              (el.status_type != "added_video" || el.type != "VIDEO")
            );
          }
      }
    };

    // Appliquer les filtres
    return statusTypeFilter() && criteriaFilter();
  });

  // Nombre de messages à afficher
  const numberOfPosts = postsToRender.length;

  const sortedPosts = postsToRender.sort((a, b) => {
    if (!sortCriteria || !sortCriteria.code) return 0;
    let c = a[sortCriteria.code] > b[sortCriteria.code] ? -1 : 1;
    if (sortCriteria.code == "mdate") {
      let date_a = new Date(a.creation_time);
      let date_b = new Date(b.creation_time);
      //c = a[sortCriteria.code] > b[sortCriteria.code] ? 1 : -1;
      c = date_a > date_b ? 1 : -1;
    }
    if (sortCriteria.code == "comments") {
      let comment_a = +a.comments_count;
      let comment_b = +b.comments_count;
      c = comment_a > comment_b ? 1 : -1;
    }
    if (sortCriteria.code == "share") {
      let share_a = +a.shares_count;
      let share_b = +b.shares_count;
      c = share_a > share_b ? 1 : -1;
    }

    if (sortCriteria.code == "reactions") {
      let reactions_a =
        +a.likes_count +
        +a.wow_count +
        +a.sad_count +
        +a.haha_count +
        +a.angry_count +
        +a.none_count +
        +a.love_count +
        +a.thankful_count;
      let reactions_b =
        +b.likes_count +
        +b.wow_count +
        +b.sad_count +
        +b.haha_count +
        +b.angry_count +
        +b.none_count +
        +b.love_count +
        +b.thankful_count;
      c = reactions_a > reactions_b ? 1 : -1;
    }

    return c * (sortCriteria.asc ? 1 : -1);
  });

  let changePostAttribute = (postId, attribute, value) => {
    let idx = posts.findIndex((el) => el.id == postId);
    if (!attribute) posts[idx] = value;
    else posts[idx][attribute] = value;
    setPosts(Object.assign([], posts));
    if (attribute == "status" && value == "error") {
    }
  };

  const tpl = (post) => {
    //-- url
    let url_post = "";
    if (currentPage.social_type == "facebook") {
      url_post = "https://www.facebook.com/" + post.social_id;
    } else if (currentPage.social_type == "instagram") {
      url_post = post.url;
    }

    let show_data = true;
    if (currentPage.social_type == "facebook" && post.type == "storie") {
      show_data = false;
    } else if (
      currentPage.social_type == "instagram" &&
      post.status_type == "STORY"
    ) {
      show_data = false;
    }

    const shares_count = post?.shares_count;
    const comments_count = post?.comments_count;
    const reaction_post =
      +post?.angry_count +
      +post?.haha_count +
      +post?.likes_count +
      +post?.love_count +
      +post?.none_count +
      +post?.sad_count +
      +post?.thankful_count +
      +post?.wow_count;
    const interaction_post = +shares_count + +comments_count + +reaction_post;
    const pageFans =
      dataBench.find((item) => item.page_name === post.name)?.page_fans || 0;
    const interaction_per_kfans = (
      (+interaction_post / +pageFans) *
      1000
    ).toFixed(2);
    return (
      <div className="flex flex-row justify-center items-center mb-4 relative">
        <div
          key={post.id}
          data-id={"dig-p-" + post.id}
          className="bg-white shadow rounded-lg w-72 mt-8 mb-4"
        >
          <div className="h-32">
            <div className="flex items-center mb-2 px-4 pt-4">
              <div className="flex items-center">
                <img className="w-12 h-12 rounded-full" src={post?.logo} />
                <div className="ml-2 mt-0.5">
                  <a href={url_post} target="_blank">
                    <span className="block font-medium text-base leading-snug text-black ">
                      {post?.name}
                    </span>
                  </a>
                  <span className="block text-sm text-gray-500 dark:text-gray-400 font-light leading-snug">
                    {new Date(post.creation_time).toDateString()}
                  </span>
                </div>
              </div>
            </div>
            <p className="text-asap-blue leading-snug md:leading-normal text-xs px-4 line-clamp-3 ">
              {post.message}
            </p>
          </div>

          <Skeleton
            post={post}
            onLoad={() => changePostAttribute(post.id, "status", "loaded")}
            onError={() => changePostAttribute(post.id, "status", "error")}
          />

          {show_data && (
            <div>
              <div className="relative">
                <div className="grid grid-cols-3  gap-4 mx-auto mb-4 mx-4">
                  <div
                    className={`flex items-center gap-2 ${
                      currentPage.social_type === "facebook" ? "" : ""
                    }`}
                  >
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {interaction_post}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        Interactions
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>
                  <div
                    className={`flex items-center gap-2 ${
                      currentPage.social_type === "facebook" ? "" : ""
                    }`}
                  >
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {reaction_post}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        Reactions
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>

                  <div
                    className={`flex items-center gap-2 ${
                      currentPage.social_type === "facebook" ? "" : ""
                    }`}
                  >
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {comments_count}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        Comments
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>

                  <div
                    className={`flex items-center gap-2 ${
                      currentPage.social_type === "facebook" ? "" : ""
                    }`}
                  >
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {shares_count}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        Shares
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>
                  <div
                    className={`flex items-center gap-2 ${
                      currentPage.social_type === "facebook" ? "" : ""
                    }`}
                  >
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {interaction_per_kfans}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        Int. per 1K Fans
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {post.status_type == "STORY" && post.social_type == "instagram" && (
            <div>
              <div className="relative">
                <div className="grid grid-cols-3  gap-4 mx-auto mb-4 mx-4">
                  <div
                    className={`flex items-center gap-2 ${
                      currentPage.social_type === "facebook"
                        ? "bg-[#f2f4f7]"
                        : ""
                    }`}
                  >
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold"></h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        Interactions
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  if (status == "loading") return <Loader />;

  return (
    <div className="">
      <div className="justify-between flex mt-4 mx-6 items-center text-sm 2xl:text-base">
        {/* calendar */}
        <div className="flex items-center gap-6">
          <div className="flex bg-white border-gray-300  border  items-center rounded font-CityOfEmber   calendar-navbar w-72  ">
            <label
              htmlFor="date_range"
              className="cursor-pointer pl-6 dark:text-asap-white text-asap-blue"
            >
              <CalendarIcon />
            </label>
            <Calendar
              inputId="date_range"
              dateFormat="yy/mm/dd"
              panelClassName="mt-6 bg-asap-gray-900 p-2 font-CityOfEmber text-gray-900 font-bold  "
              className="font-CityOfEmber text-gray-900 font-bold "
              inputClassName=" ml-2 cursor-pointer   outline-none  text-asap-blue text-sm text-gray-900 font-semibold "
              id="range"
              value={dates2}
              onChange={(e) => {
                setDates2(e.value);
                if (e.value[0] != null && e.value[1] != null)
                  setState({ ...state, reportingPeriod: e.value });
              }}
              selectionMode="range"
              readOnlyInput
              numberOfMonths={1}
            />
            <label
              htmlFor="date_range"
              className="cursor-pointer pl-2 cursor-pointer dark:text-asap-white text-asap-blue"
            >
              <CalendarArrow />
            </label>
          </div>
          <div className="flex gap-2">
            <span>{t("Performance_report.period")}</span>
            {dates2[0] && (
              <div className="flex gap-2">
                <span>{dates2[0]?.toDateString()}</span> -
                <span>{dates2[1]?.toDateString()}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* selectbar */}
      <div className="flex items-center justify-between  mt-8">
        <div className="flex items-center">
          <h1 className="text-2xl float-left font-bold ml-4 ">
            Sector Posts Library
          </h1>
          <span className="ml-5 bg-[#EAECF0] text-[12px] text-[#344054] font-semibold p-[5px] px-2 py-1 rounded-[16px]">
            {numberOfPosts}
          </span>
        </div>

        <div className=" float-right   mr-8 flex gap-2  ">
          <div className="flex items-center border bg-white rounded border-gray-300 h-10">
            <img className="w-6 h-6 ml-4" src={dropdownIcon} alt="" />
            <div>
              <Dropdown
                className=" float-right text-gray-800 "
                value={filterStatusType}
                options={filterTypeOptions}
                onChange={onFilterTypeChange}
                optionLabel="name"
                placeholder={t("Benchmark.marque")}
              />
            </div>
          </div>
          <div className="flex items-center border bg-white rounded border-gray-300 h-10">
            <img className="w-6 h-6 ml-4" src={dropdownIcon} alt="" />
            <div>
              <Dropdown
                className=" float-right text-gray-800 "
                value={filterCriteria}
                options={filterCriteriasOptions}
                onChange={onFilterChange}
                optionLabel="name"
                placeholder={t("advanced_search.media_type")}
              />
            </div>
          </div>
          <div className="flex items-center border bg-white rounded border-gray-300 h-10">
            <img className="w-6 h-6 ml-4" src={dropdownIcon} alt="" />
            <div>
              <Dropdown
                className="float-right text-gray-800 "
                value={sortCriteria}
                options={sortCriteriasOptions}
                onChange={onSortChange}
                optionLabel="name"
                placeholder={t("Post_Library.Sorting_Criteria")}
              />
            </div>
          </div>
        </div>
      </div>

      <VirtualScroller
        className="w-full  mx-auto mt-4 mb-16"
        style={{ height: 1900 }}
        items={postsToRender}
        itemSize={100}
        itemTemplate={tpl}
      />
    </div>
  );
};

export default BenchmarkPosts;
