import { API } from "../utils";
import React, { useState, useEffect } from "react";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import dayjs from "dayjs";

const MotCle = (props) => {
    console.log('MotCle props ==>', props);

    const user_id = props?.userData?.id;
    const page_id = props?.currentPage?.page_id;
    const currentPage_id = props?.currentPage?.id;

    const [listKeys, setListKeys] = useState([]);

    // Fonction pour récupérer les données des messages
    const fetchPosts = async () => {
        try {
            const response = await API.pages.getPosts(currentPage_id, { 
                user_id, 
                from: dayjs(props.reportingPeriod[0]).format("YYYY-MM-DD"),
                to: dayjs(props.reportingPeriod[1]).format("YYYY-MM-DD"),
            });
            console.log('res.data?.data =>', response.data);
            setListKeys(response.data.all_keys); // Assurez-vous que c'est le bon chemin pour les données des messages dans la réponse
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        // Appelez la fonction pour récupérer les données des messages lorsque le composant est monté
        fetchPosts();
    }, []);

    // Une fois que vous avez les données de listKeys, nous allons extraire les mots-clés
    useEffect(() => {
        if (listKeys.length > 0) {
            // Utilisez la méthode map pour extraire les mots-clés
            const motsClesFromListPosts = listKeys.map(post => ({
                motcle: post.mot_cle, // Assurez-vous que le nom de la propriété est correct
            }));

            // Mettez à jour les champs d'entrée avec les mots-clés extraits
            setInputFields(motsClesFromListPosts);
        }
    }, [listKeys]);

    const [inputFields, setInputFields] = useState([{
        motcle: '',
    }]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const addInputField = () => {
        setInputFields([...inputFields, {
            motcle: '',
        }]);
    }

    /*const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }*/

    const removeInputFields = (index) => {
        const rows = [...inputFields];
        
        if (rows.length === 1) {
            rows[index].motcle = ''; // Réinitialisez la valeur de l'input à une chaîne vide
        } else {
            rows.splice(index, 1); // Supprimez complètement le champ d'entrée
        }

        setInputFields(rows);
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }

    const handleSubmit = async () => {
        // Check if all input fields are not empty
        const allFieldsFilled = inputFields.every(data => data.motcle !== '');

        if (allFieldsFilled) {
            setIsFormSubmitted(true);
            const motcleValues = inputFields.map(data => data.motcle);
            console.log('motcleValues =>', motcleValues);
            
            // Appel à sendMotCle avec la liste des mots-clés
            try {
                const response = await API.pages.sendMotCle(page_id, user_id, motcleValues);
                console.log(response.data); 
            } catch (error) {
                console.error(error);
            }

        } else {
            alert("Veuillez remplir tous les champs d'entrée avant de soumettre.");
        }

    }

    console.log('listKeys =>', listKeys);

    return (
        <div>
            {
                inputFields.map((data, index) => {
                    const { motcle } = data;
                    return (
                        <div key={index}>
                            <InputText
                                className={isFormSubmitted && motcle === '' ? 'p-invalid' : ''}
                                onChange={(evnt) => handleChange(index, evnt)}
                                value={motcle}
                                name="motcle"
                                placeholder="Mot-clé"
                            />
                            {(inputFields.length !== 1 || motcle) && ( 
                                <Button label="x" onClick={() => removeInputFields(index)} />
                            )}
                        </div>
                    )
                })
            }
            {inputFields.length > 0 && (
                <Button label="Nouveau Mot-clé" onClick={addInputField} />
            )}
            <Button label="Submit" onClick={handleSubmit} />

            {listKeys.length > 0 && listKeys.map((listKey, index) => {
                return (
                    <div key={index} className="table mt-5">
                        <div className="flex">
                            <div className="text-[14px] font-semibold mr-2 text-[#667085]">{listKey.mot_cle}</div>
                            <div className="text-[12px] font-semibold p-[5px] px-2 py-1 rounded-[12px] bg-[#f2f4f7] text-[#344054]">{listKey.comments_count}</div>
                        </div>
                    </div>
                );
            })}

        </div>
    )
};

export default MotCle;
