import React, { useEffect } from "react";
import * as dayjs from "dayjs";
import Area from "./Area";
import Bar from "./Bar";
import Line from "./Line";
import Pie from "./Pie";
import Table from "./Table";
import CrAuLineChart from "./CrAuLineChart";
import Map from "./Map";
import Column from "./Column";
import SpLine from "./SpLine";
import PRColumnChart from "../Reporting/PRColumnChart";
import TableCombined from "../Reporting/TableCombined";
//import SpLine1 from "./SpLine1";

import millify from "millify";
import { AppContext, SocialIcon, useUtils, getNextColor } from "../../utils";
import StackedBar from "./StackedBar";
import SpLineFans from "./SpLineFans";
import SpLineFansPost from "./SpLineFansPost";

//------- Pie FB --------//
import PieComportement from "./PieComportement";
import PieEngagement from "./PieEngagement";
//------- End Pie FB --------//

import SpLineVideos from "./SpLineVideos";
import SpLineReals from "./SpLineReals";
import SpLineStories from "./SpLineStories";
import SpLineSector from "./SpLineSector";
import PiePosts from "./PiePosts";

//-- tiktok
import PerformanceChart from "./PerformanceChart";

import YoutubePerformanceChart from "./Youtube/YoutubePerformanceChart";
import YoutubeBenchmarkChart from "./Youtube/Benchmark/YoutubeBenchmarkChart";
// import { useTranslation } from "react-i18next";

const Block = ({ children, config }) => {
  // const { t, i18n } = useTranslation();

  let ti = config.title && (
    <h1 className="bg-white mt-4 font-bold ">{config.title}</h1>
  );
  let c = "contenu_reporting";
  if (config.cmp && config.cmp.length && !config.fullWidth) c += "";
  return (
    <>
      {/* {ti} */}
      <div className={c}>{children}</div>
    </>
  );
};

const reducers = {
  sum: function (a, b) {
    return a + b;
  },
  max: function (a, b) {
    return a >= b ? a : b;
  },
  min: function (a, b) {
    return a <= b ? a : b;
  },
  avg: function (a, b, index, { length }) {
    return a + b / length;
  },
};

let currentPage = {};

const round = (v) => {
  return parseFloat(v).toFixed(3).replace(".000", "");
};

const cmpData = (config, data) => {
  if (!config || !config.metrics) return null;
  let r = {
    dataKey: config.metrics[0],
    data: {},
  };
  if (config.innerData) {
    r.data = [];
    let innerData = data.total[config.metrics[0]];
    if (typeof innerData == "string") innerData = JSON.parse(innerData);
    if (innerData && typeof innerData == "object")
      Object.keys(innerData).forEach((k) => {
        r.data.push({ name: k, [config.metrics[0]]: innerData[k] });
      });
    //console.log({ config, r, data });
    return r;
  }

  if (config.sector) {
    r.data = [];
    if (typeof data.total != "object" || typeof data.by_day != "object")
      return r;
    let currentBrand = { name: currentPage.name };
    config.metrics.forEach((metric) => {
      currentBrand[metric] = data["total"][metric];
    });
    r.data.push(currentBrand);
    Object.values(data.brands).forEach((brand) => {
      if (brand.id == currentPage.id) return;
      let total =
        (data.in_sector[brand.id] && data.in_sector[brand.id]["total"]) || {};
      let brandEl = { name: brand.name, page_id: brand.id };
      config.metrics.forEach((metric) => {
        brandEl[metric] = total[metric] || 0;
      });
      r.data.push(brandEl);
    });

    r.xAxisProps = {
      sclaeToFit: true,
      interval: 0,
      tick: (el) => {
        let b = Object.values(data.brands).find(
          (e) => e.name == el.payload.value
        );
        return (
          <foreignObject
            x={el.x - 15}
            y={el.y}
            width={el.width}
            height={el.height}
          >
            <div xmlns="http://www.w3.org/1999/xhtml">
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  height: el.height,
                }}
              >
                <img
                  className="rounded-lg"
                  src={b?.picture}
                  style={{ maxWidth: "100%", maxHeight: el.height }}
                />
              </div>
            </div>
          </foreignObject>
        );
      },
    };
    //console.log("sectorData", r);
    return r;
  }
  if (typeof data.by_day != "object") return r;
  if (config.by == "day") {
    Object.keys(data.by_day)
      .sort()
      .forEach((day) => {
        config.metrics.forEach((metric) => {
          r.data[day] = { name: day, [metric]: data.by_day[day][metric] || 0 };
        });
      });
  } else if (config.by == "month") {
    Object.keys(data.by_day)
      .sort()
      .forEach((day) => {
        let m = dayjs(day).format("MMMM YY");
        if (!r.data[m]) r.data[m] = { name: m };
        config.metrics.forEach((metric) => {
          if (!r.data[m][metric]) r.data[m][metric] = [];
          r.data[m][metric].push(data.by_day[day][metric] || 0);
          // else r.data[m][metric] = (r.data[m][metric] || 0) + (data.by_day[day][metric] || 0);
        });
      });
    Object.keys(r.data).forEach((m) => {
      config.metrics.forEach((metric) => {
        r.data[m][metric] = (r.data[m][metric] || []).reduce(
          reducers[config.reducer || "sum"],
          0
        );
      });
    });
  } else {
    config.metrics.forEach((metric) => {
      r.data[metric] = { name: metric, value: data.total[metric] || 0 };
    });
  }
  r.data = Object.values(r.data);
  //console.log("=====config", { config, r, data });
  return r;
};

const Layout = ({ layout, data }) => {
  //console.log("==============>", data);
  const [state, setState] = React.useContext(AppContext);
  currentPage = state.currentPage?.page;
  const cmpRender = (config, key) => {
    let T;
    if (config.type == "column2") T = TableCombined;
    if (config.type == "table") T = Table;
    if (config.type == "spline") T = SpLine;
    if (config.type == "splinef") T = SpLineFans;
    if (config.type == "splinefp") T = SpLineFansPost;
    if (config.type == "comparebar") T = StackedBar;
    // if (config.type == "bar") T = Bar;
    if (config.type == "column") T = PRColumnChart;
    if (config.type == "area") T = Area;
    if (config.type == "Spline_videos") T = SpLineVideos;
    if (config.type == "Spline_reals") T = SpLineReals;
    if (config.type == "Spline_stories") T = SpLineStories;
    if (config.type == "splineS") T = SpLineSector;
    // if (config.type == "spline") T = SpLine;
    if (config.type == "line") T = Line;
    if (config.type == "Pie_posts") T = PiePosts;
    if (config.type == "pie") T = Pie;

    //------- Pie FB --------//
    if (config.type == "pie_comportement") T = PieComportement;
    if (config.type == "pie_engagement") T = PieEngagement;
    //------- End Pie FB --------//
    
    //------- tiktok
    if (config.type == "performance_chart") T = PerformanceChart;
    
    if (config.type == "youtube_charts") T = YoutubePerformanceChart;
    if (config.type == "youtube_bench") T = YoutubeBenchmarkChart;

    // if (config.type == "map") T = Map;
    if (config.type == "block") T = Block;
    //T = Map;
    // console.log(config);
    if (!T) return null;
    let cls = "";
    if (config.type == "block") cls = "";
    return (
      <div className={"w-full " + cls} key={key}>
        <T
          config={config}
          data={data}
          cmpData={cmpData(config, data)}
          onBrushChange={(a, b, c) => {
            //console.log(a, b, c);
          }}
        >
          {config.cmp?.map((el, idx) => cmpRender(el, idx))}
        </T>
      </div>
    );
  };

  // return cmpRender(layout[3]);
  let cmps = layout.map((el, idx) => cmpRender(el, idx));
  return cmps;
};

export { Layout, cmpData };
