import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import axios from "axios";



import {
  Link,
  useHistory,
  useLocation,
  useParams,
  Redirect,
  useNavigate,
} from "react-router-dom";

import Slice1 from "../images/images/Slice1.svg";
import body_login from "../images/my_images/body_login.png";

import { AppContext, API, useUtils, toast } from "../utils";

//import { useNavigate } from "react-router-dom";
const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
        //   setStatus("loading"); 
          await API.pages.forgotPassword( email ); 
        //   setStatus(""); 
          toast.success(t('forgot_password.email_sent')); 
        } catch (error) {
        //   setStatus(""); 
          toast.error(t('forgot_password.email_not_exist')); 
        }
      };
  const { t, i18n } = useTranslation();
  // useEffect(() => {
  //   if (localStorage.getItem("Lang") == "EN") i18n.changeLanguage("en");
  // }, []);

  return (
    <div>
        
      <div className="block_login body_gray">
        <form
          onSubmit={handleSubmit}
          className="align"
          
        >
          <div className="body_login full_width body_blue h-full">
            <img src={body_login} alt="body_login" className="center_el"></img>
          </div>
          {/* <div class="Vector"></div> */}
          
          <div className="form_reset_password form_style full_width">
            
            <div className="logo_flex flex">
              <img src={Slice1} alt="logo_flex" />
              <h1>diggow</h1>
            </div>

            <div className="connect_block">
              <h2>{t("forgot_password.resetpassword")}</h2>
              <p>{t("forgot_password.resetlink")}</p>
            </div>
            <div className="input_login input_style">
              <div className="box_input">
                <label>{t("forgot_password.email")}</label>
                <InputText
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Entrez votre email"
                  required
                  className="w-36"
                />
              </div>
                
              <button
                type="submit"
                className="btn_url"
              >
                {t("forgot_password.continue")}
              </button>
              <a href="/login" className="pwd_forget backlogin back">
                  <p>{t("forgot_password.back_login")}</p>
               </a>
              <Toaster
                toastOptions={{
                  className: "",
                  style: {
                    border: "2px solid #713200",
                    padding: "16px",
                    color: "#596ae9",
                    backgroundColor: "#f4f7fb",
                  },
                }}
              />

            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ForgotPassword;
