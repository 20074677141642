import React from "react";
import EngagementTiktok from './EngagementTiktok';
import PerformanceVideosTiktok from './PerformanceVideosTiktok';
import CroissanceAudienceTiktok from './CroissanceAudienceTiktok';
import CroissanceEngagementTiktok from './CroissanceEngagementTiktok';
import CroissanceAbonnementsAbonnesTiktok from './CroissanceAbonnementsAbonnesTiktok';

const PerformanceChart = (props) => {

  return (
    <div>
      <CroissanceAudienceTiktok state={props} />
      <CroissanceAbonnementsAbonnesTiktok state={props} />
      <EngagementTiktok state={props} />
      <CroissanceEngagementTiktok state={props} />
      <PerformanceVideosTiktok state={props} />
    </div>
  );
};

export default PerformanceChart;
