import React, { useState, useEffect } from "react";

import fbIcon from "../images/images/fbIcon.png";
import instaIcon from "../images/images/instaIcon.png";
import searchIcon from "../images/images/search.png";
import infoModal from "../images/images/infoModal.svg";
import deleteIcon from "../images/images/delete.png";
import checkIcon from "../images/images/check.png";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import AddCategoryModals from "./AddCategoryModals";

import { API, Loader, SocialIcon, toast } from "../utils";

const SocialModals = (props) => {

  let socialResult = props.socialResult;
  const [status, setStatus] = useState();
  const [selection, setSelection] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [categoriesList, setCategoriesList] = useState(null);

  const getCategories = async () => {
    try {
      setStatus("loading");
      const res = await API.categories.all();
      setCategoriesList(res.data?.data);
      console.log('res.data?.data', res.data?.data);
    } catch (e) {
      console.log(e.response);
      props.onGetCategoriesError(e.response);
    }
    setStatus(null);
  };

  const selectPage = (id, selected, category_id) => {
    let s = { ...selection };
    if (selected !== null) {
      if (selected) s[id] = 0;
      else delete s[id];
    }
    if (category_id !== null) s[id] = category_id;
    setSelection(s);
  };

  const savePages = async () => {
    if (!Object.keys(selection).length) {
      toast.error("Veuillez sélectionner au moins une page");
      return;
    }
    if (Object.values(selection).filter((el) => el == 0).length) {
      toast.error("Veuillez spécifier les catégories des pages");
      return;
    }
    setStatus("saving");
    API.run(async () => {
      let scId = await API.socialAccounts.put(props.socialResult.data);
      let s = {};
      Object.keys(selection).map((k) => (s[k] = selection[k]?.id));
      await API.socialAccounts.storePages(scId.data?.data?.id, s);
      //await API.socialAccounts.storePages(scId.data?.data?.id, [], {
      //  [s]: true,
      //});
      console.log("add =>", scId.data?.data?.id, s);
      setStatus("");
      props.onSave && props.onSave();
    }, setStatus);
  };

  let pages = socialResult?.pages.filter((page) =>
    page.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
  );
  useEffect(() => {
    getCategories();
  }, []);

  //console.log("selection", socialResult);
  if (!socialResult) return null;

  const cnt = () => {
    if (status == "loading") return <Loader />;
    return (
      <>
        {/* header */}
        <div className="username_page">
          <SocialIcon type={socialResult.social_type} />
          <div className="content_username_page">
            <h1>
              Vous êtes connecté en tant que <span>{socialResult.name} !</span>
            </h1>

            <p>
              "Sélectionnez la page que vous souhaitez gérer avec ce profil."
              <br />
              Vous pouvez ajoutez jusqu'à 1 page.
            </p>
          </div>
        </div>
        <div className="search_page">
          {/* <img className="w-5 h-5" src={searchIcon} alt="" /> */}
          <input
            type="text"
            className="btn_url_cover"
            placeholder="Search page ..."
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              backgroundColor: "rgba(0,0,0,0.0)",
            }}
          />
        </div>

        {/* body */}

        <div className="list_page_for_choices">
          <div className="flex flex-col  justify-center  justify-items-center items-center">
            {pages?.map((page) => (
              <div
                key={page.id}
                className="flex justify-center justify-items-center items-center flex-col md:flex-row mt-14   md:ml-2  "
              >
                <div className="flex items-center ">
                  <img
                    className="w-12 h-12 md:mr-20 rounded-full"
                    src={page.picture}
                    alt=""
                  />
                  <span>
                    <SocialIcon
                      type={page.social_type}
                      className="rounded-full w-4 h-4 ml-4 md:ml-0"
                    />
                  </span>

                  <h1 className=" text-asap-blue w-36 font-Montserrat font-bold text-xs text-center md:text-left  md:pl-5">
                    {page.name}
                  </h1>
                </div>
                {selection[page.social_id] === undefined &&
                  selection[page.social_id] === undefined && (
                    <div className="flex flex-col md:flex-row items-center float-right relative ">
                      <button
                        onClick={() => selectPage(page.social_id, true, null)}
                        type="text"
                        className="btn_url_cover"
                      >
                        Sélectionner
                      </button>
                    </div>
                  )}
                {selection[page.social_id] !== undefined && (
                  <div className="flex gap-6  flex-row md:ml-2 items-center  mt-6 md:mt-0 md:border-0 border py-2 px-3 border-gray-300 rounded-full ">
                    <div className="flex">
                      <img className="w-7 h-7" src={checkIcon} alt="" />
                      <h1 className="text-asap-blue  font-Montserrat font-bold text-xs mt-1 ml-4">
                        Page ajoutée
                        <img
                          onClick={() => selectPage(page.social_id, false)}
                          className="w-5 h-5  ml-7 cursor-pointer inline-block"
                          src={deleteIcon}
                          alt=""
                        />
                      </h1>
                    </div>
                    <div className="max-w-xs   md:mt-0  text-xs  float-righ  ">
                      <Dropdown
                        filter
                        className=" ml-2 outline-none rounded-xl"
                        placeholder="Category"
                        options={categoriesList}
                        value={selection[page.social_id]}
                        optionLabel="name"
                        onChange={(e) => {
                          selectPage(page.social_id, null, e.value);
                        }}
                      />
                    </div>

                    {/* <button className="rounded-full text-sm underline text-diggow-blue px-6 py-1 hover:opacity-80 ">
                    {t("Editer categorie")}
                    </button> */}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {/* footer */}
        {/*  info */}

        <div className="flex mt-14  items-center justify-center  gap-10">
          <div className="flex items-center">
            <img className="text-medium max-h-6 mt-1" src={infoModal} alt="" />
            <p className="text-asap-purple text-xs ml-4 font-Montserrat font-medium">
              Vous avez besoin d'aide ? <br />
              N'hésitez pas à nous contacter pour une assistance !
            </p>
          </div>
          <button
            onClick={() => setStatus("addCategory")}
            className="rounded-full   text-asap-blue underline text-sm px-8 hover:opacity-80 py-3 hide_el"
          >
            Ajouter une categorie
          </button>
          <AddCategoryModals
            visible={status == "addCategory"}
            onHide={() => setStatus("")}
            // onClick={() => setStatus("selectingPages")}
            onGetCateg={() => getCategories()}
          />
        </div>

        <div className="justify-center flex">
          <Button
            onClick={() => savePages()}
            iconPos="right"
            // label={t("Confirmer")}
            loading={status == "saving"}
            className="btn_url specific_style_btn"
          >
            Confirmer
          </Button>
        </div>
      </>
    );
  };

  return (
    <Dialog
      breakpoints={{ "1460px": "95vw" }}
      style={{ width: "55vw" }}
      className={
        "text-asap-orange font-thin bg-asap-lowWhite text-xl font-Montserrat  rounded-3xl p-3   h-screen no-background-dialog"
      }
      {...props}
    >
      {cnt()}
    </Dialog>
  );
};

export default SocialModals;
