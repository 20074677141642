import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import body_register from "../images/my_images/body_register.png";
import Slice1 from "../images/images/Slice1.svg";
import FacebookColor from "../images/images/FacebookColor.svg";
import fbIcon from "../images/images/fbIcon.png";
import instaIcon from "../images/images/instaIcon.png";
import linkedinIcon from "../images/images/linkedinIcon.png";
import twitterIcon from "../images/images/twitterIcon.png";
import InstagramColor from "../images/images/InstagramColor.svg";
import linkedin from "../images/my_images/linkedin.png";
import Pluscircle from "../images/images/Pluscircle.svg";
import { AppContext, API, useUtils, Loader, toast } from "../utils";
import deleteIcon from "../images/images/delete.png";
import youtubeIcon from "../images/images/sm/youtube.svg";
import { confirmPopup } from "primereact/confirmpopup";
import SocialModals from "./SocialModals";
import SuccessModal from "./SuccessModals";
import { ConfirmPopup } from "primereact/confirmpopup"; // To use <ConfirmPopup> tag
//import { confirmPopup } from "primereact/confirmpopup"; // To use confirmPopup method
import Popup from "reactjs-popup";
import videoReact from "../images/images/videoReact.mp4";

let msgListener = null;
const SetupPages = () => {
  const [state, setState] = React.useContext(AppContext);
  // const { t, i18n } = useTranslation();
  const [socialResult, setSocialResult] = useState(null);
  const [status, setStatus] = useState("loading");
  const [pages, setPages] = useState(state?.pages);
  const navigate = useNavigate();

  const utils = useUtils();
  const [isOpen, setIsOpen] = useState(false);

  const loadPages = (s) => {
    setStatus(s || "loading");
    return API.run(async () => {
      let p = await API.pages.all();
      p = p.data?.data && p.data?.data.length ? p.data?.data : null;
      setPages(p);
      setState((os) => ({ ...os, pages: p }));
    }, setStatus);
  };

  const addSocialLink = (provider) => {
    let popup = window.open(
      API.socialAccounts.redirection(provider),
      "Diggow Social Link",
      "width=580,height=700"
    );
    if (msgListener) {
      window.removeEventListener("message", msgListener);
    }
    msgListener = (event) => {
      // console.log("window.addEventListener", event.data);
      window.removeEventListener("message", msgListener);
      msgListener = null;
      event.data.social_type = provider;
      event.data.pages = (event.data.pages ?? []).filter(
        (el) => el.social_type == provider
      );
      if (!event.data.pages.length) {
        toast(
          "Aucune liaison trouvée avec ce compte. Vérifiez que vous disposez des droits nécessaires."
        );
        return;
      }
      setSocialResult(event.data);
      setStatus("selectingPages");
    };
    window.addEventListener("message", msgListener);
    popup.focus();
  };

  const onSavePages = () => {
    loadPages("saving");
  };

  const delPage = (social_account_id, social_id, event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Sûr(e) de vouloir supprimer ?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Non",
      accept: () => {
        setStatus("deleting");
        API.run(async () => {
          await API.socialAccounts.storePages(social_account_id, [], {
            [social_id]: true,
          });
          await loadPages("deleting");
        }, setStatus);
      },
    });
  };

  const showPages = () => {
    if (pages == null) return;
    let pagesByType = {};
    pages.forEach(
      (el) =>
        (pagesByType[el.social_type] = [
          ...(pagesByType[el.social_type] ?? []),
          el,
        ])
    );
    return (
      <div className="list_pages_choisie">
        <div className="block_list_page">
          {/* facebbok */}
          {pagesByType["facebook"] && pagesByType["facebook"].length && (
            <>
              <h2>
                Liste des profils sociaux
                <br />
                que vous pouvez gérer.
              </h2>
              <div className="align name_block_list_page">
                <img src={fbIcon} alt="" />
                <h3>Pages Facebook ajoutées</h3>
              </div>

              <div className="box_list_pages">
                {pagesByType["facebook"].map((el) => (
                  <div key={el.id} className="align style_page">
                    <img className="icon_page" src={el.page.picture} alt="" />

                    <div className="name_page full_width align">
                      <h4>{el.page.name}</h4>
                      <img
                        className="cursor-pointer right_el"
                        src={deleteIcon}
                        alt=""
                        onClick={(event) =>
                          delPage(el.social_account_id, el.social_id, event)
                        }
                      />
                      <ConfirmPopup />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* instagram */}
          {pagesByType["instagram"] && pagesByType["instagram"].length && (
            <>
              <div className="flex flex-row mt-6 md:ml-4 items-center justify-start">
                <img className="w-12  h-12 mr-10" src={InstagramColor} alt="" />
                <h1 className="text-asap-orange   text-sm  md:text-xl font-Montserrat font-bold ">
                  Profils Instagram ajoutés
                </h1>
              </div>

              <div className="instacontainer md:overflow-auto max-h-44 md:m-2 md:mr-14">
                {pagesByType["instagram"].map((el) => (
                  <div
                    key={el.id}
                    className="flex flex-row gap-2 md:gap-8 mt-6 mr-8 justify-center"
                  >
                    <div className="bg-asap-white flex items-center rounded-xl p-3 md:p-4">
                      <img
                        className=" w-5 h-5 md:w-8 md:h-8"
                        src={el.page.picture}
                        alt=""
                      />
                    </div>

                    <div className=" flex items-center  md:gap-24 bg-asap-white rounded-xl px-2 md:px-0  md:pr-8 ">
                      <h1 className=" text-asap-blue w-28 font-Montserrat font-bold text-xs md:text-lg  text-left mt-2 md:pl-5">
                        {el.page.name}
                      </h1>
                      <img
                        className="w-5 h-5 mt-1 md:ml-7 cursor-pointer"
                        src={deleteIcon}
                        alt=""
                        onClick={(event) =>
                          delPage(el.social_account_id, el.social_id, event)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* linkedin */}
          {pagesByType["linkedin"] && pagesByType["linkedin"].length && (
            <>
              <div className="flex flex-row md:ml-4 mt-6 items-center justify-start">
                <img
                  className="w-12  h-12 mr-10"
                  src={linkedin}
                  alt="linkedin"
                />
                <h1 className="text-asap-orange   text-sm  md:text-xl font-Montserrat font-bold ">
                  Profils Linkedin ajoutés
                </h1>
              </div>

              <div className="linkedin-container md:overflow-auto h-44 md:m-2 md:mr-14 ">
                {pagesByType["linkedin"].map((el) => (
                  <div
                    key={el.id}
                    className="flex flex-row gap-2 md:gap-8 mt-6 mr-8 justify-center "
                  >
                    <div className="bg-asap-white flex items-center rounded-xl p-3 md:p-4">
                      <img
                        className=" w-5 h-5 md:w-8 md:h-8"
                        src={el.page.picture}
                        alt=""
                      />
                    </div>

                    <div className=" flex items-center  md:gap-24 bg-asap-white rounded-xl px-2 md:px-0  md:pr-8 ">
                      <h1 className=" text-asap-blue w-28 font-Montserrat font-bold text-xs md:text-lg  text-left mt-2 md:pl-5">
                        {el.page.name}
                      </h1>
                      <img
                        className="w-5 h-5 mt-1 md:ml-7 cursor-pointer"
                        src={deleteIcon}
                        alt=""
                        onClick={(event) =>
                          delPage(el.social_account_id, el.social_id, event)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* twitter */}
          {pagesByType["twitter"] && pagesByType["twitter"].length && (
            <>
              <div className="flex flex-row md:ml-4 mt-6 items-center justify-start">
                <img className="w-12  h-12 mr-10" src={twitterIcon} alt="" />
                <h1 className="text-asap-orange   text-sm  md:text-xl font-Montserrat font-bold ">
                  Profils twitter ajoutés
                </h1>
              </div>

              <div className="twitter-container md:overflow-auto h-44 md:m-2 md:mr-14 ">
                {pagesByType["twitter"].map((el) => (
                  <div
                    key={el.id}
                    className="flex flex-row gap-2 md:gap-8 mt-6 mr-8 justify-center "
                  >
                    <div className="bg-asap-white flex items-center rounded-xl p-3 md:p-4">
                      <img
                        className=" w-5 h-5 md:w-8 md:h-8"
                        src={el.page.picture}
                        alt=""
                      />
                    </div>

                    <div className=" flex items-center  md:gap-24 bg-asap-white rounded-xl px-2 md:px-0  md:pr-8 ">
                      <h1 className=" text-asap-blue w-28 font-Montserrat font-bold text-xs md:text-lg  text-left mt-2 md:pl-5">
                        {el.page.name}
                      </h1>
                      <img
                        className="w-5 h-5 mt-1 md:ml-7 cursor-pointer hide_el"
                        src={deleteIcon}
                        alt=""
                        onClick={(event) =>
                          delPage(el.social_account_id, el.social_id, event)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <button
          //onClick={() => setStatus("success") && navigate("/welcome")}
          onClick={() => navigate("/")}
          className="btn_url"
        >
          Commencer à utiliser DIGGOW
        </button>
      </div>
    );
  };

  useEffect(() => {
    loadPages();
    setIsOpen(false);
  }, []);

  if (status == "loading") return <Loader />;

  return (
    <div>
      {/* <SuccessModal
        visible={status == "success"}
        onHide={() => setStatus("")}
        onClick={() => navigate("/welcome")}
      /> */}
      {isOpen && (
        <Popup open modal nested closeOnDocumentClick={false}>
          <div className="modal">
            <div>
              <button
                className="text-white text-[18px] bg-[#7F56D9] rounded-[8px] font-semibold w-full mt-5	px-3 py-2 skipbutton"
                onClick={() => setIsOpen(false)}
              >
                Skip
              </button>
            </div>
            <div className="content">
              {/* Video element */}
              <video width="1020" height="840" controls autoPlay muted>
                <source src={videoReact} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </Popup>
      )}
      <SocialModals
        onSave={() => onSavePages()}
        socialResult={socialResult}
        visible={status == "selectingPages" && socialResult}
        onHide={() => setStatus("")}
      />
      <div className="block_register body_gray align">
        <div className="body_login full_width body_blue">
          <img
            src={body_register}
            alt="body_register"
            className="center_el"
          ></img>
        </div>
        {/* <div class="Vector"></div> */}

        <div className="form_login form_style full_width">
          <div className="logo_flex flex">
            <img src={Slice1} alt="logo_flex" />
            <h1>diggow</h1>
          </div>

          <div className="connect_block">
            <h2>Félicitations !</h2>
            <p>
              Votre espace est à présent actif. Veuillez ajouter vos profils
              sociaux <br />
              pour commencer !
            </p>

            <h3>Connectez les profils sociaux que vous souhaitez gérer.</h3>
          </div>
          <div className="block_social">
            {/* first row */}

            <div className="flex box_social">
              <button
                onClick={() => addSocialLink("facebook")}
                className="account_social align_all"
              >
                <div>
                  <img src={fbIcon} className="w-10 mb-4 h-10" />
                  <span>Facebook</span>
                </div>
              </button>

              <button
                onClick={() => addSocialLink("instagram")}
                className="account_social align_all"
              >
                <img src={instaIcon} className="w-10 mb-4 h-10" />
                <span>Instagram</span>
              </button>

              <button
                onClick={() => addSocialLink("linkedin")}
                className="account_social align_all"
              >
                <img src={linkedinIcon} className="w-10 mb-4 h-10" />
                <span>Linkedin</span>
              </button>

              <button
                onClick={() => addSocialLink("twitter")}
                className="account_social align_all"
              >
                <img src={twitterIcon} className="w-10 mb-4 h-10" />
                <span>Twitter</span>
              </button>
              <button
                onClick={() => addSocialLink("youtube")}
                className="account_social align_all"
              >
                <div>
                  <img src={youtubeIcon} className="w-10 mb-4 h-10" />
                  <span>Youtube</span>
                </div>
              </button>
            </div>

            <div className="flex items-center mt-20 hide_el">
              <img src={Pluscircle} className="Pluscircle w-4 h-4 " />
              <label className="text-left pr-24">
                {" "}
                Vous êtes en version d'essai ? Vous pouvez ajouter jusqu'à 1{" "}
                <br /> pages Facebook ou profil Instagram ou Linkedin.
              </label>
            </div>

            <button
              className="btn_url"
              onClick={() => utils.goTo("/setup/account")}
            >
              Retour
            </button>
          </div>
          {/* pages container */}

          {showPages()}
        </div>
      </div>
    </div>
  );
};
export default SetupPages;
