import React, { useState, useContext } from "react";

import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";

import { Link, useNavigate } from "react-router-dom";
import user from "../images/user.svg";
import { Checkbox } from "primereact/checkbox";
import Slice1 from "../images/images/Slice1.svg";
import body_register from "../images/my_images/body_register.png";

import Pluscircle from "../images/images/Pluscircle.svg";
import { AppContext, API, toast, useUtils } from "../utils";
import { useFormik } from "formik";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import paysOptions from '../../src/countries';
import '../../src/flag.css';

const countryOptionTemplate = (option) => {
  return (
      <div className="flex align-items-center">
          <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} />
          <div>{option.name}</div>
      </div>
  );
};

const SignUp = () => {
  //let loading = "true";
  const utils = useUtils();
  const [state, setState] = React.useContext(AppContext);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  let failed = "";
  const errormail = (props) => {
    return (
      <div>
        <p className="text-black">{failed}</p>
      </div>
    );
  };  

  const [filterPays, setFilterPays] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      pays: "",
      phone: "",
      company: "",
      email: "",
      password: "",
      confirmation_password: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.email) {
        errors.email = "Email requis.";
      } else if (
        //!/^[A-Z0-9._%+-]+@[A-Z0-9.-_]+\.[A-Z]{2,4}$/i.test(data.email)
        !/^[A-Z0-9._%+-]+@[A-Z0-9._-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Invalide format pour l'email";
      }

      // Ajouter une vérification pour pays
      if (!data.pays.code) {
        errors.pays = "Pays requis."; // Message d'erreur si le pays est null
      }

      if (!data.password) {
        errors.password = "Mot de passe requis.";
      }

      if (data.password.length < 8) {
        errors.password = "Veuillez choisir au minimum 8 caractères";
      } else if (data.password.search(/[a-z]/i) < 0) {
        errors.password = "Veuillez choisir au minimum une lettre.";
      } else if (data.password.search(/[0-9]/) < 0) {
        errors.password = "Veuillez choisir au minimum un chiffre.";
      } else if (data.password.search(/[!@#$%^&*_]/) < 0) {
        errors.password = "Veuillez choisir au minimum un caractère spécial.";
      }

      if (data.confirmation_password != data.password) {
        errors.confirmation_password = "Les mots de passe ne correspondent pas";
      }

      return errors;
    },
    onSubmit: async (data) => {
      console.log("thisdata", data);
      const paysName = data.pays.name;
      const newData = { ...data, pays: paysName };
      setFilterPays(data.pays);
      try {
        setStatus("saving");
        const res = await API.user.signup(newData);
        API.user.setupSession(res.data, state, setState);
        navigate("/setup/account");
      } catch (e) {
        Object.values(e.response.data.error).forEach((error) => {
          toast("email already exists");
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error text-asap-orange block font-thin mx-auto font-Montserrat mt-1">
          {formik.errors[name]}
        </small>
      )
    );
  };  

  const { t, i18n } = useTranslation();

  return (
    <div className="block_register body_gray">

      <form onSubmit={formik.handleSubmit} className="align">
        <div className="body_login full_width body_blue">
          <img
            src={body_register}
            alt="body_register"
            className="center_el"
          ></img>
        </div>
        {/* <div class="Vector"></div> */}

        <div className="form_login form_style full_width">
          <div className="logo_flex flex">
            <img src={Slice1} alt="logo_flex" />
            <h1>diggow</h1>
          </div>

          <div className="connect_block">
            <h2>{t("signup.welcome")}</h2>
            <p>{t("signup.signup_msg")}</p>
          </div>

          <div className="input_login input_style">
            <div className="box_input">
              <label className="Email-professionnel" name="name">
                {t("signup.name")}
              </label>
              <input
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {getFormErrorMessage("name")}
            </div>

            <div className="box_input">
              <label className="Email-professionnel" name="email">
                {t("login.Email_professionnel")}
              </label>
              <input
                type="text"
                name="email"
                placeholder="Nom@email.com"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {getFormErrorMessage("email")}
              {getFormErrorMessage()}

              <errormail></errormail>
            </div>

            {getFormErrorMessage("email")}
            <div className="box_input">
              <label className="Email-professionnel" name="company">
                Société
              </label>
              <input
                type="text"
                name="company"
                value={formik.values.company}
                onChange={formik.handleChange}
              />
              {getFormErrorMessage("company")}
            </div>
            
            <div className="box_input">
              <label className="Email-professionnel" name="phone">
                Choisissez un pays
              </label>              
              <Dropdown
                filter
                className="w-full bg-white"
                value={filterPays}
                name="pays"
                options={paysOptions}
                itemTemplate={countryOptionTemplate}
                onChange={(e) => {
                  formik.handleChange(e); // Met à jour les valeurs du formulaire
                  setFilterPays(e.target.value); // Met à jour l'état filterPays
                }}
                optionLabel="name"
                placeholder="Pays"
              />
              {getFormErrorMessage("pays")}
            </div>

            <div className="box_input">
              <label className="Email-professionnel" name="phone">
                {t("signup.phone")}
              </label>
              <input
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              {getFormErrorMessage("phone")}
            </div>

            <div className="box_input">
              <label
                className="Email-professionnel"
                name="password"

                // feedback={false}
              >
                {t("login.password")}
              </label>
              <input
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {getFormErrorMessage("password")}
            </div>

            <div className="box_input">
              <label
                className="Email-professionnel"
                name="confirmation_password"

                // feedback={false}
              >
                {t("signup.confirm_password")}
              </label>
              <input
                type="password"
                name="confirmation_password"
                value={formik.values.confirmation_password}
                onChange={formik.handleChange}
              />
              {getFormErrorMessage("confirmation_password")}
            </div>

            <div className="flex required_pwd items-center ">
              <img src={Pluscircle} className="mb-5" />
              <label> {t("signup.prerequisites_password")}</label>
            </div>
            <button
              type="submit"
              className="btn_url"
              // onClick={() => utils.goTo("/setup/account")}
              loading={status == "saving"}
            >
              {t("signup.register")}
            </button>
            <Toaster
              toastOptions={{
                className: "",
                style: {
                  border: "2px solid #713200",
                  padding: "16px",
                  color: "#596ae9",
                  backgroundColor: "#f4f7fb",
                },
              }}
            />

            <div className="align choix_cnx">
              <label> {t("signup.have_account")} </label>
              <Link to="/register">
                <button>{t("signup.login")}</button>
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default SignUp;
