import Highcharts from "highcharts";
import html2canvas from "html2canvas";
import HighchartsReact from "highcharts-react-official";

const COLORS = ["#596ae9", "#6955e6", "#22c4ac", "orange"];

const PiePosts = (props) => {
  let options = {
    credits: false,
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 600,
      style: {
        fontFamily: "Inter",
      },
    },
    colors: COLORS,
    title: {
      text: "Types des Posts",
      align: "left",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        size: "80%",
      },
    },
    // series: [{
    //   name: '',
    //   innerSize: "50%",
    //   pie: {},
    //   colorByPoint: true,
    //     data: [{
    //       name: 'photo',
    //       y: 5,
    //     },{
    //       name: 'video',
    //       y: 55,
    //     }],
    // }],
    series: [
      {
        innerSize: "50%",
        pie: {},
        colorByPoint: true,
        data: [{}],
      },
    ],
  };

  let myData = props.data.instagram.types_posts || [];
  //console.log("myData ", myData.video);
  var keyNames = Object.keys(myData);
  //console.log(keyNames);

  const posts_ins_photo = {
    name: "photo",
    y: myData.photos,
  };

  const posts_ins_video = {
    name: "video",
    y: myData.video,
  };

  const posts_ins_album = {
    name: "album",
    y: myData.album,
  };

  options.series[0].data.push(posts_ins_photo);
  options.series[0].data.push(posts_ins_album);
  options.series[0].data.push(posts_ins_video);

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");
    parent.classList.add("capture_style");

    html2canvas(parent, {
      onclone: (document) => {},
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        parent.classList.remove("capture_style");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  const data_instagram = props?.data?.instagram?.types_posts;
  const feeds_instagram = data_instagram?.feeds ?? 0;
  const images_instagram = data_instagram?.images ?? 0;
  const videos_instagram = data_instagram?.videos ?? 0;
  const reels_instagram = data_instagram?.reels ?? 0;
  const albums_instagram = data_instagram?.albums ?? 0;
  const stories_instagram = data_instagram?.stories ?? 0;

  console.log('props posts', props?.data?.instagram?.types_posts);

  return (
    <div>
      <div
        data-value="Comportement de publication"
        className="style_title_chart highchart_block1 mb-5"
      >
        <div className="flex items-center">
          <button
            data-html2canvas-ignore
            onClick={printChart}
            className="ml-auto mr-1.5 w-5 h-5 print_capture"
          ></button>
        </div>
        <div className="highchart_block flex">
          <div className="highchart_block1 full_width">
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>

          <div className="highchart_block1 full_width">
            <div className="audience_total bg-rectangle rounded-[8px] mb-10">
              <div className="flex items-center">
                <div className="w-1/5">
                  <h2 className="text-[#344563] text-[16px] font-['inter']">
                    Total posts
                  </h2>
                </div>
                <div className="w-4/5">
                  <span className="text-[#152935] text-[24px] font-['inter'] font-extrabold">
                    {props.data?.total?.posts_count} Posts
                  </span>
                </div>
              </div>
            </div>

            <div>
              <div className="text-xs">
                <div className="flex items-center font-bold">
                  <span className=""></span>
                  <div className="ml-2 flex capitalize">Feeds :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {feeds_instagram}
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
              
              <div className="text-xs">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex capitalize">Photos :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {images_instagram}
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
              <div className="text-xs">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex capitalize">Videos :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {videos_instagram}
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
              <div className="text-xs">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex capitalize">Albums :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {albums_instagram}
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
              
              <div className="text-xs">
                <div className="flex items-center font-bold">
                  <span className=""></span>
                  <div className="ml-2 flex capitalize">Stories :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {stories_instagram}
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
              
              <div className="text-xs">
                <div className="flex items-center font-bold">
                  <span className=""></span>
                  <div className="ml-2 flex capitalize">Reels :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {reels_instagram}
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PiePosts;
