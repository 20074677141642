import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import html2canvas from "html2canvas";

import { AppContext, SocialIcon, useUtils, getNextColor } from "../../utils";
// import { useTranslation } from 'react-i18next';

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Table = (props) => {
  //   const { t, i18n } = useTranslation();
  const { t, i18n } = useTranslation();
  const [color] = useState(getNextColor());
  let columns = [];
  props.config.metrics.map((el) => {
    let metric = props.data.metrics[el];
    columns.push(
      <Column
        key={"1" + el}
        field={"name"}
        header={metric?.name || el}
        sortable
      />
    );
    columns.push(
      <Column key={"2" + el} field={el} header={"Valeur"} sortable />
    );
  });

  let options = {
    credits: false,
    // xAxis: {
    //   type: "datetime",
    // },
    chart: {
      type: "bar",
    },
    // colors: color,

    title: {
      align: "left",
      text:
        "Variation " + props.data.metrics[props.config.metrics[0]].name || "",
      style: { "font-weight": "50px", color: "#a" },
    },
    //   subtitle: {
    //     align:"left",
    //     text: props.data.metrics[props.config.metrics[0]].name || "",
    //   },

    xAxis: {
      categories: [],
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Fans",
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
    },
    dataSorting: {
      enabled: true,
      sortKey: "y",
    },
    legend: false,
    series: [
      {
        data: [],
      },
    ],
    plotOptions: {
      series: {
        color: color,
      },
      bar: {
        pointWidth: 8,
        borderRadius: 3,
        opacity: 0.9,
      },
    },
  };
  
  let sorted = props.cmpData.data
    .sort((a, b) => b[props.cmpData.dataKey] - a[props.cmpData.dataKey])
    .slice(0, 5);
  options.series[0].data = [];
  options.xAxis.categories = [];
  sorted.map((entry, index) => {
    options.xAxis.categories.push(entry.name);
    options.series[0].data.push({
      name: entry.name,
      y: entry[props.cmpData.dataKey],
    });
  });

  //console.log("sorted",sorted);
  //console.log("color",color);

  //console.log("props.cmpData.data", props);

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");
    parent.classList.add("capture_style");

    html2canvas(parent, {
      onclone: (document) => {},
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        parent.classList.remove("capture_style");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  return (
    <div
      data-value={props.data.metrics[props.config.metrics[0]].name}
      className="style_title_chart highchart_block1 mb-5"
    >
      <div className="flex items-center">
        <div className="content_style_title">
          <h1>{props.data.metrics[props.config.metrics[0]].name}</h1>
          <p>{props.data.metrics[props.config.metrics[0]].description}</p>
        </div>
        <button
          data-html2canvas-ignore
          onClick={printChart}
          className="ml-auto mr-1.5 w-5 h-5 print_capture"
        ></button>
      </div>

      <div className="highchart_block flex">
        <div className="highchart_block1 full_width">
          <DataTable
            style={{ width: "26vw" }}
            scrollable
            scrollHeight="300px"
            //    paginatorTemplate="CurrentPageReport"
            //    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10]}
            value={props.cmpData.data}
            responsiveLayout="scroll"
            sortField={props.config.metrics[0]}
            sortOrder={-1}
            className="dark:bg-diggow-dark-300 dark:text-asap-white text-xs 2xl:text-base"
            //headerClassName="dark:bg-diggow-dark-300 dark:text-asap-white text-xs 2xl:text-base"
            //bodyClassName="dark:bg-diggow-dark-300 dark:text-asap-white text-xs 2xl:text-base"
          >
            {columns}
          </DataTable>
        </div>
        <div className="highchart_block1 full_width">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </div>
  );
};

export default Table;
