import React, { useState, useEffect, useRef } from "react";
import Welcome from "../Welcome";
import { AppContext, SocialIcon, useUtils, Loader } from "../../utils";
import PostModal from "./PostModal";
//import PostModal from "./PostModal";
import AddPostModal from "./PostModal";
import { Dialog } from "primereact/dialog";

import { useTranslation } from "react-i18next";

import {
  useNavigate,
  Link,
  useParams,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import API from "../../utils/Api";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import addPlanIcon from "../../images/addNewPlan.png";

import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import { Tooltip } from "primereact/tooltip";

const Planification = () => {
  const utils = useUtils();

  const [state, setState] = React.useContext(AppContext);
  const currentPage = state.currentPage;
  const [status, setStatus] = useState("");
  const [ttEvent, setTtEvent] = useState(null);
  const params = useParams();
  const ttRef = useRef();
  const fcRef = useRef();
  console.log(params);
  const [event, setEvent] = useState([]);
  const [data, setData] = useState([]);

  //fix calendar full screen resizing
  setTimeout(() => window.dispatchEvent(new Event("resize")), 500);

  let element = document.querySelector('[title="list view"]');
  console.log("elem", element);
  //****v1****

  useEffect(() => {
    console.log("state", state);
    if (
      state.pages &&
      params.pageId &&
      (!state.currentPage || state.currentPage.page.id != params.pageId)
    ) {
      let page = (state.pages || []).find((el) => el.page_id == params.pageId);
      if (page)
        setState((s) => {
          return { ...s, currentPage: page };
        });
    }
    loadEvents();
  }, [state.pages, state?.currentPage?.page_id]);

  let events = [];
  for (let el of data) {
    events.push({
      title: el.description,
      date: new Date(el.date),
      id: el.id,
      type: el.type,
      media: el.media,
      tt: <PostModal preview event={el} />,
    });
  }

  const loadEvents = async () => {
    try {
      if (!state?.currentPage) return;
      setStatus("loading");
      const res = await API.plannings.all(state?.currentPage.page_id);
      const response = res?.data?.data;
      console.log("response", response);
      setData(response);
      setStatus("");
    } catch (e) {
      console.log(e);
    }
  };

  const addPost = () => {
    setEvent([]);
    setStatus("adding");
  };

  const handleEventClick = async (clickInfo) => {
    try {
      setTtEvent(null);
      const res = await API.plannings.get(
        state.currentPage.page_id,
        clickInfo.event.id
      );
      setEvent(res?.data?.data);
      console.log(event);
      setStatus("adding");
    } catch (e) {
      console.log(e);
    }
  };

  const eventDidMount = function (info) {
    ttRef?.current?.updateTargetEvents();
  };

  const onBeforeShowTt = (e) => {
    setTtEvent(e.target.fcSeg.eventRange.def.extendedProps.tt);
  };
  const onBeforeHideTt = (e) => {
    setTtEvent(null);
  };

  return (
    <div className="flex-1 p-4">
      <Tooltip
        target=".fc-event"
        autoHide={true}
        ref={ttRef}
        onBeforeShow={onBeforeShowTt}
        onBeforeHide={onBeforeHideTt}
      >
        <div>{ttEvent}</div>
      </Tooltip>
      {/* {!currentPage || currentPage.social_type != "facebook" ? (
        <Welcome /> */}
      {status == "loading" ? (
        <Loader />
      ) : (
        <div className="full-calendar mb-20 p-4 mx-auto bg-white border border-gray-300 rounded-md ">
          <FullCalendar
            ref={fcRef}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            eventClick={handleEventClick}
            events={events}
            headerToolbar={{
              left: "today listWeek",
              center: "prev title next",
              right: "dayGridMonth,timeGridWeek,timeGridDay myCustomButton",
            }}
            initialView="dayGridMonth"
            dayHeaderFormat={{ weekday: "long" }}
            customButtons={{
              myCustomButton: {
                hint: "Add new post",
                text: (
                  <div className="flex items-center gap-2 px-2">
                    <span>
                      <img src={addPlanIcon} alt="" />
                    </span>{" "}
                    Add New Post
                  </div>
                ),

                click: () => addPost(),
                //click: () => utils.goTo("/postmodal"),
              },
            }}
            eventDidMount={eventDidMount}
          />

          {status == "adding" && (
            <AddPostModal
              visible={status == "adding"}
              onHide={() => setStatus("")}
              //onClick={() => history("/welcome")}
              onLoad={() => loadEvents()}
              key={event ? event.id : null}
              event={event}
            />
          )}
          <Dialog />
        </div>
      )}
    </div>
  );
};

export default Planification;
