// import React from "react";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
// import VFColumnChart from "./VFColumnChart";
// import PIColumnChart from "./PIColumnChart";
// import PEColumnChart from "./PEColumnChart";
// import PRColumnChart from "./PRColumnChart";
// import PPColumnChart from "./PPColumnChart";
// import PCColumnChart from "./PCColumnChart";
// import DataTableCrudDemo from "./DataTableCrudDemo";
// import BenchmarkHeader from "./Header";
// import SpLine from "../DataCmp/SpLine";
// import TableCombined from "./TableCombined";

// const BenchMark = () => {
//   return (
//     <div>
//       <BenchmarkHeader></BenchmarkHeader>
//       <TableCombined></TableCombined>
//       {/* <DataTableCrudDemo></DataTableCrudDemo>
//       <div>i'm the buttons area</div>
//       <VFColumnChart></VFColumnChart> */}
//       {/* <SpLine></SpLine> */}
//       {/* <VFColumnChart></VFColumnChart> */}
//       <PIColumnChart></PIColumnChart>
//       <PEColumnChart></PEColumnChart>
//       <PRColumnChart></PRColumnChart>
//       <PCColumnChart></PCColumnChart>
//       <PPColumnChart></PPColumnChart>
//     </div>
//   );
// };

// export default BenchMark;

////////////////////////////////////v2////////////////////////
import React, { useEffect } from "react";
import { Layout as DataLayout } from "../DataCmp/Layout";
import millify from "millify";
import { AppContext, SocialIcon, useUtils } from "../../utils";
import BenchmarkHeader from "./BenchmarkHeader";


const Benchmark = (props) => {
  return (
    <>
    <BenchmarkHeader/>
    <div className="btn_url_cover cat_name table my-4">
        Category :{" "}
        <span className="font-Montserrat">
          {props.reportingData.category?.name}
        </span>
      </div>
      <DataLayout
        layout={props.reportingData.layouts.bench}
        data={props.reportingData}
      />
    </>
  );
};

export default Benchmark;
