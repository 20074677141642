import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import prdcts from "./products.json";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "./DataTableDemo.css";
import { AppContext } from "../../utils/Context";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import html2canvas from "html2canvas";
import millify from "millify";

import { ReactComponent as UpIcon } from "../../images/upIcon.svg";
import { ReactComponent as DownIcon } from "../../images/downIcon.svg";

const TableCombined = (props) => {
  const [state, setState] = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  //-- moy_sectorielle
  function nombreTotalJoursEntreDeuxDates(dateDebut, dateFin) {
    const debut = new Date(dateDebut);
    const fin = new Date(dateFin);
    const differenceEnMillisecondes = fin - debut;
    const differenceEnJours =
      Math.floor(differenceEnMillisecondes / (1000 * 60 * 60 * 24)) + 1;
    return differenceEnJours;
  }

  const start_date = props.data.date_info.start_date;
  const end_date = props.data.date_info.end_date;
  const totalJours = nombreTotalJoursEntreDeuxDates(start_date, end_date);

  // Fonction pour formater un nombre avec millify et une précision de 3 chiffres après la virgule
  function millifyAvecPrecision(nombre) {
    if (typeof nombre !== "number" || isNaN(nombre)) {
      // Handle the case where nombre is not a valid number
      return 0; // or throw an error, log a message, etc.
    }

    const nombreFormate = nombre.toLocaleString();
    return nombre > 0 ? nombreFormate : 0;
  }

  function percentWithPrecision(nombre) {
    var arrondi =
      nombre.toString().substring(0, nombre.toString().indexOf(".") + 4) + "%";
    return arrondi;
  }

  function campareTwoValues(val1, val2) {
    if (val1 >= val2) {
      return <UpIcon className="fill-[#008000] w-5 table m-auto" />;
    } else {
      return <DownIcon className="fill-[#FF0000] w-5 table m-auto" />;
    }
  }

  const dataBench = props.data?.dataBench?.total;
  const social_type = props?.data?.socialType;

  const total_posts_moy = dataBench.length
    ? dataBench.reduce((sum, item) => sum + item.total_posts, 0) /
      dataBench.length
    : 0;
  const total_video_inst = dataBench.reduce(
    (sum, item) => sum + item.post_type.videos,
    0
  );
  const total_posts_moy_ig = dataBench.length
    ? dataBench.reduce(
        (sum, item) => sum + item.total_posts - total_video_inst,
        0
      ) / dataBench.length
    : 0;
  const total_posts_moy_inst =
    social_type == "facebook" ? total_posts_moy : total_posts_moy_ig;
  const page_fans_moy = dataBench.length
    ? dataBench.reduce((sum, item) => sum + (item.page_fans ?? 0), 0) /
      dataBench.length
    : 0;
  const total_interactions_moy = dataBench.length
    ? dataBench.reduce((sum, item) => sum + item.interactions.sum, 0) /
      dataBench.length
    : 0;
  const taux_interaction_moy = dataBench.length
    ? dataBench.reduce((sum, item) => {
        const engagement = item.page_fans
          ? item.interactions.sum / item.page_fans
          : 0;
        return sum + engagement;
      }, 0) / dataBench.length
    : "0%";
  const nbr_posts_jour_moy = dataBench.length
    ? dataBench.reduce((sum, item) => {
        const nbr_post_day = totalJours ? item.total_posts / totalJours : 0;
        return sum + nbr_post_day;
      }, 0) / dataBench.length
    : 0;
  const post_img_moy = dataBench.length
    ? dataBench.reduce((sum, item) => sum + item.post_type.photos, 0) /
      dataBench.length
    : 0;
  const post_video_moy = dataBench.length
    ? dataBench.reduce((sum, item) => sum + item.post_type.videos, 0) /
      dataBench.length
    : 0;
  const post_video_moy_inst = post_video_moy;
  const post_reel_moy = dataBench.length
    ? dataBench.reduce((sum, item) => sum + item.post_type.reels, 0) /
      dataBench.length
    : 0;
  const post_reel_moy_inst = post_reel_moy;

  const table_moy_obj = {
    nbr_marque: dataBench.length,
    page_fans: millifyAvecPrecision(page_fans_moy),
    total_post: millifyAvecPrecision(total_posts_moy_inst),
    total_interactions: millifyAvecPrecision(total_interactions_moy),
    taux_interaction: percentWithPrecision(taux_interaction_moy),
    nbr_posts_jour: millifyAvecPrecision(nbr_posts_jour_moy),
    posts_images: millifyAvecPrecision(post_img_moy),
    posts_videos: millifyAvecPrecision(post_video_moy_inst),
    posts_reals: millifyAvecPrecision(post_reel_moy_inst),
  };

  const moy_data = [];
  moy_data.push(table_moy_obj);

  //-- Chiffre clés
  const page_admin = state.currentPage.page_id;

  const filteredData = dataBench.filter((item) => item.page_id === page_admin);
  const AdminPageData = filteredData[0];

  //-- Taux d’engagement moyen des pages
  const total_fans_kf = AdminPageData?.page_fans;
  const total_interactions_kf = filteredData.reduce((sum, item) => {
    return sum + item.interactions.sum;
  }, 0);
  const taux_interactions_kf = total_fans_kf
    ? percentWithPrecision((total_interactions_kf / total_fans_kf) * 100)
    : 0;

  //-- Nombre moyen des publications
  const total_posts_kf = AdminPageData?.total_posts ?? 0;
  const nbr_moy_pub = total_posts_kf
    ? total_interactions_kf / total_posts_kf
    : 0;
  const nbr_moy_pub_sec = total_posts_moy
    ? total_interactions_moy / total_posts_moy
    : 0;

  //-- Nombre moyen des réactions par page
  const reaction_kf = AdminPageData?.interactions?.reactions;
  const reaction_sec = dataBench.length
    ? dataBench.reduce(
        (acc, dataBench) => acc + dataBench.interactions.reactions,
        0
      ) / dataBench.length
    : 0;

  //-- Nombre moyen des partages par page
  const shares_kf = AdminPageData?.interactions?.shares;
  const shares_sec = dataBench.length
    ? dataBench.reduce(
        (acc, dataBench) => acc + dataBench.interactions.shares,
        0
      ) / dataBench.length
    : 0;

  //-- Nombre moyen des commentaires par page
  const comments_kf = AdminPageData?.interactions?.comments;
  const comments_sec = dataBench.length
    ? dataBench.reduce(
        (acc, dataBench) => acc + dataBench.interactions.comments,
        0
      ) / dataBench.length
    : 0;

  const chiffre_key = [
    {
      name: t("Benchmark.Average_Page_Engagement_Rate"),
      nos_marque: taux_interactions_kf,
      verified: campareTwoValues(
        taux_interactions_kf,
        percentWithPrecision(taux_interaction_moy)
      ),
      moy_sec: percentWithPrecision(taux_interaction_moy),
    },
    {
      name: t("Benchmark.Average_Total_Number_Fans"),
      nos_marque: millifyAvecPrecision(total_fans_kf),
      verified: campareTwoValues(total_fans_kf, page_fans_moy),
      moy_sec: millifyAvecPrecision(page_fans_moy),
    },
    {
      name: t("Benchmark.Average_Number_Posts"),
      nos_marque: millifyAvecPrecision(total_posts_kf),
      verified: campareTwoValues(total_posts_kf, total_posts_moy),
      moy_sec: millifyAvecPrecision(total_posts_moy),
    },
    {
      name: t("Benchmark.Average_Number_Interactions_per_Post"),
      nos_marque: millifyAvecPrecision(nbr_moy_pub),
      verified: campareTwoValues(nbr_moy_pub, nbr_moy_pub_sec),
      moy_sec: millifyAvecPrecision(nbr_moy_pub_sec),
    },
    {
      name: t("Benchmark.Average_Number_Interactions_per_Page"),
      nos_marque: millifyAvecPrecision(total_interactions_kf),
      verified: campareTwoValues(total_interactions_kf, total_interactions_moy),
      moy_sec: millifyAvecPrecision(total_interactions_moy),
    },
    {
      name: t("Benchmark.Average_Number_Reactions_Page"),
      nos_marque: millifyAvecPrecision(reaction_kf),
      verified: campareTwoValues(reaction_kf, reaction_sec),
      moy_sec: millifyAvecPrecision(reaction_sec),
    },
    {
      name: t("Benchmark.Average_Number_Shares_Page"),
      nos_marque: millifyAvecPrecision(shares_kf),
      verified: campareTwoValues(shares_kf, shares_sec),
      moy_sec: millifyAvecPrecision(shares_sec),
    },
    {
      name: t("Benchmark.Average_Number_Comments_Page"),
      nos_marque: millifyAvecPrecision(comments_kf),
      verified: campareTwoValues(comments_kf, comments_sec),
      moy_sec: millifyAvecPrecision(comments_sec),
    },
  ];

  //console.log('dataBench', dataBench);

  const finalResult = props.data?.ids_sector?.finalResult;

  const tabledata = [];
  for (var i = 0; i < finalResult.length; i++) {
    const ids_sector_table = {
      name: finalResult[i]["page_name"] ?? 0,
      posts_count: finalResult[i]["total_posts"] ?? 0,
      page_fans: finalResult[i]["page_fans"] ?? 0,
      posts_interactions: finalResult[i]["interactions"]["sum"] ?? 0,
      posts_engagement: finalResult[i]["taux_interaction"]
        ? finalResult[i]["taux_interaction"] + "%"
        : 0,
      nbr_posts_jour: finalResult[i]["nbr_posts_jour"] ?? 0,
      post_img: finalResult[i]["post_type"]["photos"] ?? 0,
      post_video: finalResult[i]["post_type"]["videos"] ?? 0,
      post_reels: finalResult[i]["post_type"]["reels"] ?? 0,
      post_others: finalResult[i]["post_type"]["autres"] ?? 0,
    };
    tabledata.push(ids_sector_table);
  }

  const moy_sectorielles = props.data?.ids_sector?.moy_sectorielles;

  const moyData = [];
  const ids_sector_obj = {
    nbr_marque: moy_sectorielles["nbr_marques"],
    page_fans: millifyAvecPrecision(moy_sectorielles["total_fans"]),
    total_post: millifyAvecPrecision(moy_sectorielles["total_posts"]),
    total_interactions: millifyAvecPrecision(
      moy_sectorielles["total_interactions"]
    ),
    taux_interaction:
      millifyAvecPrecision(moy_sectorielles["taux_interactions"]) + "%",
    nbr_posts_jour: millifyAvecPrecision(
      moy_sectorielles["nbr_posts_par_jour"]
    ),
    posts_images: millifyAvecPrecision(moy_sectorielles["posts_images"]),
    posts_videos: millifyAvecPrecision(moy_sectorielles["posts_videos"]),
    posts_reals: millifyAvecPrecision(moy_sectorielles["posts_reals"]),
    posts_others: millifyAvecPrecision(moy_sectorielles["posts_others"]),
  };
  moyData.push(ids_sector_obj);

  const chiffres_key = props.data?.ids_sector?.chiffres_key;

  const chiffreKey = [
    {
      name: t("Benchmark.Average_Page_Engagement_Rate"),
      nos_marque: chiffres_key["taux_eng_moy_pages"],
      verified: campareTwoValues(
        chiffres_key["taux_eng_moy_pages"],
        percentWithPrecision(moy_sectorielles["taux_interactions"])
      ),
      moy_sec: percentWithPrecision(moy_sectorielles["taux_interactions"]),
    },
    {
      name: t("Benchmark.Average_Total_Number_Fans"),
      nos_marque: millifyAvecPrecision(chiffres_key["nbr_total_moy_fans"]),
      verified: campareTwoValues(
        chiffres_key["nbr_total_moy_fans"],
        moy_sectorielles["total_fans"]
      ),
      moy_sec: millifyAvecPrecision(moy_sectorielles["total_fans"]),
    },
    {
      name: t("Benchmark.Average_Number_Posts"),
      nos_marque: millifyAvecPrecision(chiffres_key["nbr_moy_publications"]),
      verified: campareTwoValues(
        chiffres_key["nbr_moy_publications"],
        moy_sectorielles["total_posts"]
      ),
      moy_sec: millifyAvecPrecision(moy_sectorielles["total_posts"]),
    },
    {
      name: t("Benchmark.Average_Number_Interactions_per_Post"),
      nos_marque: millifyAvecPrecision(
        chiffres_key["nbr_interactions_moy_post"]
      ),
      verified: campareTwoValues(
        chiffres_key["nbr_interactions_moy_post"],
        moy_sectorielles["total_interactions"] / moy_sectorielles["total_posts"]
      ),
      moy_sec: millifyAvecPrecision(
        moy_sectorielles["total_interactions"] / moy_sectorielles["total_posts"]
      ),
    },
    {
      name: t("Benchmark.Average_Number_Interactions_per_Page"),
      nos_marque: millifyAvecPrecision(
        chiffres_key["nbr_interactions_moy_page"]
      ),
      verified: campareTwoValues(
        chiffres_key["nbr_interactions_moy_page"],
        moy_sectorielles["total_interactions"]
      ),
      moy_sec: millifyAvecPrecision(moy_sectorielles["total_interactions"]),
    },
    {
      name: t("Benchmark.Average_Number_Reactions_Page"),
      nos_marque: millifyAvecPrecision(chiffres_key["nbr_moy_reactions_page"]),
      verified: campareTwoValues(
        chiffres_key["nbr_moy_reactions_page"],
        moy_sectorielles["total_reactions"]
      ),
      moy_sec: millifyAvecPrecision(moy_sectorielles["total_reactions"]),
    },
    {
      name: t("Benchmark.Average_Number_Shares_Page"),
      nos_marque: millifyAvecPrecision(chiffres_key["nbr_moy_partages_page"]),
      verified: campareTwoValues(
        chiffres_key["nbr_moy_partages_page"],
        moy_sectorielles["total_comments"]
      ),
      moy_sec: millifyAvecPrecision(moy_sectorielles["total_comments"]),
    },
    {
      name: t("Benchmark.Average_Number_Comments_Page"),
      nos_marque: millifyAvecPrecision(
        chiffres_key["nbr_moy_commentaires_page"]
      ),
      verified: campareTwoValues(
        chiffres_key["nbr_moy_commentaires_page"],
        moy_sectorielles["total_shares"]
      ),
      moy_sec: millifyAvecPrecision(moy_sectorielles["total_shares"]),
    },
  ];

  const DataTableCrudDemo2 = () => {
    let emptyProduct = {
      id: null,
      name: "",
      page_fans: 0,
      posts_count: 0,
      posts_interactions: 0,
      posts_engagement: 0,
    };

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    prdcts.data = props.cmpData.data;
    const data_all = props.data;

    const name_bench = dataBench.map((item) => item.page_name || "");
    const total_posts = dataBench.map((item) => item.total_posts || 0);
    const total_posts_autre = dataBench.map(
      (item) => item.post_type.autres || 0
    );

    const post_img = dataBench.map((item) => item.post_type.photos || 0);
    const post_video = dataBench.map((item) => item.post_type.videos || 0);
    const post_reels = dataBench.map((item) => item.post_type.reels || 0);
    const post_album = dataBench.map((item) => item.post_type.albums || 0);
    const total_fans = dataBench.map((item) => item.page_fans || 0);
    const total_interactions = dataBench.map(
      (item) => item.interactions.sum || 0
    );
    const table_data = [];
    for (var i = 0; i < dataBench.length; i++) {
      const formattedInteractions = total_interactions[i]
        ? total_interactions[i].toLocaleString()
        : 0;

      const taux_interactions_bench = total_fans[i]
        ? (total_interactions[i] / total_fans[i]) * 100
        : 0;
      const posts_engagement =
        taux_interactions_bench
          .toString()
          .substring(0, taux_interactions_bench.toString().indexOf(".") + 4) +
        "%";
      const nbr_posts_jour_result = totalJours
        ? total_posts[i] / totalJours
        : 0;
      const nbr_posts_jour = nbr_posts_jour_result > 0 ? nbr_posts_jour_result.toLocaleString() : 0;
     
      const table_data_obj = {
        name: name_bench[i],
        posts_count: total_posts[i],
        page_fans: total_fans[i],
        posts_interactions: formattedInteractions,
        posts_engagement: posts_engagement,
        nbr_posts_jour: nbr_posts_jour,
        post_img: post_img[i] + post_album[i],
        post_video: post_video[i],
        post_reels: post_reels[i],
      };
      table_data.push(table_data_obj);
    }

    useEffect(() => {
      setProducts(prdcts.data);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const hideDialog = () => {
      setSubmitted(false);
      setProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
      setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
      setDeleteProductsDialog(false);
    };

    const saveProduct = () => {
      setSubmitted(true);

      if (product.name.trim()) {
        let _products = [...products];
        let _product = { ...product };
        if (product.id) {
          const index = findIndexById(product.id);

          _products[index] = _product;
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          _product.id = createId();
          // _product.image = "product-placeholder.svg";
          _products.push(_product);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        setProducts(_products);
        setProductDialog(false);
        setProduct(emptyProduct);
      }
    };

    const deleteProduct = () => {
      let _products = products.filter((val) => val.id !== product.id);
      setProducts(_products);
      setDeleteProductDialog(false);
      setProduct(emptyProduct);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    };

    const findIndexById = (id) => {
      let index = -1;
      for (let i = 0; i < products.length; i++) {
        if (products[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    };

    const createId = () => {
      let id = "";
      let chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    };

    const deleteSelectedProducts = () => {
      let _products = products.filter((val) => !selectedProducts.includes(val));
      setProducts(_products);
      setDeleteProductsDialog(false);
      setSelectedProducts(null);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    };

    const onCategoryChange = (e) => {
      let _product = { ...product };
      _product["category"] = e.value;
      setProduct(_product);
    };

    const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || "";
      let _product = { ...product };
      _product[`${name}`] = val;

      setProduct(_product);
    };

    const onInputNumberChange = (e, name) => {
      const val = e.value || 0;
      let _product = { ...product };
      _product[`${name}`] = val;

      setProduct(_product);
    };

    const printChart = (event) => {
      event.preventDefault();

      let target = event.target;
      let parent =
        target.parentElement.parentElement.parentElement.parentElement
          .parentElement.parentElement.parentElement;
      let name = parent.getAttribute("data-value");
      parent.classList.add("capture_style");

      html2canvas(parent, {
        onclone: (document) => {
          document.querySelector(
            ".p-paginator.p-component.p-paginator-bottom"
          ).style.visibility = "hidden";
        },
      }).then((canvas) => {
        const data = canvas.toDataURL("image/jpg");
        const link = document.createElement("a");

        if (typeof link.download === "string") {
          link.href = data;
          link.download = name + ".jpg";

          parent.classList.remove("capture_style");

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }
      });
    };

    const header = (
      <div className="table-header">
        <div className="flex  items-center w-full">
          <div className="flex flex-col">
            <h1 className="mx-0 my-1 text-[24px]">{t("Benchmark.summary")}</h1>
            <p>{t("Benchmark.summary_desc")}</p>
          </div>
          <div className="ml-auto" data-html2canvas-ignore>
            <div className="flex items-center mb-2">
              <button
                onClick={printChart}
                className="ml-auto mr-1.5 w-5 h-5 print_capture"
              ></button>
            </div>
            <span className="p-input-icon-left items-right">
              <i className="pi pi-search" />
              <InputText
                type="search"
                onInput={(e) => setGlobalFilter(e.target.value)}
                placeholder={t("Options.search")}
              />
            </span>
          </div>
        </div>
      </div>
    );

    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialog}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={saveProduct}
        />
      </React.Fragment>
    );
    const deleteProductDialogFooter = (
      <React.Fragment>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDeleteProductDialog}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-text"
          onClick={deleteProduct}
        />
      </React.Fragment>
    );
    const deleteProductsDialogFooter = (
      <React.Fragment>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDeleteProductsDialog}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-text"
          onClick={deleteSelectedProducts}
        />
      </React.Fragment>
    );

    return (
      <div className="datatable-crud-demo">
        <Toast ref={toast} />

        <div data-value="Résumé" className="card">
          <DataTable
            ref={dt}
            value={
              social_type === "facebook"
                ? tabledata
                : social_type === "instagram"
                ? table_data
                : null
            }
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id"
            paginator
            rows={10}
            tableStyle={{ minWidth: "50rem" }}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Brand"
            globalFilter={globalFilter}
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="name"
              header={t("Benchmark.marque")}
              sortable
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            <Column
              field="page_fans"
              header="Total fans"
              headerStyle={{ fontSize: "14px", lineHeight: "1.07" }}
              sortable
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#344563",
                fontFamily: "inter",
                lineHeight: "1.2",
              }}
              body={(rowData) => rowData.page_fans.toLocaleString()}
            ></Column>

            <Column
              field="posts_count"
              header={t("Benchmark.hors_stories")}
              sortable
              headerStyle={{ fontSize: "14px", lineHeight: "1.07" }}
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#344563",
                fontFamily: "inter",
                lineHeight: "1.2",
              }}
            ></Column>
            <Column
              field="posts_interactions"
              header="Total interactions"
              headerStyle={{ fontSize: "14px", lineHeight: "1.07" }}
              sortable
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#344563",
                fontFamily: "inter",
                lineHeight: "1.2",
              }}
            ></Column>
            <Column
              field="posts_engagement"
              header={t("Performance_report.interaction_rate")}
              headerStyle={{ fontSize: "14px", lineHeight: "1.07" }}
              sortable
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#344563",
                fontFamily: "inter",
                lineHeight: "1.2",
              }}
            ></Column>
            <Column
              field="nbr_posts_jour"
              header={t("Benchmark.posts_per_day")}
              headerStyle={{ fontSize: "14px", lineHeight: "1.07" }}
              sortable
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#344563",
                fontFamily: "inter",
                lineHeight: "1.2",
              }}
            ></Column>
            <Column
              field="post_img"
              header="Posts Images"
              headerStyle={{ fontSize: "14px", lineHeight: "1.07" }}
              sortable
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#344563",
                fontFamily: "inter",
                lineHeight: "1.2",
              }}
            ></Column>
            <Column
              field="post_video"
              header="Posts Videos"
              headerStyle={{ fontSize: "14px", lineHeight: "1.07" }}
              sortable
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#344563",
                fontFamily: "inter",
                lineHeight: "1.2",
              }}
            ></Column>
            <Column
              field="post_reels"
              header="Posts Reels"
              headerStyle={{ fontSize: "14px", lineHeight: "1.07" }}
              sortable
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#344563",
                fontFamily: "inter",
                lineHeight: "1.2",
              }}
            ></Column>
            {social_type === "facebook" && (
              <Column
                field="post_others"
                header={t("Benchmark.Posts_Autres")}
                headerStyle={{ fontSize: "14px", lineHeight: "1.07" }}
                sortable
                style={{
                  width: "12.5%",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#344563",
                  fontFamily: "inter",
                  lineHeight: "1.2",
                }}
              ></Column>
            )}
          </DataTable>
        </div>

        <Dialog
          visible={productDialog}
          style={{ width: "450px" }}
          header="Product Details"
          modal
          className="p-fluid"
          footer={productDialogFooter}
          onHide={hideDialog}
        >
          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              value={product.name}
              onChange={(e) => onInputChange(e, "name")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !product.name,
              })}
            />
            {submitted && !product.name && (
              <small className="p-error">Name is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="description">Description</label>
            <InputTextarea
              id="description"
              value={product.description}
              onChange={(e) => onInputChange(e, "description")}
              required
              rows={3}
              cols={20}
            />
          </div>

          <div className="field">
            <label className="mb-3">Category</label>
            <div className="formgrid grid">
              <div className="field-radiobutton col-6">
                <RadioButton
                  inputId="category1"
                  name="category"
                  value="Accessories"
                  onChange={onCategoryChange}
                  checked={product.category === "Accessories"}
                />
                <label htmlFor="category1">Accessories</label>
              </div>
              <div className="field-radiobutton col-6">
                <RadioButton
                  inputId="category2"
                  name="category"
                  value="Clothing"
                  onChange={onCategoryChange}
                  checked={product.category === "Clothing"}
                />
                <label htmlFor="category2">Clothing</label>
              </div>
              <div className="field-radiobutton col-6">
                <RadioButton
                  inputId="category3"
                  name="category"
                  value="Electronics"
                  onChange={onCategoryChange}
                  checked={product.category === "Electronics"}
                />
                <label htmlFor="category3">Electronics</label>
              </div>
              <div className="field-radiobutton col-6">
                <RadioButton
                  inputId="category4"
                  name="category"
                  value="Fitness"
                  onChange={onCategoryChange}
                  checked={product.category === "Fitness"}
                />
                <label htmlFor="category4">Fitness</label>
              </div>
            </div>
          </div>

          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="posts_count">posts_count</label>
              <InputNumber
                id="posts_count"
                toFixed
                value={product.posts_count}
                // value={100}
                onValueChange={(e) => onInputNumberChange(e, "posts_count")}
                integeronly
              />
              <InputNumber
                id="posts_count-rate"
                value={100}
                onValueChange={(e) => onInputNumberChange(e, "posts_count")}
                integeronly
              />
            </div>
            <div className="field col">
              <label htmlFor="posts_engagement">posts_engagement</label>
              <InputNumber
                id="posts_engagement"
                value={product.posts_engagement.toFixed(3)}
                onValueChange={(e) =>
                  onInputNumberChange(e.toFixed(3), "posts_engagement")
                }
                integeronly
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          visible={deleteProductDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductDialogFooter}
          onHide={hideDeleteProductDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {product && (
              <span>
                Are you sure you want to delete <b>{product.name}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteProductsDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductsDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {product && (
              <span>
                Are you sure you want to delete the selected products?
              </span>
            )}
          </div>
        </Dialog>
      </div>
    );
  };
  /*---- Option 22 */
  let options22 = {
    chart: {
      type: "column",
    },
    title: {
      text: t("Performance_report.audience_growth"),
      align: "left",
      margin: 50,
    },
    subtitle: {
      text: t("Benchmark.audience_growth_desc"),
      align: "left",
      margin: 10,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      labels: {
        enabled: false,
      },
      categories: [],
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          //format: '{point.y:.1f}'
        },
      },
    },
    series: [{}],
  };
  /*--- End option 22 */
  const dataBenchOp = props?.data?.dataBench?.total;
  const arr_data_option22 = [];
  options22.xAxis.categories.push("");

  for (const tt in dataBenchOp) {
    // Vérifier la valeur de tt.page_id et définir la couleur en conséquence
    let color;
    if (dataBenchOp[tt].page_id === 323) {
      //-- Orange
      color = "#f16e00";
    } else if (dataBenchOp[tt].page_id === 325) {
      //-- Ooredoo
      color = "#ed1c24";
    } else if (dataBenchOp[tt].page_id === 330) {
      //-- Tunisie
      color = "#001e8c";
    }

    const arr = {
      name: dataBenchOp[tt].page_name,
      data: [dataBenchOp[tt].page_fans],
      color: color,
    };
    arr_data_option22.push(arr);
  }
  arr_data_option22.map((entry, index) => {
    options22.series.push(entry);
  });

  function remove_empty_value(arr) {
    var index;
    arr.some(function (obj, i) {
      return Object.keys(obj).length === 0 && obj.constructor === Object
        ? ((index = i), true)
        : false;
    });
    arr.shift(index);
    return index;
  }
  remove_empty_value(options22.series);
  /*--- End option 22 */

  const printChart1 = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");
    parent.classList.add("capture_style");

    html2canvas(parent, {
      onclone: (document) => {},
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        parent.classList.remove("capture_style");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  return (
    <div>
      <div className="mb-5">
        <DataTableCrudDemo2 className=""></DataTableCrudDemo2>
        <div className="p-5 bg-white">
          <h2 className="mb-7 text-[24px] font-semibold">
            {t("Benchmark.Sector_Averages")}
          </h2>

          <DataTable
            value={
              social_type === "facebook"
                ? moyData
                : social_type === "instagram"
                ? moy_data
                : null
            }
          >
            <Column
              field="nbr_marque"
              header={t("Benchmark.number_brands")}
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            <Column
              field="page_fans"
              header="Total fans"
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            <Column
              field="total_post"              
              header={
                social_type === "facebook"
                  ? t("Benchmark.hors_stories")
                  : t("Benchmark.hors_reel_stories")
              }
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            <Column
              field="total_interactions"
              header="Total interactions"
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            <Column
              field="taux_interaction"
              header={t("Performance_report.interaction_rate")}
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            <Column
              field="nbr_posts_jour"
              header={t("Benchmark.posts_per_day")}
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            <Column
              field="posts_images"
              header="Posts Images"
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            <Column
              field="posts_videos"
              header="Posts Videos"
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            
            <Column
              field="posts_reals"
              header="Posts Reels"
              style={{
                width: "12.5%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            {social_type === "facebook" && (
              <Column
                field="posts_others"
                header={t("Benchmark.Posts_Autres")}
                style={{
                  width: "12.5%",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#152935",
                  fontFamily: "inter",
                }}
              ></Column>
            )}
          </DataTable>
        </div>

        <div className="p-5 bg-white">
          <h2 className="mb-7 text-[24px] font-semibold">
            {t("Benchmark.Key_Metrics")}
          </h2>

          <DataTable
            value={
              social_type === "facebook"
                ? chiffreKey
                : social_type === "instagram"
                ? chiffre_key
                : null
            }
          >
            <Column
              field="name"
              header=""
              style={{
                //width: "33.33333333%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            <Column
              field="nos_marque"
              header={t("Benchmark.our_brand")}
              bodyStyle={{ textAlign: "center" }}
              className="center_header"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            <Column
              field="verified"
              header=""
              bodyStyle={{ textAlign: "center" }}
              className="center_header"
              style={{
                //width: "33.33333333%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
            <Column
              field="moy_sec"
              header={t("Benchmark.Sector_Averages")}
              bodyStyle={{ textAlign: "center" }}
              className="center_header"
              style={{
                //width: "20%",
                fontSize: "14px",
                fontWeight: "600",
                color: "#152935",
                fontFamily: "inter",
              }}
            ></Column>
          </DataTable>
        </div>
      </div>

      <div data-value="Variations des Fans" className="highchart_block1 mb-5">
        <div className="flex items-center">
          <button
            data-html2canvas-ignore
            onClick={printChart1}
            className="ml-auto mr-1.5 w-5 h-5 print_capture"
          ></button>
        </div>
        <HighchartsReact highcharts={Highcharts} options={options22} />
      </div>
    </div>
  );
};
export default TableCombined;
