import React from "react";
import CroissanceLikesDislikesYoutube from "./CroissanceLikesDislikesYoutube";
import CroissanceVuesYoutube from "./CroissanceVuesYoutube";
import CroissanceAbonnesYoutube from "./CroissanceAbonnesYoutube";
import ListeLecturesYoutube from "./ListeLecturesYoutube";
import PerformanceVisionnageYoutube from "./PerformanceVisionnageYoutube";
import EngagementYoutube from "./EngagementYoutube";
import TypesPublicationYoutube from "./TypesPublicationYoutube";
import TablePaysYoutube from "./TablePaysYoutube";
import CroissanceVideosYoutube from "./CroissanceVideosYoutube";

const YoutubePerformanceChart = (props) => {
  return (
    <div>
      {/* chart types de publications */}
      <TypesPublicationYoutube state={props} />
      <CroissanceVideosYoutube state={props} />

      <CroissanceAbonnesYoutube state={props} />
      

      <CroissanceVuesYoutube state={props} />
      <PerformanceVisionnageYoutube state={props} />
      
      <CroissanceLikesDislikesYoutube state={props} />

      <ListeLecturesYoutube state={props} />

      {/* chart engagement */}
      <EngagementYoutube state={props} />

      <TablePaysYoutube state={props} />
    </div>
  );
};

export default YoutubePerformanceChart;
