import React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Highcharts from "highcharts";
import { Dropdown } from "primereact/dropdown";
import HighchartsReact from "highcharts-react-official";
import { AppContext, API } from "../../utils";
import html2canvas from "html2canvas";
import DropdownImg from "../../images/dropdown1.png";

//mport exporting from "highcharts/modules/exporting";
//exporting(Highcharts);
const StackedBar = (props) => {
  //console.log("props-StackedBar =", props);

  const socialType = props.data.socialType;
  const { t, i18n } = useTranslation();
  const [state, setState] = React.useContext(AppContext);
  function convert_format_date(date_to_convert) {
    let date = new Date(date_to_convert);

    // Get year, month, and day part from the date
    let year = date.toLocaleString("default", { year: "numeric" });
    let month = date.toLocaleString("default", { month: "2-digit" });
    let day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    let formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }

  function day_or_month(date_one, date_two) {
    let date1 = new Date(date_one);
    let date2 = new Date(date_two);

    let diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let type = " ";
    if (diffDays <= 31) {
      type = "day";
    } else {
      type = "month";
    }

    return type;
  }

  let date_form = convert_format_date(state.reportingPeriod[0].toDateString());
  let date_to = convert_format_date(state.reportingPeriod[1].toDateString());

  const [type_option, setType_option] = useState(
    day_or_month(date_form, date_to)
  );
  const [type_option1, setType_option1] = useState(
    day_or_month(date_form, date_to)
  );
  const [valueDropdownOption, setValueDropdownOption] = useState(
    day_or_month(date_form, date_to)
  );
  const [valueDropdownOption1, setValueDropdownOption1] = useState(
    day_or_month(date_form, date_to)
  );

  let options = {
    chart: {
      type: "spline",
      style: {
        fontFamily: "Inter",
      },
    },
    title: {
      text: t("Performance_report.engagement_growth"),
      align: "left",
      margin: 50,
    },
    subtitle: {
      text: t("Performance_report.engagement_growth_desc"),
      align: "left",
      margin: 50,
    },

    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
          textOutline: "none",
        },
      },
    },
    legend: {
      reversed: true,
    },
    tooltip: {
      shared: true,
      crosshairs: true,
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        marker: {
          radius: 4,
          //   lineColor: "#596ae9",
          lineWidth: 1,
          enabled: false,
        },
      },
    },

    series: [{}],
  };

  let options1 = {
    chart: {
      type: "spline",
      style: {
        fontFamily: "Inter",
      },
    },
    title: {
      text: t("Performance_report.post_engagement_rate"),
      align: "left",
      margin: 50,
    },
    subtitle: {
      text: t("Performance_report.post_engagement_rate_desc"),
      align: "left",
      margin: 50,
    },

    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
          textOutline: "none",
        },
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        marker: {
          radius: 4,
          //   lineColor: "#596ae9",
          lineWidth: 1,
          enabled: false,
        },
      },
    },

    series: [{}],
  };

  //-- day data
  const day_array = [];
  const day_array_posts_reactions = [];
  const day_array_posts_comments = [];
  const day_array_posts_shares = [];
  const day_array_posts_clicks = [];
  const day_array_posts_reach = [];

  const my_data = props.data.by_day;
  const my_data_reels = props.data.post_type.reels.by_day;

  const allDates = [...new Set(Object.keys(my_data))];
  allDates.sort();

  allDates.forEach((date) => {
    // Push the date to array_data
    day_array.push(date);

    // If the date exists in the data, push the reactions; otherwise, push 0
    day_array_posts_reactions.push(
      my_data[date]
        ? (my_data[date].posts_reactions || 0) +
            (my_data[date].posts_likes_ad || 0) + (my_data_reels[date].total_reactions_reel || 0)
        : 0
    );

    // If the date exists in the data, push the comments; otherwise, push 0
    day_array_posts_comments.push(
      my_data[date]
        ? (my_data[date].posts_comments || 0) +
            (my_data[date].posts_comments_ad || 0) +(my_data_reels[date].total_comments_reel || 0)
        : 0
    );

    // If the date exists in the data, push the shares; otherwise, push 0
    day_array_posts_shares.push(
      my_data[date] ? (my_data[date].posts_shares || 0) + +(my_data_reels[date].total_shares_reel || 0) : 0
    );

    // If the date exists in the data, push the clicks; otherwise, push 0
    day_array_posts_clicks.push(
      my_data[date] ? my_data[date].posts_clicks || 0 : 0
    );

    // If the date exists in the data, push -Math.abs(posts_reach); otherwise, push 0
    day_array_posts_reach.push(
      my_data[date]
        ? (-Math.abs(my_data[date].posts_reach) || 0) +
            (-Math.abs(my_data[date].posts_reach_ad) || 0)
        : 0
    );
  });

  const propsDay = Object.entries(props.data.by_day);
  const propsDayReel = Object.entries(props.data.post_type.reels.by_day);

  const day_posts_reactions = {
    name: t("Performance_report.reactions"),
    data: day_array_posts_reactions,
  };

  const day_posts_comments = {
    name: t("Performance_report.comments"),
    data: day_array_posts_comments,
  };

  const day_posts_shares = {
    name: t("Performance_report.shares"),
    data: day_array_posts_shares,
  };

  const day_posts_clicks = {
    name: t("Performance_report.clics"),
    data: day_array_posts_clicks,
  };

  function day_data_option() {
    options.series.push(day_posts_reactions);
    options.series.push(day_posts_comments);
    options.series.push(day_posts_shares);
    if (socialType == "facebook") options.series.push(day_posts_clicks);
    sort_array(day_array).map((entry, index) => {
      options.xAxis.categories.push(entry);
    });
  }

  //-- month data
  const myData = props.cmpData.data;
  //const data_reel = props

  const calculateSums = (data, props) => {
    const sums = {};

    data.forEach(item => {
      const dateName = item.name.split(" ")[0]; // Prend le premier mot de 'name' (April ou May)
      const correspondingProps = Object.values(props).find(p => p.date_name === dateName);
      
      if (correspondingProps) {
        sums[dateName] = (sums[dateName] || 0) + (item.posts_reactions || 0) + (correspondingProps.total_reactions_reel || 0);
      } else {
        sums[dateName] = (sums[dateName] || 0) + (item.posts_reactions || 0);
      }
    });

    // Assurez-vous de couvrir tous les date_names dans props même s'ils ne sont pas dans myData
    Object.values(props).forEach(p => {
      if (!(p.date_name in sums)) {
        sums[p.date_name] = (sums[p.date_name] || 0) + (p.total_reactions_reel || 0);
      }
    });

    return sums;
  };
  
  const posts_reactions_array = myData.map((item) => item.posts_reactions);

  const sums = calculateSums(myData, props.data.post_type.reels.by_month);
  const reactionsArray = Object.values(sums);

  const posts_reactions = {
    name: "Réactions",
    data: reactionsArray,
  };

  const posts_comments_array = myData.map((item) => item.posts_comments);
  const posts_comments = {
    name: "Commentaires",
    data: posts_comments_array,
  };

  const posts_shares_array = myData.map((item) => item.posts_shares);
  const posts_shares = {
    name: "Partages",
    data: posts_shares_array,
  };

  const posts_clicks_array = myData.map((item) => item.posts_clicks);
  const posts_clicks = {
    name: "Clics",
    data: posts_clicks_array,
  };

  const status = [
    { name: t("Options.month"), code: "month" },
    { name: t("Options.day"), code: "day" },
  ];

  function month_data_option() {
    options.series.push(posts_reactions);
    options.series.push(posts_comments);
    options.series.push(posts_shares);
    if (socialType == "facebook") options.series.push(posts_clicks);
    props.cmpData.data.map((entry, index) => {
      options.xAxis.categories.push(entry.name);
    });
  }

  //-- option
  type_option === "month" ? month_data_option() : day_data_option();
  const onOptionChange = (e) => {
    setValueDropdownOption(e.value);
    if (e.value === "month") setType_option("month");
    if (e.value === "day") setType_option("day");
  };

  //-- option1

  //-- day data
  const engagement_posts_perday = [];

if (propsDay.length === propsDayReel.length) {
  propsDay.forEach(([key, value], index) => {
    const [keyReel, valueReel] = propsDayReel[index];

    //console.log("Current date:", key, "Current index:", index);
    //console.log("propsDay value:", value);
    //console.log("propsDayReel value:", valueReel);

    if (key === keyReel) {
      //console.log("Dates match:", key);
      //day_array.push(key);

      // Calcul du taux d'engagement
      const taux_engagement_posts = value.posts_reach ? (((value.posts_reactions + value.posts_comments + value.posts_shares + value.posts_clicks) /
        value.posts_reach) * 100).toFixed(2) : 0;
      const taux_engagement_reels = valueReel.total_reach_reel ? (((valueReel.total_reactions_reel + valueReel.total_comments_reel + valueReel.total_shares_reel) /
        valueReel.total_reach_reel) * 100).toFixed(2) : 0;

      // Ajouter la somme des taux d'engagement à engagement_posts_perday
      engagement_posts_perday.push(parseFloat(taux_engagement_posts) + parseFloat(taux_engagement_reels));
      
    } else {
      console.log("Dates do not match!");
    }
  });
} 

//console.log("day_array:", day_array);
//console.log("engagement_posts_perday:", engagement_posts_perday);

const day_taux_engagement = {
  name: "Taux engagement",
  data: engagement_posts_perday,
};

  function day_data_option1() {
    options1.series.push(day_taux_engagement);
    sort_array(day_array).map((entry, index) => {
      options1.xAxis.categories.push(entry);
    });
  }

  let sum_taux_day = day_taux_engagement.data.reduce((x, y) => {
    return x + y;
  }, 10);

  let moy_day_option1 =
    sum_taux_day /
    (day_taux_engagement.data.length -
      day_taux_engagement.data.filter((v) => v === 0).length);
      //-- month data
  

  let taux_engagement = 0;
  let taux_engagement_array = [];
  for (let tt in myData) {
    taux_engagement = myData[tt].posts_reach ? 
    (((myData[tt].posts_clicks + myData[tt].posts_comments + myData[tt].posts_reactions + myData[tt].posts_shares) / (myData[tt].posts_reach)) *100).toFixed(2)
    : 0;
    taux_engagement_array.push(+taux_engagement);
  }

  const taux_engagement_evolution = {
    name: "Taux engagement",
    data: taux_engagement_array,
  };

  function month_data_option1() {
    options1.series.push(taux_engagement_evolution);
    props.cmpData.data.map((entry, index) => {
      options1.xAxis.categories.push(entry.name);
    });
  }

  let sum_taux_month = taux_engagement_evolution.data.reduce((x, y) => {
    return x + y;
  }, 10);

  let moy_month_option1 =
    sum_taux_month /
    (taux_engagement_evolution.data.length -
      taux_engagement_evolution.data.filter((v) => v === 0).length);

  type_option1 === "month" ? month_data_option1() : day_data_option1();

  /*let minDayIndex = 0;
  let maxDayIndex = 0;
  $yy.forEach(function (val, index) {
    if (val === Math.min(...$yy)) minDayIndex = index;
    if (val === Math.max(...$yy)) maxDayIndex = index;
  });*/

  let total_post = props.data?.total?.posts_count ?? 0;
  let total_engagement_clicks =
    props.data.total.posts_type_interactions.Clicks ?? 0;
  let total_engagement_commentaires =
    props.data.total.posts_type_interactions.Commentaires ?? 0;
  let total_engagement_partages =
    props.data.total.posts_type_interactions.Partages ?? 0;
  let total_engagement_reactions =
    props.data.total.posts_type_interactions.Réactions ?? 0;
  let total_engagement =
    total_engagement_clicks +
      total_engagement_commentaires +
      total_engagement_partages +
      total_engagement_reactions ?? 0;

  let taux_engagement_val = 0;
  if (socialType == "facebook") {
    taux_engagement_val = props.data?.total?.posts_reach
      ? (total_engagement / props.data?.total?.posts_reach) * 100 ?? 0
      : 0;
  } else if (socialType == "instagram") {
    taux_engagement_val = props.data?.instagram?.total?.reach
      ? (total_engagement / props.data?.instagram?.total?.reach) * 100 ?? 0
      : 0;
  } else if (socialType == "linkedin") {
    taux_engagement_val = props.data?.total?.page_impressions_organic_unique
      ? (total_engagement /
          props.data?.total?.page_impressions_organic_unique) *
          100 ?? 0
      : 0;
  }

  let moy_value = total_post ? taux_engagement_val / total_post : 0;

  let min_day_option1 = Math.min(...engagement_posts_perday.filter(Number));
  let minDayIndex = engagement_posts_perday.indexOf(min_day_option1);

  let max_day_option1 = Math.max(...engagement_posts_perday.filter(Number));
  let maxDayIndex = engagement_posts_perday.indexOf(max_day_option1);
  //let moy_day_option1 = (((min_day_option1 + max_day_option1) / $yy.length) * 100).toFixed(2);
  let min_date_day_option1 = day_array[minDayIndex];
  let max_date_day_option1 = day_array[maxDayIndex];

  let minMonthIndex = 0,
    maxMonthIndex = 0;
  taux_engagement_array.forEach(function (val, index) {
    if (val === Math.min(...taux_engagement_array)) minMonthIndex = index;
    if (val === Math.max(...taux_engagement_array)) maxMonthIndex = index;
  });

  let min_month_option1 = Math.min(...taux_engagement_array.filter(Number));
  let max_month_option1 = Math.max(...taux_engagement_array.filter(Number));
  //let moy_month_option1 = (((min_month_option1 + max_month_option1) / taux_engagement_array.length) * 100).toFixed(2);
  let min_date_month_option1 = props.cmpData.data[minMonthIndex]?.name
    ? props.cmpData.data[minMonthIndex].name
    : 0;
  let max_date_month_option1 = props.cmpData.data[maxMonthIndex]?.name
    ? props.cmpData.data[maxMonthIndex].name
    : 0;

  let dayInfo = [
    { min_value: min_day_option1 },
    { max_value: max_day_option1 },
    { moy_value: (min_day_option1 + max_day_option1) / 2 },
    { min_date: min_date_day_option1 },
    { max_date: max_date_day_option1 },
  ];

  let monthInfo = [
    { min_value: min_month_option1 },
    { max_value: max_month_option1 },
    //{ moy_value: moy_month_option1 },
    { moy_value: (min_month_option1 + max_month_option1) / 2 },
    { min_date: min_date_month_option1 },
    { max_date: max_date_month_option1 },
  ];

  let info_value = type_option1 == "day" ? dayInfo : monthInfo;
  const [list, setList] = useState(info_value);

  const onOptionChange1 = (e) => {
    setValueDropdownOption1(e.value);
    if (e.value === "month") {
      setType_option1("month");
      setList(monthInfo);
    }

    if (e.value === "day") {
      setType_option1("day");
      setList(dayInfo);
    }
  };

  function remove_empty_value(arr) {
    var index;
    arr.some(function (obj, i) {
      return Object.keys(obj).length === 0 && obj.constructor === Object
        ? ((index = i), true)
        : false;
    });
    arr.shift(index);
    return index;
  }

  remove_empty_value(options.series);
  remove_empty_value(options1.series);

  function sort_array(dates) {
    dates.sort(function (a, b) {
      let dateA = new Date(a);
      let dateB = new Date(b);
      return dateA - dateB;
    });
    return dates;
  }

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");
    parent.classList.add("capture_style");

    html2canvas(parent, {
      onclone: (document) => {},
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        parent.classList.remove("capture_style");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  return (
    <div>
      <div className="highchart_block">
        <div
          data-value="Croissance de l’engagement"
          className="highchart_block1"
        >
          <div data-html2canvas-ignore className="flex items-center">
            <div className="flex items-center ml-auto border rounded-md capitalize">
              <img src={DropdownImg} className="pl-2" alt="DropdownImg" />
              <Dropdown
                value={valueDropdownOption}
                options={status}
                onChange={onOptionChange}
                defaultValue={setType_option}
                optionLabel="name"
                optionValue="code"
                placeholder="Choisir"
                className="font-medium"
              />
            </div>

            <button
              onClick={printChart}
              className="mx-5 w-5 h-5 print_capture"
            ></button>
          </div>

          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>

      {socialType == "facebook" ? (
        <div className="highchart_block">
          <div
            data-value="Croissance du taux de l'engagement"
            className="highchart_block1"
          >
            <div data-html2canvas-ignore className="flex items-center">
              <div className="flex items-center ml-auto border rounded-md capitalize">
                <img src={DropdownImg} className="pl-2" alt="DropdownImg" />
                <Dropdown
                  value={valueDropdownOption1}
                  options={status}
                  onChange={onOptionChange1}
                  defaultValue={setType_option1}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Choisir"
                  className="font-medium"
                />
              </div>

              <button
                onClick={printChart}
                className="mx-5 w-5 h-5 print_capture"
              ></button>
            </div>
            <HighchartsReact highcharts={Highcharts} options={options1} />

            <div className="flex calc_taux mt-10 pb-5">
              <div className="flex-auto px-8">
                <h2 className="text-[#344563] text-[18px] font-bold font-['inter'] text-center border-[#dee0e1] mb-2">
                  Min
                </h2>
                <span className="text-[#5a6872] text-[14px] font-bold font-['inter'] text-center block">
                  {list[0].min_value}%
                </span>
                <span className="text-[#5a6872] text-[14px] font-bold font-['inter'] text-center block">
                  {list[3].min_date}
                </span>
              </div>
              <div className="flex-auto px-8">
                <h2 className="text-[#344563] text-[18px] font-bold font-['inter'] text-center border-[#dee0e1] mb-2">
                  Max
                </h2>
                <span className="text-[#5a6872] text-[14px] font-bold font-['inter'] text-center block">
                  {list[1].max_value}%
                </span>
                <span className="text-[#5a6872] text-[14px] font-bold font-['inter'] text-center block">
                  {list[4].max_date}
                </span>
              </div>
              <div className="flex-auto px-8">
                <h2 className="text-[#344563] text-[18px] font-bold font-['inter'] text-center border-[#dee0e1] mb-2">
                  {t("Options.moyenne")}
                </h2>
                <span className="text-[#5a6872] text-[14px] font-bold font-['inter'] text-center block">
                  {list[2].moy_value.toFixed(2)}%
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default StackedBar;
