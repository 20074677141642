import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";

const Welcome = () => {
  const notify = () => toast("Here is your toast.");
  const { t, i18n } = useTranslation();

  return (
    <div>      
      <div className="felx lex-col items-center mt-48 ml-[636px]">
        <p className="font-bold text-4xl">{t("welcome.welcome_msg")} 👋</p>
        <p className="text-dgrey font-bold text-lg">
          {t("welcome.welcome_msg2")}
        </p>
      </div>
      {/* <button onClick={notify}>Make me a toast</button> */}
      {/* <Toaster /> */}
    </div>
  );
};

export default Welcome;
