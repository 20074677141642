import React, { useState } from "react";

import { Dialog } from "primereact/dialog";
import { AppContext, API, toast } from "../utils";
import { useTranslation } from "react-i18next";

const SuccessModals = (props) => {
  const [count, setCount] = useState(0);
  const [name, setName] = useState("");
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");
  const [paths, setUrls] = useState([]);

  //console.log("paths", name, paths);
  // const [t, i18n] = useTranslation();
  const addCateg = async () => {
    try {
      if (paths.length) {
        const resp = await API.categories.add(name, paths);
        console.log(resp.data?.message);
        toast.success(resp.data?.message);
        if (resp.status == "200") {
          props.onGetCateg && props.onGetCateg();
          props.onHide && props.onHide();
        }
      } else {
        toast.error("Please add at least 1 page");
      }
    } catch (e) {
      toast.error(e.response.data?.message);
    }
  };

  const d = (n) => {
    const addPaths = (e) => {
      // n == 1
      //   ? (e) => setUrl1(e.target.value)
      //   : n === 2
      //   ? (e) => setUrl2(e.target.value)
      //   : null;
      // paths[n] = e.target.value;
    };
    return (
      <div className="divisions">
        <p className="text-asap-purple font-semibold font-Montserrat text-sm mt-8 text-center underline">
          Facebook URL {n + 1} :
          <span className="inline">
            <input
              className="border ml-2 py-1 rounded-lg focus:outline-none border-diggow-blue"
              type="text"
              onChange={(e) => (paths[count] = e.target.value)}
            />
          </span>
        </p>
      </div>
    );
  };

  const [division, setDivision] = useState([]);

  const addDiv = () => {
    setCount(count + 1);
    setDivision([...division, d(count)]);
  };

  const succsessFooter = (
    <div className="justify-center flex">
      <button
        onClick={addCateg}
        onOK={props.onClick}
        className="mt-14 px-28 mx-auto hover:opacity-90  font-Montserrat bg-gradient-to-r text-xs font-medium from-asap-pGradient  mb-5  to-diggow-blue py-4 rounded-full  text-asap-white"
      >
        {/* {t("Ajouter")} */}Ajouter
      </button>
    </div>
  );
  return (
    <Dialog
      footer={succsessFooter}
      breakpoints={{ "960px": "95vw" }}
      style={{ width: "30vw" }}
      className={
        "text-asap-orange font-thin bg-asap-lowWhite text-xl font-Montserrat  rounded-3xl p-3  no-background-dialog"
      }
      {...props}
    >
      {/* header */}
      <div className="flex flex-col justify-center mx-auto  ">
        <h1 className="text-diggow-blue font-bold font-Montserrat mx-auto  md:text-2xl ">
          {/* {t("Ajouter une categorie")}*/} Ajouter une categorie !
        </h1>

        <p className="text-asap-purple font-semibold font-Montserrat text-sm mt-8 text-center">
          {/* {t("Nom de categorie")} : */}Nom de categorie :
          <span className="inline">
            <input
              onChange={(e) => setName(e.target.value)}
              className="border ml-4 py-1 rounded-lg focus:outline-none border-diggow-blue"
              type="text"
            />
          </span>
        </p>
      </div>

      {/* body */}
      <div className="flex  flex-col justify-center  gap-2">
        {division.map((el, i) => (
          <div key={i}>{el}</div>
        ))}
        <button
          disabled={division.length > 4}
          onClick={() => addDiv()}
          className="rounded-full text-xl bg-diggow-blue text-asap-white px-2 hover:opacity-80 mx-auto mt-2"
        >
          +
        </button>
      </div>
      {/* footer */}
    </Dialog>
  );
};

export default SuccessModals;
