import React from "react";
import TableCombinedYoutube from "../../../Reporting/Youtube/TableCombinedYoutube";
import ComparisonSectorYoutube from "./ComparisonSectorYoutube";
import CroissancesChartesYoutube from "./CroissancesChartesYoutube";


const YoutubeBenchmarkChart = (props) => {
  return (
    <div>
      <TableCombinedYoutube state={props} />
      <ComparisonSectorYoutube state={props} />
      <CroissancesChartesYoutube state={props} />
    </div>
  );
};

export default YoutubeBenchmarkChart;
