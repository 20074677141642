import React from "react";
import { useTranslation } from "react-i18next";
import fansIcon from "../../../images/Users.png";
import newFansIcon from "../../../images/newFan.png";
import engagementIcon from "../../../images/engagement.png";
import impressionIcon from "../../../images/impression.png";

import { ReactComponent as PlusIcon } from "../../../images/svg/icon_plus.svg";

import { ReactComponent as FansIcon } from "../../../images/svg/icon_total_fans.svg";
import { ReactComponent as NewFansIcon } from "../../../images/svg/icon_new_fans.svg";
import { ReactComponent as TotalEngIcon } from "../../../images/svg/icon_total_eng.svg";
import { ReactComponent as TauxEngIcon } from "../../../images/svg/icon_taux_eng.svg";
import { ReactComponent as ArrowUp } from "../../../images/arrowUp.svg";

import millify from "millify";
import { AppContext, API, useUtils } from "../../../utils";
import { YoutubeOverview } from "./OverviewPanels/YoutubeOverview";

const ReportingOverview = (props) => {
  //console.log('ReportingOverview=', props);
  let reportingData = props.reportingData;
  let utils = useUtils();
  let total_engagement_clicks = reportingData.total.posts_clicks ?? 0;
  let total_engagement_commentaires =
    reportingData.total.posts_type_interactions.Commentaires ?? 0;
  let total_engagement_partages =
    reportingData.total.posts_type_interactions.Partages ?? 0;
  let total_engagement_reactions =
    reportingData.total.posts_type_interactions.Réactions ?? 0;

  let total_engagement =
    total_engagement_clicks +
      total_engagement_commentaires +
      total_engagement_partages +
      total_engagement_reactions ?? 0;

  const reel_total = reportingData?.post_type?.reels;
  const reel_engagement = reel_total.total.engagement;

  if (reportingData.socialType == "facebook") {
    total_engagement += reel_engagement;
  }
  //let taux_engagement = (reportingData.total.posts_reach) ? ((total_engagement / reportingData.total.posts_reach) * 100 ?? 0) : 0;

  let page_post_engagements = +reportingData.total.page_post_engagements ?? 0;
  let variation_reachs =
    reportingData.total.reach_page.var_impressions_uniques_total_prev ?? 0;

  let variation_count_posts =
    reportingData.total.previous_total.variation_count_posts ?? 0;
  let variation_page_fans =
    reportingData.total.previous_total.variation_page_fans ?? 0;

  let variation_engagement_posts =
    reportingData.total.previous_total.variation_engagement_posts ?? 0;
  const variation_reel = reel_total.variation.engagement;
  if (reportingData.socialType == "facebook") {
    variation_engagement_posts += variation_reel;
  }

  let variation_engagement_posts_page =
    reportingData.total.previous_total.variation_page_post_engagements ?? 0;
  let variation_taux_engagement_posts =
    reportingData.total.previous_total.variation_taux_engagement_posts ?? 0;
  let variation_taux_engagement_page =
    reportingData.total.previous_total.previous_taux_engagement_page ?? 0;

  const socialType = reportingData.socialType;

  let reach = 0;
  let taux_engagement = 0;
  if (socialType == "facebook") {
    reach = reportingData.total.reach_page.impressions_uniques_total
      ? (reportingData?.total?.reach_page.impressions_uniques_total).toLocaleString() ??
        0
      : 0;

      
    taux_engagement = reportingData?.total?.posts_reach
      ? (reportingData.total.page_post_engagements /
          reportingData?.total?.posts_reach) *
          100 ?? 0
      : 0;
  } else if (socialType == "instagram") {
    reach = reportingData?.instagram?.total?.reach
      ? (reportingData?.instagram?.total?.reach).toLocaleString() ?? 0
      : 0;
    taux_engagement = reportingData?.instagram?.total?.reach
      ? (total_engagement / reportingData?.instagram?.total?.reach) * 100 ?? 0
      : 0;
  } else if (socialType == "linkedin") {
    reach = reportingData?.total?.linkedin?.reach_linkedin
      ? (reportingData?.total?.linkedin?.reach_linkedin).toLocaleString() ?? 0
      : 0;
    taux_engagement = reportingData?.total?.linkedin?.reach_linkedin
      ? (total_engagement / reportingData?.total?.linkedin?.reach_linkedin) *
          100 ?? 0
      : 0;
  }

  const total_interaction =
    reportingData?.total?.total_interaction?.total_interaction;
  const variation_interaction =
    reportingData?.total?.total_interaction?.variation_interaction;


  // total followers
  console.log('reportingData ==>', reportingData.total.previous_total);
  const page_follows = reportingData?.total?.previous_total?.actuel_page_followers;
  const page_follows_previous = reportingData?.total?.previous_total?.previous_page_followers;
  const page_follows_variation = reportingData?.total?.previous_total?.variation_page_follows;

  /*----- tiktok */
  const tiktok_data = props?.reportingData?.tiktok;
  const following_tiktok = +tiktok_data?.insights?.following ?? 0;
  const followers_tiktok = +tiktok_data?.insights?.followers ?? 0;
  const total_fans_tiktok = +tiktok_data?.insights?.page_fans ?? 0;
  const videos_tiktok = +tiktok_data?.videos?.total.count_videos ?? 0;
  const { t, i18n } = useTranslation();
  return socialType === "youtube" ? (
    <YoutubeOverview props={reportingData} />
  ) : (
    <div>
      <div className="flex font-bold text-2xl pt-20">
        {/* Récapitulatif des performances */}
        <h1>{t("Performance_report.performance_overview")}</h1>
        <div
          onClick={() => utils.goTo("/reporting/:pageId/stats")}
          className="flex items-center text-[#7f56d9] font-semibold text-[14px] ml-auto cursor-pointer bg-white px-3 rounded-[8px]"
        >
          <span className="mr-2">
            <PlusIcon />
          </span>
          {t("Performance_report.indicators")}{" "}
        </div>
      </div>
      <div className="flex justify-between mx-6">
        {/* <p className="highcharts-subtitle">
          Consultez les mesures clés de la performance de votre profil ou page
          pendant la période définie pour le rapport.
        </p> */}
      </div>

      <div
        id="performance_overview"
        className=" mt-8 flex gap-6 justify-center text-gray-700 text-sm 2xl:text-base"
      >
        {/* cards */}
        <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
          <div className="flex items-center">
            <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
              <FansIcon />
            </div>
            <h2 className="text-[#101828] text-[16px] font-medium ml-4">
              {t("Performance_report.Total_fans")}
            </h2>
          </div>
          <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
            {socialType === "tiktok"
              ? total_fans_tiktok?.toLocaleString()
              : reportingData?.total?.page_fans
              ? reportingData.total.page_fans.toLocaleString()
              : "0"}
          </h3>
          {socialType == "facebook" ? (
            <div className="flex mt-6 items-center">
              <div className="check_upgrade mr-2">
                <ArrowUp
                  className={`${variation_page_fans > 0 ? null : "arrowDown"} `}
                />
              </div>
              <span
                className={`${
                  variation_page_fans > 0 ? "text-[#027a48]" : "text-[#b42318]"
                } text-[14px] font-['inter'] font-bold`}
              >
                {variation_page_fans > 0 ? "+" : null}
                {variation_page_fans
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                %
              </span>
            </div>
          ) : null}
        </div>
        {/* cards */}
       
        {/* cards */}
        {socialType === "facebook" &&
        <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
          <div className="flex items-center">
            <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
              <FansIcon />
            </div>
            <h2 className="text-[#101828] text-[16px] font-medium ml-4">
              {t("Performance_report.total_followers")}
            </h2>
          </div>
          <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
          {socialType !== "tiktok" &&
            (page_follows?.toLocaleString() || "0")
          }
          </h3>
          {socialType == "facebook" ? (
            <div className="flex mt-6 items-center">
              <div className="check_upgrade mr-2">
                <ArrowUp
                  className={`${page_follows_variation > 0 ? null : "arrowDown"} `}
                />
              </div>
              <span
                className={`${
                  page_follows_variation > 0 ? "text-[#027a48]" : "text-[#b42318]"
                } text-[14px] font-['inter'] font-bold`}
              >
                {page_follows_variation > 0 ? "+" : null}
                {page_follows_variation
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                %
              </span>
            </div>
          ) : null}
        </div>
        }
        {/* cards */}

        <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
          <div className="flex items-center">
            <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
              <NewFansIcon />
            </div>

            {socialType === "tiktok" ? (
              <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                {t("Performance_report.total_Subscribers")}
              </h2>
            ) : (
              <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                {t("Performance_report.page_reach")}
              </h2>
            )}
          </div>
          <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
            {socialType === "tiktok"
              ? followers_tiktok?.toLocaleString()
              : reach}
          </h3>
          {socialType == "facebook" ? (
            <div className="flex mt-6 items-center">
              <div className="check_upgrade mr-2">
                <ArrowUp
                  className={`${variation_reachs > 0 ? null : "arrowDown"} `}
                />
              </div>
              <span
                className={`${
                  variation_reachs > 0 ? "text-[#027a48]" : "text-[#b42318]"
                } text-[14px] font-['inter'] font-bold`}
              >
                {variation_reachs > 0 ? "+" : null}
                {variation_reachs.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] ||
                  0}
                %
              </span>
            </div>
          ) : null}
        </div>

        {socialType == "tiktok1" && (
          <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
            <div className="flex items-center">
              <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
                <NewFansIcon />
              </div>
              <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                {t("Performance_report.interaction_post")}
              </h2>
            </div>
            <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
              {total_interaction}
            </h3>
            <div className="flex mt-6 items-center">
              <div className="check_upgrade mr-2">
                <ArrowUp
                  className={`${
                    variation_interaction > 0 ? null : "arrowDown"
                  } `}
                />
              </div>
              <span
                className={`${
                  variation_interaction > 0
                    ? "text-[#027a48]"
                    : "text-[#b42318]"
                } text-[14px] font-['inter'] font-bold`}
              >
                {variation_interaction > 0 ? "+" : null}
                {variation_interaction || 0}%
              </span>
            </div>
          </div>
        )}

        {/* cards */}
        <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
          <div className="flex items-center">
            <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
              <TotalEngIcon />
            </div>

            {socialType === "tiktok" ? (
              <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                {t("Performance_report.total_subscription")}
              </h2>
            ) : (
              <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                {t("Performance_report.Posts_Engagements")}
              </h2>
            )}
          </div>
          <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
            {socialType === "tiktok"
              ? following_tiktok?.toLocaleString()
              : total_engagement.toLocaleString() || 0}
          </h3>
          {socialType == "facebook" ? (
            <div className="flex mt-6 items-center">
              <div className="check_upgrade mr-2">
                <ArrowUp
                  className={`${
                    variation_engagement_posts > 0 ? null : "arrowDown"
                  } `}
                />
              </div>
              <span
                className={`${
                  variation_engagement_posts > 0
                    ? "text-[#027a48]"
                    : "text-[#b42318]"
                } text-[14px] font-['inter'] font-bold`}
              >
                {variation_engagement_posts > 0 ? "+" : null}
                {variation_engagement_posts
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                %
              </span>
            </div>
          ) : null}
        </div>

        {/* cards */}
        {socialType == "facebook" && (
          <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
            <div className="flex items-center">
              <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
                <TotalEngIcon />
              </div>
              <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                {t("Performance_report.Page_Engagements")}
              </h2>
            </div>
            <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
              {page_post_engagements.toLocaleString()}
            </h3>
            {socialType == "facebook" ? (
              <div className="flex mt-6 items-center">
                <div className="check_upgrade mr-2">
                  <ArrowUp
                    className={`${
                      variation_engagement_posts_page > 0 ? null : "arrowDown"
                    } `}
                  />
                </div>
                <span
                  className={`${
                    variation_engagement_posts_page > 0
                      ? "text-[#027a48]"
                      : "text-[#b42318]"
                  } text-[14px] font-['inter'] font-bold`}
                >
                  {variation_engagement_posts_page > 0 ? "+" : null}
                  {variation_engagement_posts_page
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                  %
                </span>
              </div>
            ) : null}
          </div>
        )}

        {/* cards */}
        <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
          <div className="flex items-center">
            <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
              <TauxEngIcon />
            </div>
            {socialType === "tiktok" ? (
              <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                {t("Performance_report.total_videos")}
              </h2>
            ) : (
              <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                {t("Performance_report.page_engagement_rate")}
              </h2>
            )}
          </div>
          <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
            {socialType === "tiktok"
              ? videos_tiktok?.toLocaleString()
              : taux_engagement.toFixed(3) + "%" || 0}
          </h3>
          {socialType == "facebook" ? (
            <div className="flex mt-6 items-center">
              <div className="check_upgrade mr-2">
                <ArrowUp
                  className={`${
                    variation_taux_engagement_page > 0 ? null : "arrowDown"
                  } `}
                />
              </div>
              <span
                className={`${
                  variation_taux_engagement_page > 0
                    ? "text-[#027a48]"
                    : "text-[#b42318]"
                } text-[14px] font-['inter'] font-bold`}
              >
                {variation_taux_engagement_page > 0 ? "+" : null}
                {variation_taux_engagement_page
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                %
              </span>
            </div>
          ) : null}
        </div>

        {/* cards */}
      </div>
    </div>
  );
};

export default ReportingOverview;
