import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { AppContext, API, useUtils} from "../utils";
import { Toaster } from "react-hot-toast";
import { InputText } from "primereact/inputtext";

import Slice1 from "../images/images/Slice1.svg";
import body_login from "../images/my_images/body_login.png";

const ResetPassword = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const { t, i18n } = useTranslation();
    

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const data = urlParams.get('data');

        if (data) {
            API.pages.fetchResetData(data)
                .then(response => {
                    console.log("Fetched token:", response.data.token); // Débogage
                    console.log("Fetched email:", response.data.email); // Débogage
                    setToken(response.data.token);
                    setEmail(response.data.email);
                })
                .catch(error => {
                    console.error('Error fetching reset data:', error); // Débogage
                    toast.error('Invalid or expired reset link.');
                });
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!email || !token || !password || !passwordConfirmation) {
                toast.error('Please fill in all fields.');
                return;
            }

            const data = {
                email: email,
                token: token,
                password: password,
                password_confirmation: passwordConfirmation
            };

            await API.pages.resetsPassword(data);
            toast.success('Password reset successfully');
            await new Promise(resolve => setTimeout(resolve, 2000));
            navigate('/login');
        } catch (error) {
            console.error('Error resetting password:', error); // Affiche l'erreur dans la console
            if (error.response && error.response.data) {
                const { errors, message } = error.response.data;
                if (errors) {
                    console.error('Validation Errors:', errors); // Affiche les erreurs dans la console
                    if (errors.email) {
                        toast.error(`Error: ${errors.email.join(', ')}`);
                    }
                    if (errors.token) {
                        toast.error(`Error: ${errors.token.join(', ')}`);
                    }
                }
                if (message) {
                    toast.error(`Error: ${message}`);
                }
            } else {
                toast.error('Error resetting password.');
            }
        }
    };
    
    // Reste du composant
    return (
        <div>
            <div className="block_login body_gray">
               
                <form 
                 onSubmit={handleSubmit}
                 className="align" 
                > 
                    <div className="body_login full_width body_blue h-full">
                        <img src={body_login} alt="body_login" className="center_el"></img>
                    </div>
                    {/* <div class="Vector"></div> */}
                    
                    <div className="form_reset_password form_style full_width form_change">
                        <div className="logo_flex flex">
                            <img src={Slice1} alt="logo_flex" />
                            <h1>diggow</h1>
                        </div>
                            {/* <input  id="token" value={token} readOnly />
                            <input id="email" value={email} readOnly /> */}
                        <div className="connect_block">
                            <h2>{t("forgot_password.change_password")}</h2>
                            {/* <p>{t("forgot_password.resetlink")}</p> */}
                        </div>
                        <div className="input_login input_style">
                            <div className="box_input">
                                <label>{t("forgot_password.new_password")}</label>
                                <InputText
                                    type="password"
                                    placeholder="Nouveau mot de passe"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <label className="label_confirm">{t("signup.confirm_password")}</label>
                                <InputText
                                    type="password"
                                    placeholder="Entrer de nouveau le mot de passe"
                                    value={passwordConfirmation}
                                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                                    required
                                />
                                <button type="submit" 
                                className="btn_url btn_chng"
                                >Changer
                                </button>
                                <Toaster
                                    toastOptions={{
                                    className: "",
                                    style: {
                                        border: "2px solid #713200",
                                        padding: "16px",
                                        color: "#596ae9",
                                        backgroundColor: "#f4f7fb",
                                    },
                                    }}
                                />
                            </div>    
                        </div>
                    </div>
                </form>      
            </div>
        </div>        
    );
};

export default ResetPassword;